import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const newspapersApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getNewspapers: build.query({
            query: (query) => {
                const url = constructUrl('newspapers', {
                    ...query,
                    sort: query.sort ?? JSON.stringify({created: 'desc'}),
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Newspaper'),
        }),
        getNewspaper: build.query({
            query: (id) => ({
                url: `newspapers/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Newspaper', result),
        }),
        createNewspaper: build.mutation({
            query: (body) => {
                return {
                    url: `newspapers`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Newspaper'),
        }),
        saveNewspaper: build.mutation({
            query: (body) => {
                return {
                    url: `newspapers/${body._id}`,
                    method: 'PUT',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Newspaper', result),
        }),
        deleteNewspaper: build.mutation({
            query: (id) => {
                return {
                    url: `newspapers/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Newspaper'),
        }),
    }),
    overrideExisting: false,
})

export default newspapersApi

export const {
    useGetNewspapersQuery,
    useGetNewspaperQuery,
    useCreateNewspaperMutation,
    useSaveNewspaperMutation,
    useDeleteNewspaperMutation,
} = newspapersApi
