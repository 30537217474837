import {Page, PageContent} from 'components/Page'
import BannerForm from 'components/Page/Banner/BannerForm'
import FormTitle from 'components/Page/Form/FormTitle'
import useBannerForm from 'logic/Forms/useBannerForm'
import {FormProvider} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {isNew} from 'services/utils/editForm'
import {useI18n} from 'store/Store'

const Banner = () => {
    const {i18n} = useI18n()
    const {bannerId} = useParams()
    const {form, submit, uploadBanner} = useBannerForm(bannerId)

    return (
        <Page>
            <PageContent>
                <FormTitle
                    itemId={bannerId}
                    itemLabel={i18n.t(`tables.banner`)}
                    submit={submit}
                />
                <FormProvider {...form}>
                    <BannerForm
                        isNew={isNew(bannerId)}
                        uploadBanner={uploadBanner}
                    />
                </FormProvider>
            </PageContent>
        </Page>
    )
}

export default Banner
