import {Page, PageContent} from 'components/Page'
import {FormProvider} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {useParams} from 'react-router-dom'
import FormTitle from 'components/Page/Form/FormTitle'
import {isNew} from 'services/utils/editForm'
import DanceCollectionForm from 'components/Page/DanceCollections/DanceCollectionForm'
import useDanceCollectionForm from 'logic/Forms/useDanceCollectionForm'

const DanceCollection = () => {
    const {i18n} = useI18n()
    const {danceCollectionId} = useParams()
    const {
        form,
        submit,
        dancesResults,
        setSearchDanceInputValue,
        searchValue,
        danceCollectionDances,
        handleAddDance,
        handleRemoveDance,
        handleMoveDance,
    } = useDanceCollectionForm({danceCollectionId})

    return (
        <Page>
            <PageContent>
                <FormTitle
                    itemId={danceCollectionId}
                    itemLabel={i18n.t(`tables.danceCollection`)}
                    submit={submit}
                />
                <FormProvider {...form}>
                    <DanceCollectionForm
                        isNew={isNew(danceCollectionId)}
                        searchResults={dancesResults}
                        danceCollectionDances={danceCollectionDances}
                        searchValue={searchValue}
                        setInputValue={setSearchDanceInputValue}
                        onSelectValue={handleAddDance}
                        handleRemoveDance={handleRemoveDance}
                        handleMoveDance={handleMoveDance}
                    />
                </FormProvider>
            </PageContent>
        </Page>
    )
}

export default DanceCollection
