import TableRowRaw from '@mui/material/TableRow'
import TableCell from './TableCell'
import TableActions from 'components/Display/Table/TableActions'
import {getItemId} from 'services/utils/tables'

const TableRow = ({
    itemName,
    tableName,
    tableKeys,
    tableRowData,
    rowIndex,
    allowEdit,
    allowDelete,
    onDelete,
    onExpand,
    expandedId,
    keyTypes,
    keyActions,
    children,
}) => {
    const itemId = getItemId(tableRowData)
    const isExpanded = expandedId === itemId

    console.log('key types', keyTypes)

    return (
        <>
            <TableRowRaw
                sx={{
                    '&:last-child td, &:last-child th': {border: 0},
                }}>
                {tableKeys.map((key) => (
                    <TableCell
                        key={`table_col_${key}_${rowIndex}`}
                        itemKey={key}
                        itemId={itemId}
                        tableRowData={tableRowData}
                        keyTypes={keyTypes}
                        keyActions={keyActions}
                    />
                ))}

                <TableActions
                    itemId={itemId}
                    itemName={itemName}
                    tableName={tableName}
                    tableRowData={tableRowData}
                    allowEdit={allowEdit}
                    allowDelete={allowDelete}
                    onDelete={onDelete}
                    onExpand={onExpand}
                    isExpanded={isExpanded}
                />
            </TableRowRaw>
            {isExpanded && (
                <tr>
                    <td colspan={tableKeys.length}>{children}</td>
                </tr>
            )}
        </>
    )
}

export default TableRow
