import {extractError, getFormTitle, isNew} from 'services/utils/editForm'
import tables from 'constants/tables'
import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {itemSchema} from 'services/validations/schemas/item'

const useItemForm = (
    itemName,
    useCreateMutation,
    useGetQuery,
    useSaveMutation,
    itemId
) => {
    const itemNames = useMemo(() => `${itemName}s`, [itemName])

    const [errorMessage, setErrorMessage] = useState(null)

    const {data: item} = useGetQuery(itemId)

    const [doCreate, {error: createError, data: createResponse}] =
        useCreateMutation()

    const [doSave, {error: saveError, data: saveResponse}] = useSaveMutation()

    const {
        handleSubmit,
        reset,
        getValues: getFormValues,
        setValue: setFormValue,
        control: formControl,
        formState: {errors: formErrors},
    } = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(itemSchema),
        defaultValues: useMemo(() => {
            return item
        }, [item]),
    })

    useEffect(() => reset(item), [item, reset])

    useEffect(() => {
        setErrorMessage(extractError(saveError, createError))
    }, [saveError, createError])

    const onSubmit = (itemData) => {
        if (isNew(itemId)) {
            doCreate(itemData)
        } else {
            doSave(itemData)
        }
    }

    const submit = handleSubmit(onSubmit)

    const formTitle = getFormTitle(itemId, itemName)
    const {keyTypes, requiredKeys, editableKeys} = tables[itemNames]

    return {
        formTitle,
        keyTypes,
        requiredKeys,
        editableKeys,
        itemNames,
        errorMessage,
        item,
        formControl,
        formErrors,
        submit,
        getFormValues,
        setFormValue,
        createResponse,
        saveResponse,
    }
}

export default useItemForm
