import {styled} from '@mui/material'
import {Check} from '@styled-icons/boxicons-regular/Check'
import {CloseOutline} from '@styled-icons/evaicons-outline/CloseOutline'

const LanguagesIcons = ({valuesToDisplay}) => {
    return (
        <Container>
            {valuesToDisplay.map((value, index) => (
                <Container key={index}>
                    {index > 0 && ' | '}
                    {value ? (
                        <Check size={20} color="green" />
                    ) : (
                        <CloseOutline size={20} color="red" />
                    )}
                </Container>
            ))}
        </Container>
    )
}

export default LanguagesIcons

const Container = styled('div')`
    display: flex;
    justify-content: center;
    gap: 4px;
`
