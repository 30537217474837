import {Snackbar, Alert} from '@mui/material'
import {useAlert} from './AlertContext'

const AlertComponent = () => {
    const {alert, closeAlert} = useAlert()

    return (
        <Snackbar
            open={alert.open}
            autoHideDuration={null}
            onClose={closeAlert}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
            <Alert
                onClose={closeAlert}
                severity={alert.severity}
                sx={{width: '100%'}}>
                {alert.message}
            </Alert>
        </Snackbar>
    )
}

export default AlertComponent
