import {useIsRtl} from 'store/Store'
import {ThemeProvider} from '@mui/material'
import {CacheProvider} from '@emotion/react'
import createCache from '@emotion/cache'
import rtlPlugin from 'stylis-plugin-rtl'
import {prefixer} from 'stylis'
import theme from 'appearance/theme'

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
})

const cacheLtr = createCache({
    key: 'mui',
})

const ThemeWrapper = ({children}) => {
    const isRtl = useIsRtl()

    return (
        <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>{' '}
        </CacheProvider>
    )
}

export default ThemeWrapper
