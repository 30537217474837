import {Controller} from 'react-hook-form'
import ChipAutocomplete from '../ChipAutocomplete'

const FormFreeSoloChipInput = ({
    hidden,
    name,
    control,
    label,
    getOptionLabel,
}) => {
    const handleChange = (onChange) => (event, value) => {
        onChange(value)
    }

    return (
        !hidden && (
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value}}) => (
                    <ChipAutocomplete
                        freeSolo
                        multiple
                        handleHomeEndKeys
                        name={name}
                        value={value || []}
                        label={label}
                        onChange={handleChange(onChange)}
                        getOptionLabel={getOptionLabel}
                    />
                )}
            />
        )
    )
}

export default FormFreeSoloChipInput
