import {styled} from '@mui/material'
import {Texts} from 'appearance'

const TableHeader = ({children}) => {
    return <HeaderText>{children}</HeaderText>
}

export default TableHeader

const HeaderText = styled(Texts.Text)`
    font-size: 14px;
    font-weight: 600;
    white-space: pre-line;
    text-align: center;
`
