import {React} from 'common'
import {Controller} from 'react-hook-form'
import Input from '../Input'
import {InputContainer} from 'components/Page/Form/Forms'

const FormTextInput = ({
    name,
    label,
    control,
    error,
    required,
    disabled,
    type,
    readOnly,
    hidden,
    inputStyle,
    placeholder,
}) => {
    label = label || name

    const formatValue = (value) => {
        if (value instanceof File) {
            return value.name
        }
        return value
    }

    return (
        !hidden && (
            <InputContainer>
                <Controller
                    name={name}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <Input
                            name={name}
                            hidden={hidden}
                            readOnly={readOnly}
                            fullWidth
                            onChange={onChange}
                            value={formatValue(value)}
                            label={label}
                            required={required}
                            disabled={disabled}
                            type={type}
                            error={error}
                            inputStyle={inputStyle}
                            placeholder={placeholder}
                        />
                    )}
                />
            </InputContainer>
        )
    )
}

export default FormTextInput
