import {Controller} from 'react-hook-form'
import AddressInput from '../AddressInput'
import {InputContainer} from 'components/Page/Form/Forms'

const FormAddressInput = ({
    name,
    label,
    required,
    control,
    setGoogleLocation,
    placeholder,
    error,
}) => {
    const handleSelectAddress = (onChange) => (address, googleLocation) => {
        onChange(address)
        setGoogleLocation(googleLocation, 'googleLocation')
    }

    return (
        <InputContainer>
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value}}) => (
                    <AddressInput
                        name={name}
                        required={required}
                        placeholder={placeholder}
                        label={label}
                        value={value}
                        onAddressSelected={handleSelectAddress(onChange)}
                        error={error}
                    />
                )}
            />
        </InputContainer>
    )
}

export default FormAddressInput
