import DataTable from 'components/Display/DataTable/DataTable'
import Modal from 'components/Display/Modal/Modal'
import {Page, PageContent} from 'components/Page'
import MessageForm from 'components/Page/Messages/MessageForm'
import MessageModalTitle from 'components/Page/Messages/MessageModalTitle'
import useMessageForm from 'logic/Forms/useMessageForm'
import {FormProvider} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {
    useDeleteMessageMutation,
    useGetMessagesQuery,
} from 'store/slices/api/messagesApi'

const Messages = () => {
    const {i18n} = useI18n()
    const {
        form,
        closeMessageModal,
        open,
        handleViewMessage,
        handleMarkAsSeen,
        message,
        changeTextDirection,
        textAlign,
    } = useMessageForm()

    return (
        <Page>
            <PageContent>
                <DataTable
                    allowAdd={false}
                    allowView
                    allowDelete
                    itemName="message"
                    paginationType="external"
                    useGetQuery={useGetMessagesQuery}
                    useDeleteMutation={useDeleteMessageMutation}
                    onViewItem={handleViewMessage}
                />

                <FormProvider {...form}>
                    <Modal
                        open={open}
                        onClose={closeMessageModal}
                        buttons={[
                            {
                                label: i18n.t('form.ok'),
                                onClick: closeMessageModal,
                            },
                        ]}
                        titleComponent={
                            <MessageModalTitle
                                title={i18n.t('messages.modalTitle')}
                                seen={message?.seen}
                                onMarkAsSeen={handleMarkAsSeen}
                            />
                        }>
                        <MessageForm
                            textAlign={textAlign}
                            changeTextDirection={changeTextDirection}
                        />
                    </Modal>
                </FormProvider>
            </PageContent>
        </Page>
    )
}

export default Messages
