import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {
    useGetMessageQuery,
    useGetMessagesQuery,
    useMarkAsSeenMutation,
} from 'store/slices/api/messagesApi'

const useMessageForm = () => {
    const [messageId, setMessageId] = useState()
    const [textAlign, setTextAlign] = useState('left')

    const {data: messages} = useGetMessagesQuery()
    const {data: message} = useGetMessageQuery(messageId, {skip: !messageId})
    const [doMarkAsSeen] = useMarkAsSeenMutation()

    const form = useForm()

    useEffect(() => {
        if (message) {
            form.reset(message)
        }
    }, [message, form])

    const handleViewMessage = (messageId) => {
        setMessageId(messageId)
    }

    const closeMessageModal = () => {
        setMessageId()
    }

    const handleMarkAsSeen = async (event) => {
        await doMarkAsSeen({_id: messageId, seen: event.target.checked})
    }

    const changeTextDirection = () => {
        if (textAlign === 'left') {
            setTextAlign('right')
        } else {
            setTextAlign('left')
        }
    }

    return {
        form,
        messages,
        message,
        handleViewMessage,
        open: !!messageId,
        closeMessageModal,
        handleMarkAsSeen,
        changeTextDirection,
        textAlign,
    }
}

export default useMessageForm
