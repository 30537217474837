import {useUrlParams} from 'services/hooks'
import TablePaginationRaw from '@mui/material/TablePagination'
import {INITIAL_PAGE_SIZE, PAGE_SIZES} from 'constants/tables'

const TablePagination = ({
    itemName,
    paginationType, // 'local', 'external' (server) or null
    totalItems,
}) => {
    const {urlParams, setUrlParam} = useUrlParams()
    const {[`${itemName}_page`]: page, [`${itemName}_pageSize`]: pageSize} =
        urlParams

    if (!paginationType) {
        return null
    }

    console.log('table pagination', page, pageSize)

    return (
        <TablePaginationRaw
            rowsPerPageOptions={PAGE_SIZES}
            component="div"
            count={totalItems || 0}
            rowsPerPage={Number(pageSize || INITIAL_PAGE_SIZE)}
            page={Number(page || 0)}
            onPageChange={(_, p) => setUrlParam(`${itemName}_page`, Number(p))}
            onRowsPerPageChange={(e) => {
                setUrlParam(
                    `${itemName}_pageSize`,
                    parseInt(e.target.value, 10)
                )
                setUrlParam(`${itemName}_page`, 0)
            }}
        />
    )
}

export default TablePagination
