import {
    Checkbox as RawCheckbox,
    FormGroup,
    FormControlLabel,
} from '@mui/material'
import {styled} from '@mui/material/styles'

const Checkbox = ({label, disabled, defaultChecked, value, onChange, ...p}) => {
    if (label) {
        return (
            <FormGroup {...p}>
                <StyledFormControlLabel
                    control={
                        <RawCheckbox
                            size="small"
                            onChange={onChange}
                            defaultChecked={value}
                            checked={value}
                        />
                    }
                    label={label}
                    disabled={disabled}
                    labelPlacement="start"
                />
            </FormGroup>
        )
    }

    return <RawCheckbox {...p} defaultChecked />
}
export default Checkbox

const StyledFormControlLabel = styled(FormControlLabel)`
    & .MuiButtonBase-root {
        padding: 0;
        padding-inline: 8px;
    }
    & .MuiFormControlLabel-label {
        font-size: 14px;
    }
`
