import {List, ListItem, ListItemText, styled} from '@mui/material'
import {Texts} from 'appearance'
import {useI18n} from 'store/Store'

const PurchasedDances = ({purchasedDances}) => {
    const {i18n} = useI18n()
    return (
        <Container>
            <Title>{i18n.t('users.purchasedDances')}</Title>
            <List dense disableGutters>
                {purchasedDances?.map(({dance}) => (
                    <ListItem dense disableGutters>
                        <ListItemText primary={dance?.nameHeb} />
                    </ListItem>
                ))}
            </List>
        </Container>
    )
}

export default PurchasedDances

const Container = styled('div')`
    padding: 12px;
    width: 50%;
`

const Title = styled(Texts.Text)`
    font-weight: 500;
`
