import {useState} from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import Autocomplete from './Autocomplete'

//from the old project
// const GOOGLE_MAPS_API_KEY = 'AIzaSyDBfVJ6OHJYTvIV4Kwcs93PoiqaqrmA-Ck'
const GOOGLE_MAPS_API_KEY = 'AIzaSyD-Y_98dE5NsM4H3lsxuwITzaXldOAqlfI'

const AddressInput = ({
    name,
    label,
    onAddressSelected,
    value,
    required,
    placeholder,
    error,
}) => {
    const [inputValue, setInputValue] = useState('')

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        language: 'iw',
        apiKey: GOOGLE_MAPS_API_KEY,
        options: {
            types: ['address'],
            fields: ['geometry', 'place_id', 'formatted_address'],
            debounce: 500,
        },
    })

    const onPlaceSelected = (place) => {
        const coordinates = place.geometry.location
        const googleLocation = {
            googlePlaceId: place.place_id,
            lat: coordinates.lat(),
            lng: coordinates.lng(),
        }
        onAddressSelected(place.formatted_address, googleLocation)
    }

    const onInputChange = (event, value) => {
        setInputValue(value)
        getPlacePredictions({input: value})
    }

    const handleSetValue = (event, value) => {
        const query = {
            placeId: value?.place_id,
        }
        placesService?.getDetails(query, onPlaceSelected)
    }

    const formatPredictions = (predictions) =>
        typeof predictions === 'string' ? predictions : predictions.description

    return (
        <Autocomplete
            name={name}
            label={label}
            required={required}
            placeholder={placeholder}
            isLoading={isPlacePredictionsLoading}
            options={placePredictions}
            getOptionLabel={formatPredictions}
            value={value || ''}
            onChange={handleSetValue}
            inputValue={inputValue}
            onInputChange={onInputChange}
            clearOnBlur={false}
            error={error}
        />
    )
}

export default AddressInput
