export const dancesListKeys = ['_id', 'nameEng', 'nameHeb', 'choreographers']

export const types = [
    'SPECIAL_NEEDS',
    'SEATING_AND_STANDING',
    'WOMEN_ONLY',
    'GOLDEN_AGE',
    'SALSA_AND_RUEDA',
    'BALLROOM_AND_LATIN',
    'CHILDREN',
    'INTERNATIONAL',
    'WORKSHOPS',
    'NOSTALGIA',
    'MARATHONS_AND_SPECIAL',
    'FESTIVALS_WEEKENDS_TRIPS',
    'WEEKLY',
]

export const shapes = [
    'CIRCLE',
    'COUPLES',
    'LINES',
    'SESSION',
    'IMITATION_FOR_CHILDREN',
    'WHEELCHAIRS',
    'CHILDREN_LINES',
    'TRIOS',
    'MEDLEY',
    'CHILDREN_COUPLES',
    'CHILDREN_CIRCLE',
    'PERFORMANCE',
    'DANCE_DELIGHT',
]

export const fileTypes = [
    'fv', // 'full video',
    'af', // 'full audio',
    'dv', // 'dance video',
    'tv', // 'teach video',
]

export const videoTypes = ['videoFull', 'videoTeach', 'videoDance']
