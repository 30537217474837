import {React} from 'common'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MoreIcon from '@mui/icons-material/MoreVert'

const MobileMenuButton = ({mobileMenuId, handleOpenMenu}) => {
    return (
        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
            <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleOpenMenu('mobile')}
                color="inherit">
                <MoreIcon />
            </IconButton>
        </Box>
    )
}

export default MobileMenuButton
