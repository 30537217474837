import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const guidesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getMessages: build.query({
            query: (query) => {
                const url = constructUrl('messages', {
                    ...query,
                    sort: query.sort ?? JSON.stringify({created: 'desc'}),
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Message'),
        }),
        getMessage: build.query({
            query: (id) => ({
                url: `messages/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Message', result),
        }),
        markAsSeen: build.mutation({
            query: (body) => {
                return {
                    url: `messages/markAsSeen`,
                    method: 'PUT',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['Message'],
        }),
        deleteMessage: build.mutation({
            query: (id) => {
                return {
                    url: `messages/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Message'),
        }),

        getUnreadCount: build.query({
            query: () => ({
                url: `messages/unreadCount`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Message', result),
        }),
    }),
    overrideExisting: false,
})

export default guidesApi

export const {
    useGetMessagesQuery,
    useGetMessageQuery,
    useDeleteMessageMutation,
    useMarkAsSeenMutation,
    useGetUnreadCountQuery,
} = guidesApi
