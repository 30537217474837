import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {getFormError, isNew} from 'services/utils/editForm'
import {useGetCitiesQuery} from 'store/slices/api/citiesApi'
import {useGetCountriesQuery} from 'store/slices/api/countriesApi'
import {
    useCreateGuideMutation,
    useGetGuideQuery,
    useSaveGuideMutation,
} from 'store/slices/api/guidesApi'
import {useAlert} from 'components/Display/Alert/AlertContext'
import {yupResolver} from '@hookform/resolvers/yup'
import {guideSchema} from 'services/validations/schemas/guide'
import {useNavigate} from 'react-router-dom'

const useGuideForm = (guideId) => {
    const {showAlert} = useAlert()
    const {i18n} = useI18n()
    const navigate = useNavigate()

    const [searchCityValue, setCityInputValue] = useState()
    const [searchCountryValue, setCountryInputValue] = useState()

    const [doCreateGuide] = useCreateGuideMutation()
    const [doSaveGuide] = useSaveGuideMutation()
    const {data: guide} = useGetGuideQuery(guideId, {skip: isNew(guideId)})
    const {data: cities} = useGetCitiesQuery(
        {search: searchCityValue},
        {skip: !searchCityValue}
    )
    const {data: countries} = useGetCountriesQuery(
        {search: searchCountryValue},
        {skip: !searchCountryValue}
    )

    const form = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(guideSchema),
    })
    const {handleSubmit} = form

    useEffect(() => {
        if (guide) {
            form.reset(guide)
        }
    }, [form, guide])

    const onSubmit = async (values) => {
        let response
        console.log({values})
        if (values._id) {
            response = await doSaveGuide(values)
        } else {
            response = await doCreateGuide(values)
        }

        if (response.error) {
            const errorMessage = getFormError(
                response.error?.data?.message,
                i18n.t('guides.email'),
                i18n
            )

            showAlert(errorMessage, 'error')
        }

        if (response?.data?._id) {
            const message = i18n.t('form.savedSuccessfully', {
                itemName: i18n.t('tables.guide'),
            })
            navigate(`/guides/${response.data._id}`)
            showAlert(message)
        }
    }

    const submit = handleSubmit(onSubmit)

    return {
        form,
        submit,
        cities,
        countries,
        searchCityValue,
        searchCountryValue,
        setCityInputValue,
        setCountryInputValue,
    }
}

export default useGuideForm
