import {InputLabel, styled} from '@mui/material'

export const FormContainerRow = styled('div')`
    display: flex;
    justify-content: space-between;
    gap: 48px;
`

export const FormContainer = styled('div')`
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`

export const ModalFormContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 10px;
`

export const FormRow = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 12px;
`

export const FormColumn = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
`

export const InputContainer = styled('div')`
    width: 100%;
`

export const Label = styled(InputLabel)`
    margin-bottom: 10px;
    font-size: 12px;
`
