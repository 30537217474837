import useNewspapersForm from 'logic/Forms/useNewspapersForm'
import {useParams} from 'react-router-dom'
import {useI18n} from 'store/Store'
import {Page, PageContent} from 'components/Page'
import {isNew} from 'services/utils/editForm'
import {FormProvider} from 'react-hook-form'
import NewspaperForm from 'components/Page/Newspaper/NewspaperForm'
import FormTitle from 'components/Page/Form/FormTitle'

const Newspaper = () => {
    const {i18n} = useI18n()
    const {newspaperId} = useParams()
    const {form, submit, uploadImage, imagesUrls} =
        useNewspapersForm(newspaperId)

    return (
        <Page>
            <PageContent>
                <FormTitle
                    itemId={newspaperId}
                    itemLabel={i18n.t(`tables.newspaper`)}
                    submit={submit}
                />
                <FormProvider {...form}>
                    <NewspaperForm
                        isNew={isNew(newspaperId)}
                        uploadImage={uploadImage}
                        imagesUrls={imagesUrls}
                    />
                </FormProvider>
            </PageContent>
        </Page>
    )
}

export default Newspaper
