import {React, styled} from 'common'
import {useParams} from 'react-router-dom'
import Link from '@mui/material/Link'
import {Page, PageContent} from 'components/Page'
import {validationPatterns} from 'constants/auth'
import Button from 'components/Control/Button'
import {
    FormActionButton,
    LoginFormContainer,
    FormTitle,
    FormRow,
    FormAdditionalButton,
    FormAdditionalText,
    FormErrorMessage,
} from 'components/Control/Form/index_'
import FormCheckbox from 'components/Control/Form/FormChecbox'
import FormTextInput from 'components/Control/Form/FormTextInput'
import useAuth from 'logic/Auth/useAuth'

const Register = () => {
    const params = useParams()
    const {token} = params

    const {formControl, signup, isLoading, errorMessage, formErrors, response} =
        useAuth(token)

    if (response?.message) {
        return (
            <Page noHeader noDrawer>
                <PageContent>
                    <span>{response.message}</span>
                </PageContent>
                <Link href={'/login'}>
                    <Button variant="contained">Continue</Button>
                </Link>
            </Page>
        )
    }

    return (
        <Page noHeader noDrawer>
            <PageContent centered>
                <LoginFormContainer style={{width: 400}}>
                    <FormTitle>Welcome to {window.appName}</FormTitle>
                    <FormTextInput
                        name="firstName"
                        label="First Name"
                        control={formControl}
                        rules={{
                            required: true,
                        }}
                        fullWidth
                        type="text"
                        initialValue=""
                        errors={formErrors}
                    />
                    <FormTextInput
                        name="lastName"
                        label="Last Name"
                        control={formControl}
                        rules={{
                            required: true,
                        }}
                        fullWidth
                        type="text"
                        initialValue=""
                        errors={formErrors}
                    />
                    <FormTextInput
                        name="email"
                        label="Email"
                        control={formControl}
                        rules={{
                            required: true,
                            pattern: validationPatterns.EMAIL,
                        }}
                        fullWidth
                        type="email"
                        initialValue=""
                        errors={formErrors}
                    />
                    <FormTextInput
                        name="password"
                        label="Password"
                        control={formControl}
                        rules={{
                            required: true,
                        }}
                        fullWidth
                        type="password"
                        initialValue=""
                        errors={formErrors}
                    />
                    <FormTextInput
                        name="confirmPassword"
                        label="Confirm Password"
                        control={formControl}
                        rules={{
                            required: true,
                        }}
                        fullWidth
                        type="password"
                        initialValue=""
                        errors={formErrors}
                    />
                    <FormCheckbox
                        name={'rememberMe'}
                        control={formControl}
                        label="Remember Me"
                    />
                </LoginFormContainer>

                <StyledFormActionButton
                    onClick={signup}
                    variant="contained"
                    disabled={isLoading}>
                    Register
                </StyledFormActionButton>
                <FormErrorMessage errorMessage={errorMessage} />
                <FormRow>
                    <FormAdditionalText>Have an Account?</FormAdditionalText>
                    <NoUnderLineLink href={'/login'}>
                        <FormAdditionalButton>Login</FormAdditionalButton>
                    </NoUnderLineLink>
                </FormRow>
            </PageContent>
        </Page>
    )
}

export default Register

const NoUnderLineLink = styled(Link)`
    text-decoration: none;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
    }
`

const StyledFormActionButton = styled(FormActionButton)`
    width: 400px;
`
