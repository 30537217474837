import {Styles} from 'appearance'
import {useEffect, useMemo, useState} from 'react'
import {useSetUserAccountMutation} from 'store/Store'

const useSideMenu = (noDrawer) => {
    const [drawerOpen, setDrawerOpen] = useState(true)
    const [accountsExpanded, setAccountsExpanded] = useState(false)

    const toggleMenu = () => {
        setDrawerOpen(!drawerOpen)
    }

    const toggleAccounts = () => {
        if (!drawerOpen && !accountsExpanded) {
            toggleMenu()
        }
        setAccountsExpanded(!accountsExpanded)
    }

    useEffect(() => {
        if (!drawerOpen) {
            setAccountsExpanded(false)
        }
    }, [drawerOpen])

    const marginStart = useMemo(() => {
        if (noDrawer) {
            return 0
        } else if (drawerOpen) {
            return Styles.DRAWER_WIDTH
        }
        return Styles.DRAWER_WIDTH_CLOSED
    }, [drawerOpen, noDrawer])

    const [doSetUserAccount] = useSetUserAccountMutation()

    const onSelectAccount = async (account) => {
        const accountId = account._id
        const accountName = account.name
        await doSetUserAccount({accountId, accountName})
    }

    return {
        open: drawerOpen,
        toggleMenu,
        marginStart,
        accountsExpanded,
        toggleAccounts,
        onSelectAccount,
    }
}

export default useSideMenu
