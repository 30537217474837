import DataTable from 'components/Display/DataTable/DataTable'
import {Page, PageContent} from 'components/Page'
import {
    useDeleteBannerMutation,
    useGetBannersQuery,
} from 'store/slices/api/bannersApi'

const Banners = () => {
    return (
        <Page>
            <PageContent>
                <DataTable
                    allowEdit
                    allowDelete
                    itemName="banner"
                    paginationType="external"
                    useGetQuery={useGetBannersQuery}
                    useDeleteMutation={useDeleteBannerMutation}
                />
            </PageContent>
        </Page>
    )
}

export default Banners
