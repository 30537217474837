import {useAlert} from 'components/Display/Alert/AlertContext'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {isNew} from 'services/utils/editForm'
import {urls} from 'services/utils/urls'
import {
    useCreateNewspaperMutation,
    useGetNewspaperQuery,
    useSaveNewspaperMutation,
} from 'store/slices/api/newspapersApi'
import {useUploadImageMutation} from 'store/slices/api/uploadsApi'
import {useI18n} from 'store/Store'

const useNewspapersForm = (newspaperId) => {
    const {i18n} = useI18n()
    const {showAlert} = useAlert()
    const navigate = useNavigate()

    const [formError, setFormError] = useState(null)

    const [doCreateNewspapern] = useCreateNewspaperMutation()
    const [doSaveNewspapern] = useSaveNewspaperMutation()
    const {data: newspaper} = useGetNewspaperQuery(newspaperId, {
        skip: isNew(newspaperId),
    })

    const [doUploadImage] = useUploadImageMutation()

    const form = useForm({
        defaultValues: {
            activated: true,
        },
    })

    const formValues = form.watch()

    useEffect(() => {
        if (newspaper) {
            form.reset(newspaper)
        }
    }, [newspaper, form])

    const onSubmit = async (values) => {
        let response
        if (values._id) {
            response = await doSaveNewspapern(values)
        } else {
            response = await doCreateNewspapern(values)
        }

        if (response?.data?._id) {
            const message = i18n.t('form.savedSuccessfully', {
                itemName: i18n.t('tables.newspaper'),
            })
            navigate(`/newspapers/${response.data._id}`)

            showAlert(message)
        }
        if (response.error) {
            setFormError(response.error.data.error)
        }
    }

    const submit = form.handleSubmit(onSubmit)

    const uploadImage = async (inputName) => {
        const folderName = inputName.includes('thumbnail')
            ? urls.newspaperThumbnails
            : urls.newspaperPdf

        const formData = new FormData()
        formData.append('files', formValues[inputName])
        formData.append('folderName', folderName)

        const response = await doUploadImage({
            formData,
            folderName,
        })

        const link = response?.data?.files?.[0].link

        if (link) {
            const url = link.split('/')[2]
            form.setValue(inputName, url)
        }
    }

    const getImageUrl = (fileName, folderName) =>
        fileName &&
        !(fileName instanceof File) &&
        `${urls.base}/${folderName}/${fileName}`

    const {thumbnailUrl, pdfUrl, pdfUrlHebrew, thumbnailUrlHebrew} =
        formValues || {}
    const {newspaperThumbnails, newspaperPdfs} = urls

    const imagesUrls = {
        thumbnailUrl: getImageUrl(thumbnailUrl, newspaperThumbnails),
        pdfUrl: getImageUrl(pdfUrl, newspaperPdfs),
        pdfUrlHebrew: getImageUrl(pdfUrlHebrew, newspaperPdfs),
        thumbnailUrlHebrew: getImageUrl(
            thumbnailUrlHebrew,
            newspaperThumbnails
        ),
    }

    return {
        form,
        formError,
        newspaper,
        submit,
        uploadImage,
        imagesUrls,
    }
}

export default useNewspapersForm
