import tables, {INITIAL_PAGE_SIZE} from 'constants/tables'
import {formatItemName, formatUser} from './data'
import dayjs from 'services/utils/dayjs'
import {Texts} from 'appearance'
import CheckIcon from '@mui/icons-material/Check'
import TableHeader from 'components/Display/Table/TableHeader'
import LanguagesIcons from 'components/Page/Newspaper/LanguagesIcons'
import TableFiles from 'components/Page/Dances/TableFiles'
import TextCell from 'components/Display/Table/TextCell'
import CellWithCopy from 'components/Display/Table/CellWithCopy'
import {urls} from './urls'
import AutocompleteFilter from 'components/Display/Table/CustomFilters/AutocompleteFilter'

const date = (value) => value && dayjs(value).format('D/M/YYYY')
const datetime = (value) => value && dayjs(value).format('D/M/YYYY HH:mm')
const number = (value) => value?.toFixed(2)
const boolean = (value, row, i18n) =>
    value ? i18n.t('tables.yes') : i18n.t('tables.no')
const email = (value) => <a href={`mailto:${value.trim()}`}>{value.trim()}</a>
const url = (value) => (
    <a href={value?.trim()} target="_blank" rel="noopener noreferrer">
        {value}
    </a>
)
const namesArray = (value) => {
    return value.map(formatUser).join(', ')
}
const translatedNamesArray = (value, rowData, i18n, tableName, itemKey) =>
    value.map((item) => i18n.t(`${tableName}.${itemKey}s.${item}`)).join(', ')

const itemName = (value) => formatItemName(value)
const total = (value) => value?.length
const translateType = (value, row, i18n, tableName) =>
    i18n.t(`${tableName}.types.${value}`)

const dayOfTheWeek = (value) => dayjs().day(+value).format('dddd')

const longText = (value) => <Texts.Text noWrap>{value}</Texts.Text>
const name = (value) => (value ? formatUser(value) : '')
const bannerImage = (value, row) => {
    const baseUrl = `${urls.base}/${urls.banners_pictures}/`
    let src = ''

    if (row.imgUrl) {
        src = baseUrl + row.imgUrl
    } else if (row.hasPicture) {
        src = baseUrl + `bp${row._id}`
    }

    return <img height="50px" width="150px" src={src} alt={'banner'} />
}

const isActive = (value) =>
    value && (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <CheckIcon color="primary" />
        </div>
    )
const issueTitle = (value, row) => {
    const {issueTitleHebrew} = row
    return [value, issueTitleHebrew].filter(Boolean).join(' | ')
}
const files = (value, row, i18n, tableName, itemKey) => {
    const En = `${itemKey}Url`
    const He = `${itemKey}UrlHebrew`
    return <LanguagesIcons valuesToDisplay={[row[En], row[He]]} />
}

const danceFiles = (value, row, i18n, tableName, itemKey) => {
    return <TableFiles dance={row} />
}

const truncatedText = (value) => (
    <CellWithCopy value={value}>{`...${value.slice(-4)}`}</CellWithCopy>
)

const text = (value) => <TextCell>{value}</TextCell>

const videoViews = (value, row) => {
    return row?.videos?.videoFull?.views
}

const choreographersNames = (value, row) => {
    return namesArray(row.choreographers)
}

const userName = (value, row) => {
    return `${row?.user?.firstName || ''} ${row?.user?.lastName || ''}`
}

export const formatKeyFunctionsByType = {
    text,
    date,
    datetime,
    number,
    boolean,
    email,
    url,
    namesArray,
    name,
    itemName,
    total,
    translateType,
    dayOfTheWeek,
    longText,
    bannerImage,
    isActive,
    translatedNamesArray,
    issueTitle,
    files,
    danceFiles,
    truncatedText,
    videoViews,
    choreographersNames,
    userName,
}

export const getItemId = (item) => item._id || item.id || item.key

const extractHeaderKeys = (data) =>
    data.length > 0 ? Object.keys(data[0]) : []

const sortByItemKey = (itemKey, direction) => (item1, item2) => {
    const equality =
        direction === 'asc'
            ? item1[itemKey] < item2[itemKey]
            : item1[itemKey] > item2[itemKey]

    return equality ? -1 : 1
}

const getParsedSort = (sort) => {
    if (sort) {
        return JSON.parse(sort)
    }
    return {}
}

export const getSortDirection = (itemKey, sort) => {
    const parsedSort = getParsedSort(sort)
    return parsedSort[itemKey]
}

export const setSortDirection = (itemName, itemKey, direction, setUrlParam) => {
    const parsedSort = {[itemKey]: direction}
    setUrlParam(`${itemName}_sort`, JSON.stringify(parsedSort))
}

const getVisibleData = (visibleKeys, data) => {
    if (visibleKeys) {
        return data?.map((row) => {
            return visibleKeys.reduce((accu, key) => {
                if (key === 'role' && row?.account?.role) {
                    accu[key] = row.account.role
                } else {
                    accu[key] = row[key]
                }
                return accu
            }, {})
        })
    }

    return data
}

const getSortedData = (sort, searchedData) => {
    const parsedSort = getParsedSort(sort)
    const sortKey = Object.keys(parsedSort)[0]

    let sortedData = [...searchedData]
    if (sortKey) {
        sortedData.sort(sortByItemKey(sortKey, parsedSort[sortKey]))
    }

    return sortedData
}

const extractTableData = (
    data,
    visibleKeys,
    page,
    pageSize,
    search,
    sort,
    paginationType
) => {
    page = Number(page || 0)
    pageSize = Number(pageSize || INITIAL_PAGE_SIZE)
    if (paginationType === 'local') {
        data = data.slice(page * pageSize, (page + 1) * pageSize)
    }

    const visibleData = getVisibleData(visibleKeys, data)

    if (paginationType === 'external') {
        return visibleData
    }

    const searchedData = visibleData?.filter((row) =>
        isSearchedRow(row, search)
    )

    return getSortedData(sort, searchedData)
}

export const isSearchedRow = (row, search) =>
    !search ||
    Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(search.toLowerCase())
    )

const formatSelectOptions = (options, i18n, tableName, itemKey) =>
    options?.map((item) => {
        return {
            value: item,
            label: i18n.t(`${tableName}.${itemKey}s.${item}`),
        }
    })

const formatFilterValue = (column, filterVariant) => {
    if (filterVariant === 'multi-select') {
        return column.getIsFiltered() ? column.getFilterValue().split(',') : []
    }
    return column.getFilterValue()
}

export const createTableColumns = (
    tableKeys,
    keyTypes,
    tableName,
    i18n,
    translationKey
) => {
    return tableKeys.map((itemKey) => {
        const header = i18n.t(`${translationKey ?? tableName}.${itemKey}`)
        const type = keyTypes[itemKey]
        const filterVariant = tables[tableName]?.filters?.[itemKey]?.variant
        const selectOptions =
            tables[tableName]?.filters?.[itemKey]?.selectOptions
        const shouldSort = tables[tableName]?.sortableKeys?.includes(itemKey)

        return {
            header,
            Header:
                type === 'files' ? <TableHeader>{header}</TableHeader> : header,
            muiTableBodyCellProps: ({row}) => {
                const seen = row.original.seen
                const background =
                    tableName === 'messages' && !seen && '#F6CFD0'
                return {
                    sx: {
                        background,
                    },
                }
            },
            accessorKey: itemKey,
            accessorFn: (row) => {
                const value = row[itemKey]
                return (
                    formatKeyFunctionsByType[type]?.(
                        value,
                        row,
                        i18n,
                        tableName,
                        itemKey
                    ) ?? value
                )
            },
            filterVariant,
            filterSelectOptions: formatSelectOptions(
                selectOptions,
                i18n,
                tableName,
                itemKey
            ),
            muiFilterTextFieldProps: ({column}) => {
                return {
                    SelectProps: {
                        value: formatFilterValue(column, filterVariant),
                        valueLabelFormat: (item) =>
                            i18n.t(`${tableName}.${itemKey}s.${item}`),
                    },
                }
            },
            Filter:
                filterVariant === 'autocomplete'
                    ? ({column}) => (
                          <AutocompleteFilter
                              column={column}
                              tableName={tableName}
                          />
                      )
                    : undefined,
            enableSorting: shouldSort,
        }
    })
}

export const getTableData = (
    name,
    visibleKeys,
    data,
    page,
    pageSize,
    search,
    sort,
    paginationType,
    i18n,
    translationKey
) => {
    let tableItems = []
    let totalItems
    if (Array.isArray(data)) {
        tableItems = data
        totalItems = data.length
    } else if (data?.data) {
        tableItems = data.data
        totalItems = data.total
    }

    const headerKeys = extractHeaderKeys(tableItems)
    const tableKeys = tables[name]?.visibleKeys || visibleKeys || headerKeys
    const tableData = extractTableData(
        tableItems,
        visibleKeys,
        page,
        pageSize,
        search,
        sort,
        paginationType
    )
    const keyTypes = tables[name]?.keyTypes || {}

    const columns = createTableColumns(
        tableKeys,
        keyTypes,
        name,
        i18n,
        translationKey
    )

    return {tableKeys, tableData, keyTypes, tableItems, totalItems, columns}
}

export const stateToSort = (sorting) => {
    if (!sorting || sorting.length === 0) return

    const sort = {}
    sorting.forEach(({id, desc}) => {
        sort[id] = desc ? 'desc' : 'asc'
    })
    return JSON.stringify(sort)
}

export const sortToState = (sort) => {
    if (!sort || sort === 'undefined') return []
    return Object.entries(JSON.parse(sort)).map(([id, desc]) => {
        return {id, desc: desc === 'desc'}
    })
}

export const filterToState = (filter) => {
    const filters = []
    Object.entries(filter).forEach(([id, value]) => {
        filters.push({id, value})
    })

    return filters
}

export const mergeOldAndNewFilters = (oldFilters, newFilters) =>
    Object.keys(oldFilters).map((key) => {
        const filterIndex = newFilters.findIndex(({id}) => id === key)
        const value = filterIndex === -1 ? undefined : oldFilters[key]
        return {id: key, value}
    })
