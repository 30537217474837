import {Page, PageContent} from 'components/Page'
import {FormProvider} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {useParams} from 'react-router-dom'
import FormTitle from 'components/Page/Form/FormTitle'
import ItemForm from 'components/Page/MoreData/ItemForm'
import {isNew} from 'services/utils/editForm'
import useArtistForm from 'logic/Forms/useArtistForm'

const Artist = () => {
    const {i18n} = useI18n()
    const {artistId} = useParams()

    const {submit, form} = useArtistForm(artistId)

    return (
        <Page>
            <PageContent>
                <FormTitle
                    itemId={artistId}
                    itemLabel={i18n.t(`tables.artist`)}
                    submit={submit}
                />
                <FormProvider {...form}>
                    <ItemForm isNew={isNew(artistId)} itemName="artist" />
                </FormProvider>
            </PageContent>
        </Page>
    )
}

export default Artist
