import {FormHelperText} from '@mui/material'
import {styled} from '@mui/material/styles'
import {Texts} from 'appearance'
import {Controller} from 'react-hook-form'
import ReactQuill from 'react-quill'
import {Base64} from 'js-base64'

import 'react-quill/dist/quill.snow.css'
import {isBase64} from 'services/utils/data'

const {Quill} = ReactQuill
// const Font = Quill.import('formats/font')
// Quill.register(Font, true)

const FontAttributor = Quill.import('attributors/class/font')
FontAttributor.whitelist = [
    '',
    'Rubik',
    'times-new-roman',
    'arial',
    'Roboto',
    'Lato',
]
Quill.register(FontAttributor, true)

// const Font = Quill.import('formats/font')
// Font.whitelist = ['Roboto', 'Raleway', 'Montserrat', 'Lato', 'Rubik']
// Quill.register(Font, true)

// const ColorClass = Quill.import('attributors/class/color')
// Quill.register(ColorClass, true)

const fontSizeArr = [
    '8px',
    '9px',
    '10px',
    '12px',
    '14px',
    '16px',
    '20px',
    '24px',
    '32px',
    '42px',
    '54px',
    '68px',
    '84px',
    '98px',
]

var Size = Quill.import('attributors/style/size')
Size.whitelist = fontSizeArr
Quill.register(Size, true)

const formats = [
    'header',
    'font',
    'bold',
    'italic',
    'underline',
    'size',
    'list',
    'indent',
    'color',
    'background',
    'align',
    'link',
    'image',
    'clean',
]

// const Font = ReactQuill.import('formats/font')
// Font.whitelist = ['mirza', 'roboto']
// ReactQuill.register(Font, true)

const RichTextEditor = ({name, label, control, error, hidden}) => {
    const getValue = (value) => {
        if (isBase64(value)) {
            return Base64.decode(value)
        }

        return value
    }

    const toolbarOptions = [
        [{header: [1, 2, false]}],
        [{font: FontAttributor.whitelist}],
        ['bold', 'italic', 'underline'], // toggled buttons
        [{size: fontSizeArr}],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{indent: '-1'}, {indent: '+1'}], // outdent/indent
        [{color: []}, {background: []}], // dropdown with defaults from theme
        [{align: []}],
        ['link', 'image'],
        ['clean'], // remove formatting button
    ]

    return (
        !hidden && (
            <Container>
                <Label variant="caption" shrink error={!!error}>
                    {label}
                </Label>
                <Controller
                    name={name}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <StyledTextEditor
                            modules={{
                                toolbar: toolbarOptions,
                            }}
                            theme="snow"
                            formats={formats}
                            value={getValue(value)}
                            onChange={onChange}
                        />
                    )}
                />
                {!!error && (
                    <FormHelperText error={!!error}>{error}</FormHelperText>
                )}
            </Container>
        )
    )
}

export default RichTextEditor

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Label = styled(Texts.Text)(({theme, error}) => ({
    color: error ? theme.palette.error.main : 'rgba(0, 0, 0, 0.6)',
}))

const StyledTextEditor = styled(ReactQuill)`
    width: 100%;
    & > div {
        &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }
        &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .ql-picker.ql-font {
        .ql-picker-item {
            font-size: 0;
            &:before {
                content: attr(data-value) !important;
                font-size: 14px;
            }
        }
    }

    .ql-picker.ql-font {
        .ql-active {
            &:before {
                content: attr(data-value) !important;
                font-size: 14px;
            }
        }
    }

    /* Set droplist names - -item is the currently selected font, -label is the font's appearance in the droplist*/
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='']::before {
        content: 'Default';
    }

    .ql-snow
        .ql-picker.ql-font
        .ql-picker-label[data-value='times-new-roman']::before,
    .ql-snow
        .ql-picker.ql-font
        .ql-picker-item[data-value='times-new-roman']::before {
        content: 'Times New Roman';
        font-family: 'Times New Roman';
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before {
        content: 'Arial';
        font-family: 'Arial';
    }

    /****************************************************
    Set the font-family content used for the HTML content.
    *****************************************************/
    .ql-font-arial {
        font-family: 'Arial';
    }

    .ql-font-times-new-roman {
        font-family: 'Times New Roman';
    }

    .ql-picker.ql-font .ql-picker-label[data-value='Roboto']::before,
    .ql-picker.ql-font .ql-picker-item[data-value='Roboto']::before {
        font-family: 'Roboto', cursive;
        content: 'Roboto' !important;
    }

    /* .ql-picker.ql-font .ql-picker-label[data-value='Raleway']::before,
    .ql-picker.ql-font .ql-picker-item[data-value='Raleway']::before {
        font-family: 'Raleway', cursive;
        content: 'Raleway' !important;
    }

    .ql-picker.ql-font .ql-picker-label[data-value='Montserrat']::before,
    .ql-picker.ql-font .ql-picker-item[data-value='Montserrat']::before {
        font-family: 'Montserrat', cursive;
        content: 'Montserrat' !important;
    }*/

    .ql-picker.ql-font .ql-picker-label[data-value='Lato']::before,
    .ql-picker.ql-font .ql-picker-item[data-value='Lato']::before {
        font-family: 'Lato', cursive;
        content: 'Lato' !important;
    }

    .ql-picker.ql-font .ql-picker-label[data-value='Rubik']::before,
    .ql-picker.ql-font .ql-picker-item[data-value='Rubik']::before {
        font-family: 'Rubik', cursive;
        content: 'Rubik' !important;
    }

    /* Set content font-families */
    .ql-font-Roboto {
        font-family: 'Roboto';
    }
    /* .ql-font-Raleway {
        font-family: 'Raleway';
    }
    .ql-font-Montserrat {
        font-family: 'Montserrat';
    }*/
    .ql-font-Lato {
        font-family: 'Lato';
    }
    .ql-font-Rubik {
        font-family: 'Rubik';
    }

    /* .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='6px']::before {
        content: '6px';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='8pt']::before {
        content: '8pt';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
        content: 'Huge';
        font-size: 18px !important;
    } */

    .ql-snow {
        .ql-picker {
            &.ql-size {
                .ql-picker-label,
                .ql-picker-item {
                    &::before {
                        content: attr(data-value) !important;
                    }
                }
            }
        }
    }
`
