import {ListItem, ListItemIcon, ListItemText, styled} from '@mui/material'
import {useI18n} from 'store/Store'
import {MusicNote1} from '@styled-icons/fluentui-system-regular/MusicNote1'
import {Video} from '@styled-icons/fluentui-system-regular/Video'

const FilmedDateForUploadItem = ({file}) => {
    const {i18n} = useI18n()

    return (
        <FileListItem disablePadding key={file.name}>
            <FileListItemIcon>
                {file.type === 'audio' ? (
                    <MusicNote1 size={24} />
                ) : (
                    <Video size={24} />
                )}
            </FileListItemIcon>
            <ListItemText
                primaryTypographyProps={{
                    color: file.exists ? 'textPrimary' : 'error',
                }}
                primary={`${file.name} -> ${i18n.t(
                    `upload.${file.exists ? 'fileExists' : 'fileMissing'}`
                )}`}
            />
        </FileListItem>
    )
}

const FileListItem = styled(ListItem)`
    gap: 8px;
    width: fit-content;
`

const FileListItemIcon = styled(ListItemIcon)`
    min-width: 0;
`

export default FilmedDateForUploadItem
