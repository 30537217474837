import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const dancesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getDances: build.query({
            query: (query) => {
                const url = constructUrl('dances', {
                    ...query,
                    sort: query.sort ?? JSON.stringify({created: 'desc'}),
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Dance'),
        }),
        getDance: build.query({
            query: (danceId) => ({
                url: `dances/${danceId}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Dance', result),
        }),
        createDance: build.mutation({
            query: (body) => {
                return {
                    url: `dances`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Dance'),
        }),
        saveDance: build.mutation({
            query: (dance) => {
                return {
                    url: `dances/${dance._id}`,
                    method: 'PUT',
                    body: dance,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Dance', result),
        }),
        deleteDance: build.mutation({
            query: (id) => {
                return {
                    url: `dances/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Dance'),
        }),
        getFilmedDancesDates: build.query({
            query: () => {
                return {
                    url: 'dances/filmedDancesDates',
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Dance'),
        }),
        uploadDanceMedia: build.mutation({
            query: (body) => {
                return {
                    url: 'uploads/dance',
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Dance'),
        }),
        deleteVideo: build.mutation({
            query: ({danceId, videoType}) => {
                return {
                    url: `dances/video/${danceId}/${videoType}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Dance'),
        }),
    }),
    overrideExisting: false,
})

export default dancesApi

export const {
    useGetDancesQuery,
    useGetDanceQuery,
    useCreateDanceMutation,
    useSaveDanceMutation,
    useDeleteDanceMutation,
    useDownloadFilmedDateMutation,
    useUploadDanceMediaMutation,
    useDeleteVideoMutation,
    useGetFilmedDancesDatesQuery,
} = dancesApi
