import {HEADER_MENUS} from 'constants/header'
import {setLocale} from 'localization/i18n'
import {useState} from 'react'
import {useGetCurrentUserQuery} from 'store/Store'

const useHeaderMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const isOpen = Boolean(anchorEl)

    const [anchors, setAnchors] = useState({
        language: null,
        profile: null,
        mobile: null,
    })
    const isMenuOpen = (anchorName) => Boolean(anchors[anchorName])

    const handleOpenMenu = (anchorName) => (event) => {
        setAnchors({...anchors, [anchorName]: event.target})
    }

    const handleCloseMenu = (anchorName) => (event) => {
        setAnchors({...anchors, [anchorName]: null})
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleOpen = (event) => {
        setAnchorEl(event.target)
    }

    const handleSetLanguage = (lang) => {
        setLocale(lang)
        setAnchors(HEADER_MENUS)
    }

    const {data: user} = useGetCurrentUserQuery()
    const loggedInEmail = user?.email

    return {
        isOpen,
        anchorEl,
        handleSetLanguage,
        handleClose,
        handleOpen,
        loggedInEmail,
        anchors,
        isMenuOpen,
        handleOpenMenu,
        handleCloseMenu,
    }
}

export default useHeaderMenu
