import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const artistsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getArtists: build.query({
            query: (query) => {
                const url = constructUrl('artists', {
                    ...query,
                    sort: query.sort ?? JSON.stringify({created: 'desc'}),
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Artist'),
        }),
        getArtist: build.query({
            query: (id) => ({
                url: `artists/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Artist', result),
        }),
        createArtist: build.mutation({
            query: (body) => {
                return {
                    url: `artists`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Artist'),
        }),
        saveArtist: build.mutation({
            query: (body) => {
                return {
                    url: `artists/${body._id}`,
                    method: 'PUT',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Artist', result),
        }),
        deleteArtist: build.mutation({
            query: (id) => {
                return {
                    url: `artists/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Artist'),
        }),
    }),
    overrideExisting: false,
})

export default artistsApi

export const {
    useGetArtistsQuery,
    useGetArtistQuery,
    useCreateArtistMutation,
    useSaveArtistMutation,
    useDeleteArtistMutation,
} = artistsApi
