import {Controller} from 'react-hook-form'
import Autocomplete from '../Autocomplete'
import {InputContainer} from 'components/Page/Form/Forms'

const FormAutocomplete = ({
    name,
    label,
    control,
    error,
    options,
    required,
    getOptionLabel,
    inputValue,
    onChangeInputValue,
    isLoading,
    placeholder,
    hidden,
    onSelectValue,
    clearOnBlur,
}) => {
    const handleSelectValue = (onChange) => (event, value) => {
        onSelectValue ? onSelectValue(value) : onChange(value)
    }

    return (
        !hidden && (
            <InputContainer>
                <Controller
                    name={name}
                    control={control}
                    render={({field: {onChange, value}}) => {
                        return (
                            <Autocomplete
                                name={name}
                                label={label}
                                required={required}
                                error={error}
                                placeholder={placeholder}
                                isLoading={isLoading}
                                options={options}
                                getOptionLabel={getOptionLabel}
                                value={value || ''}
                                onChange={handleSelectValue(onChange)}
                                inputValue={inputValue}
                                onInputChange={(event, value) =>
                                    onChangeInputValue(value)
                                }
                                clearOnBlur={clearOnBlur}
                            />
                        )
                    }}
                />
            </InputContainer>
        )
    )
}

export default FormAutocomplete
