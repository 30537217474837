const en = {
    dir: 'ltr',
    header: {
        search: 'Search',
        title: 'Rokdim',
    },
    formErrors: {
        required: 'This field is required',
        emailError: 'Invalid email address',
        numberError: 'Numeric values only',
        invalidDate: 'Invalid date',
        endTimeBeforeStart: "End time can't be before start time",
        startTimeAfterEnd: "Start time can't be after end time",
        invalidEmail: 'Invalid email address',
        duplicateKey: '{{key}} already exists',
        somethingWentWrong: 'Something went wrong',
        invalidUrl: 'Invalid link URL',
    },
    login: {
        email: 'Email',
        password: 'Password',
        forgotPassword: 'Forgot password?',
        signIn: 'Sign In',
        rememberMe: 'Remember Me',
    },
    dashboard: {
        downloadsReport: 'Downloads report',
        filmedDateDownload: 'Download filmed date',
        from: 'From date',
        to: 'To date',
        download: 'Download',
        filmedDate: 'Filmed date',
        language: 'Language',
        languages: {
            he: 'Hebrew',
            en: 'English',
        },
    },
    profileMenu: {
        loggedInAs: 'Logged in As:',
        logout: 'Logout',
    },
    menu: {
        home: 'Home',
        users: 'Users',
        guides: 'Guides',
        artists: 'Artists',
        dances: 'Dances',
        danceCollections: 'Dance collections',
        sessions: 'Sessions',
        moreData: 'More data',
        messages: 'Messages',
        payments: 'Payments',
        publications: 'Publications',
        banners: 'Banners',
        newspapers: 'Newspapers',
    },
    form: {
        update: 'Update',
        create: 'Create',
        edit: 'Edit',
        link: 'link',
        add: 'Add',
        keyError: 'valid {{key}} is required.',
        not: 'Not',
        save: 'Save',
        cancel: 'Cancel',
        autocompleteNoOptionsText: 'No options',
        selectFile: 'Select file',
        noFile: 'No file selected',
        upload: 'Upload',
        yes: 'Yes',
        no: 'No',
        ok: 'Ok',
        new: 'New',
        savedSuccessfully: '{{itemName}} saved successfully',
        dragAndDrop: 'Drag and drop a file here or click to upload',
        filterBy: 'Filter by {{key}}',
    },
    general: {
        invalid_password: 'Password must contain a minimum of 8 characters',
        invalid_verify_password: 'Passwords do not match',
    },
    tables: {
        actions: 'Actions',
        users: 'Users',
        user: 'User',
        invites: 'Invites',
        accounts: 'Accounts',
        addNew: 'Add New {{itemName}}',
        countries: 'Countries',
        countrie: 'Country',
        artists: 'Artists',
        artist: 'Artist',
        cities: 'Cities',
        citie: 'City',
        shapes: 'Shapes',
        shape: 'Shape',
        types: 'Types',
        type: 'Type',
        guides: 'Guides',
        guide: 'Guide',
        dances: 'Dances',
        dance: 'Dance',
        danceCollections: 'Dance collections',
        danceCollection: 'Dance collection',
        yes: 'Yes',
        no: 'No',
        sessions: 'Sessions',
        session: 'Session',
        messages: 'Messages',
        message: 'Message',
        payments: 'Payments',
        payment: 'Payment',
        publications: 'Publications',
        publication: 'Publication',
        banners: 'Banners',
        banner: 'Banner',
        newspapers: 'Newspapers',
        newspaper: 'Newspaper',
        copyToClipboard: 'Copy',
        copiedToClipboard: 'Copied to clipboard',
    },
    accounts: {
        _id: 'ID',
        name: 'Name',
        apiKey: 'Api Key',
        created: 'Created',
        updated: 'Updated',
        role: 'Role',
        confirmed: 'Confirmed',
    },
    users: {
        _id: 'ID',
        email: 'Email',
        firstName: 'First Name',
        lastName: 'Last Name',
        isSuper: 'Is Admin',
        created: 'Created',
        lastActivity: 'Last activity',
        homePhone: 'Home phone',
        mobilePhone: 'Mobile phone',
        facebookId: 'Facebook ID',
        country: 'Country',
        clubType: 'Club type',
        clubPeriod: 'Club period',
        clubRegistrationDate: 'Club registration date',
        giftDownloads: 'Gift downloads',
        remainingDownloads: 'Remaining downloads',
        guide: 'Guide',
        purchasedDances: 'Purchased dances',
        updated: 'Updated',
        role: 'Role',
        videoViews: 'Video views',
        audioViews: 'Audio views',
        downloads: 'Downloads',
        purchases: 'Purchases',
        oldUserId: 'Old ID',
        yesNo: {
            yes: 'Yes',
            no: 'No',
        },
        clubTypes: {
            none: 'None',
            vip: 'VIP',
            vip_plus: 'VIP plus',
        },
        clubPeriods: {
            monthly: 'Monthly',
            yearly: 'Yearly',
        },
        firstNameHeb: 'First Name Heb',
        lastNameHeb: 'Last Name Heb',
        firstNameEng: 'First Name Eng',
        lastNameEng: 'Last Name Eng',
        fax: 'Fax',
        address: 'Address',
        city: 'City',
        websiteUrl: 'Website URL',
        imageUrl: 'Image URL',
        dateOfBirth: 'Date of birth',
        isDead: 'Is dead',
        dateOfDeath: 'Date of death',
        resumeEng: 'Resume Eng',
        resumeHeb: 'Resume Heb',
        inIsrael: 'In Israel',
    },
    moreData: {
        _id: 'ID',
        nameEng: 'Name Eng',
        nameHeb: 'Name Heb',
    },
    guides: {
        _id: 'ID',
        firstNameHeb: 'First Name Heb',
        lastNameHeb: 'Last Name Heb',
        firstNameEng: 'First Name Eng',
        lastNameEng: 'Last Name Eng',
        email: 'Email',
    },
    dances: {
        _id: 'ID',
        codeShort: 'Code short',
        nameEng: 'Name Eng',
        nameHeb: 'Name Heb',
        choreographers: 'Choreographers',
        choreographersDisplayHeb: 'Choreographers',
        shapes: 'Shape',
        videoViews: 'Video views',
        videoFullViews: 'Full views',
        videoTeachViews: 'Teach views',
        videoDanceViews: 'Dance views',
        yearCreated: 'Year created',
        files: 'Files',
        isHidden: 'Is hidden',
        youtubeId: 'Youtube ID',
        youtubeStatus: 'Youtube status',
        videoFile: 'Video file',
        songName: 'Song name',
        filmedDate: 'Filmed date',
        composers: 'Composers',
        performers: 'Performers',
        companies: 'Companies',
        lyricists: 'Lyricists',
        youtubeIds: 'Youtube IDs',
        facebookIds: "Facebook's video IDs",
        lyricsHeb: 'Lyrics Heb',
        lyricsEng: 'Lyrics Eng',
        danceStepsHeb: 'Dance steps Heb',
        danceStepsEng: 'Dance steps Eng',
        danceStoryHeb: 'Dance story Heb',
        danceStoryEng: 'Dance story Eng',
        audioViews: 'Audio views',
        downloads: 'Downloads',
        types: {
            SPECIAL_NEEDS: 'Special needs Groups',
            SEATING_AND_STANDING: 'Seating and standing',
            WOMEN_ONLY: 'Women only',
            GOLDEN_AGE: 'Golden age',
            SALSA_AND_RUEDA: 'Salsa and rueda',
            BALLROOM_AND_LATIN: 'Ballroom and latin',
            CHILDREN: 'Children',
            INTERNATIONAL: 'International',
            WORKSHOPS: 'Workshops',
            NOSTALGIA: 'Nostalgia',
            MARATHONS_AND_SPECIAL: 'Marathons & special sessions',
            FESTIVALS_WEEKENDS_TRIPS: 'Festivals, weekends, trips',
            WEEKLY: 'Weekly sessions',
        },
        shapesTypes: {
            SESSION: 'Session',
            IMITATION_FOR_CHILDREN: 'Imitation for children',
            WHEELCHAIRS: 'Wheelchairs',
            CHILDREN_LINES: 'Children - lines',
            TRIOS: 'Trios',
            MEDLEY: 'Medley',
            CHILDREN_COUPLES: 'Children - couples',
            CHILDREN_CIRCLE: 'Children - circle',
            PERFORMANCE: 'Performance',
            DANCE_DELIGHT: 'Dance delight',
            LINES: 'Lines',
            COUPLES: 'Couples',
            CIRCLE: 'Circle',
        },
        shapess: {
            SESSION: 'Session',
            IMITATION_FOR_CHILDREN: 'Imitation for children',
            WHEELCHAIRS: 'Wheelchairs',
            CHILDREN_LINES: 'Children - lines',
            TRIOS: 'Trios',
            MEDLEY: 'Medley',
            CHILDREN_COUPLES: 'Children - couples',
            CHILDREN_CIRCLE: 'Children - circle',
            PERFORMANCE: 'Performance',
            DANCE_DELIGHT: 'Dance delight',
            LINES: 'Lines',
            COUPLES: 'Couples',
            CIRCLE: 'Circle',
        },
        uploadMedia: 'Upload media',
        fileType: 'Select file type',
        fileTypes: {
            fv: 'Full video',
            af: 'Full audio',
            dv: 'Dance video',
            tv: 'Teach video',
        },
        deleteVideoButton: 'Delete video',
        uploadFullFilmedDate: 'Upload full filmed date',
        youtubeUploadStatus: 'Youtube upload status',
        selectDance: 'Select a dance',
        newDance: 'New dance',
        videoTeach: 'Teach video',
        videoDance: 'Dance video',
        videoFull: 'Full video',
        audio: 'Audio',
    },
    danceCollections: {
        _id: 'ID',
        created: 'Created',
        hidden: 'Hidden',
        nameHeb: 'Name Heb',
        nameEng: 'Name Eng',
        type: 'Type',
        dances: 'Dances',
        addDance: 'Add dance',
        types: {
            top_hits: 'Top hits',
            release_batch: 'Release batch',
            course: 'Course',
            contest: 'Contest',
        },
        dancesPlaceholder: 'Search for dance',
    },
    sessions: {
        _id: 'ID',
        guides: 'Guides',
        country: 'Country',
        city: 'City',
        day: 'Day',
        description: 'Description',
        isWeekly: 'Is weekly',
        isActive: 'Is active',
        highlight: 'Highlight',
        price: 'Price',
        airConditioner: 'Air conditioner',
        type: 'Type',
        address: 'Address',
        placeName: 'Place name',
        occurrence: 'Occurrence',
        occurrences: {
            ONCE: 'Once',
            WEEKLY: 'Weekly',
            MONTHLY: 'Monthly',
        },
        week: 'Week',
        startDateAndTime: 'Start date and time',
        endDateAndTime: 'End date and time',
        startDateBeginners: 'Start date for beginners',
        timeTable: 'Time table',
        time: 'Time',
        hour: 'Hour',
        timeStart: 'Time start',
        timeEnd: 'Time end',
        shape: 'Shape',
        level: 'Level',
        levels: {
            BEGINNERS: 'Beginners',
            MIDDLES: 'Middles',
            ADVANCED: 'Advanced',
            MIXED: 'Mixed',
        },
        picture: 'Picture',
        days: {
            1: 'Sunday',
            2: 'Monday',
            3: 'Tuesday',
            4: 'Wednesday',
            5: 'Thursday',
            6: 'Friday',
            7: 'Saturday',
        },
        learnedDances: 'Learned Dances',
    },
    messages: {
        _id: 'ID',
        created: 'Created',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        mobilePhone: 'Mobile Phone',
        address: 'Address',
        city: 'City',
        message: 'Message',
        modalTitle: 'View message',
        markAsSeen: 'Mark as seen',
        rightToLeft: 'Right to left',
        deleteMessage: 'Are you sure you want to delete this message?',
    },
    payments: {
        _id: 'ID',
        userName: 'User Name',
        userEmail: 'Email',
        created: 'Created',
        amount: 'Amount',
        currency: 'Currency',
        packageId: 'Package ID',
        subscriptionId: 'Subscription ID',
        transactionId: 'Transaction ID',
        success: 'Success',
        dance: 'Dance',
    },
    publications: {
        _id: 'ID',
        created: 'Created',
        date: 'Date',
        detailsEng: 'Details Eng',
        detailsHeb: 'Details Heb',
        guide: 'Guide',
        expirationDate: 'Expiration date',
        isActive: 'Is active',
        moreDetailsHeb: 'More details Heb',
        moreDetailsEng: 'More details Eng',
        link: 'Link (http://...)',
        linkTitleEng: 'Link title Eng',
        linkTitleHeb: 'Link title Heb',
        flyerUrl: 'Flyer URL',
        flyer: 'Flyer',
    },
    banners: {
        _id: 'ID',
        created: 'Created',
        name: 'Name',
        websiteUrl: 'Website URL',
        location: 'Location',
        imgUrl: 'Banner',
        isActive: 'Is active Hebrew',
        isActiveEng: 'Is active English',
        width: 'Width (pixels)',
        height: 'Height (pixels)',
        locations: {
            home: 'Home page',
            dance: 'Dance page',
        },
        picture: 'Picture',
    },
    newspapers: {
        _id: 'ID',
        issueNumber: 'Issue number',
        issueTitle: 'Issue title',
        issueTitleHebrew: 'Issue title Hebrew',
        downloadsCounter: 'Downloads',
        activated: 'Activated',
        isVipOnly: 'VIP',
        vipOnly: 'VIP only',
        pdf: 'PDF\n EN | HE',
        pdfUrl: 'Issue file url (PDF)',
        pdfUrlHebrew: 'Hebrew issue file url (PDF)',
        thumbnail: 'JPG\n EN | HE',
        thumbnailUrl: 'Thumbnail file url (JPG)',
        thumbnailUrlHebrew: 'Hebrew thumbnail file url (JPG)',
        uploadIssue: 'Upload Issue (PDF)',
        uploadThumbnail: 'Upload Thumbnail (JPG)',
        fileType: 'File type',
        language: 'Language',
        fileTypes: {
            pdf: 'PDF',
            thumbnail: 'Thumbnail',
        },
        languages: {
            he: 'He',
            en: 'En',
        },
    },
    upload: {
        processStatus: 'Process is: {{status}}',
        processStatuses: {
            idle: 'Idle',
            waiting: 'Waiting',
            running: 'Running',
        },
        filmedDate: 'Filmed date',
        filesWaitingForUpload: 'Files waiting for upload:',
        uploadButton: 'Upload',
        noFiles: 'No files waiting for upload',
        fileMissing: 'File missing',
        fileExists: 'File exists',
    },
}

export default en
