import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const publicationApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getPublications: build.query({
            query: (query) => {
                const url = constructUrl('publications', {
                    ...query,
                    sort: query.sort ?? JSON.stringify({created: 'desc'}),
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Publication'),
        }),
        getPublication: build.query({
            query: (id) => ({
                url: `publications/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Publication', result),
        }),
        createPublication: build.mutation({
            query: (body) => {
                return {
                    url: `publications`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Publication'),
        }),
        savePublication: build.mutation({
            query: (body) => {
                return {
                    url: `publications/${body._id}`,
                    method: 'PUT',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Publication', result),
        }),
        deletePublication: build.mutation({
            query: (id) => {
                return {
                    url: `publications/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Publication'),
        }),
    }),
    overrideExisting: false,
})

export default publicationApi

export const {
    useGetPublicationsQuery,
    useGetPublicationQuery,
    useDeletePublicationMutation,
    useSavePublicationMutation,
    useCreatePublicationMutation,
} = publicationApi
