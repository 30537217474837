import {FormContainer, FormColumn, FormRow} from '../Form/Forms'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import FormSelect from 'components/Control/Form/FormSelect'
import {yesNoOptions} from 'constants/users'
import UploadFiles from './UploadFiles'
import {styled} from '@mui/material'

const NewspaperForm = ({uploadImage, imagesUrls}) => {
    const {i18n} = useI18n()

    const formContext = useFormContext()
    return (
        <FormContainer>
            <FormRow>
                <FormTextInput
                    name="issueTitle"
                    label={i18n.t(`newspapers.issueTitle`)}
                    control={formContext.control}
                    error={formContext.formState.errors.issueTitle}
                />

                <FormTextInput
                    name="issueTitleHebrew"
                    label={i18n.t(`newspapers.issueTitleHebrew`)}
                    control={formContext.control}
                    error={formContext.formState.errors.issueTitleHebrew}
                />

                <FormTextInput
                    name="issueNumber"
                    label={i18n.t(`newspapers.issueNumber`)}
                    control={formContext.control}
                    error={formContext.formState.errors.issueNumber}
                />
            </FormRow>
            <FormRow>
                <FormColumn>
                    <FormTextInput
                        name="pdfUrl"
                        label={i18n.t(`newspapers.pdfUrl`)}
                        control={formContext.control}
                        error={formContext.formState.errors.pdfUrl}
                    />
                    <FormTextInput
                        name="pdfUrlHebrew"
                        label={i18n.t(`newspapers.pdfUrlHebrew`)}
                        control={formContext.control}
                        error={formContext.formState.errors.pdfUrlHebrew}
                    />
                    <FormRow>
                        <FormSelect
                            itemsName="users"
                            optionsName="yesNo"
                            name="isVipOnly"
                            label={i18n.t(`newspapers.vipOnly`)}
                            options={yesNoOptions}
                            control={formContext.control}
                            error={formContext.formState.errors.isVipOnly}
                        />

                        <FormSelect
                            itemsName="users"
                            optionsName="yesNo"
                            name="activated"
                            label={i18n.t(`newspapers.activated`)}
                            options={yesNoOptions}
                            control={formContext.control}
                            error={formContext.formState.errors.activated}
                        />
                    </FormRow>
                </FormColumn>

                <FormColumn>
                    <ThumbnailInputWrapper>
                        <FormTextInput
                            name="thumbnailUrl"
                            label={i18n.t(`newspapers.thumbnailUrl`)}
                            control={formContext.control}
                            error={formContext.formState.errors.thumbnailUrl}
                        />
                        {imagesUrls.thumbnailUrl && (
                            <ThumbnailPreview
                                src={imagesUrls.thumbnailUrl}
                                alt="thumbnail"
                            />
                        )}
                    </ThumbnailInputWrapper>

                    <ThumbnailInputWrapper>
                        <FormTextInput
                            name="thumbnailUrlHebrew"
                            label={i18n.t(`newspapers.thumbnailUrlHebrew`)}
                            control={formContext.control}
                            error={
                                formContext.formState.errors.thumbnailUrlHebrew
                            }
                        />
                        {imagesUrls.thumbnailUrlHebrew && (
                            <ThumbnailPreview
                                src={imagesUrls.thumbnailUrlHebrew}
                                alt="thumbnail"
                            />
                        )}
                    </ThumbnailInputWrapper>
                </FormColumn>
            </FormRow>

            <UploadFiles uploadImage={uploadImage} imagesUrls={imagesUrls} />
        </FormContainer>
    )
}

export default NewspaperForm

const ThumbnailInputWrapper = styled('div')`
    display: flex;
    gap: 8px;
    align-items: end;
`

const ThumbnailPreview = styled('img')`
    max-height: 92px;
    object-fit: cover;
    width: 100px;
`
