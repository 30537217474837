import {TableSortLabel} from '@mui/material'
import useTableSort from 'logic/Table/useTableSort'

const TableSort = ({itemName, itemKey}) => {
    const {showSort, toggleDirection, direction} = useTableSort(
        itemName,
        itemKey
    )

    if (!showSort) {
        return null
    }

    return (
        <TableSortLabel
            active={!!direction}
            direction={direction}
            onClick={toggleDirection}
        />
    )
}

export default TableSort
