import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import Button from 'components/Control/Button'
import {useIsRtl} from 'store/Store'

const Modal = ({
    open,
    onClose,
    title,
    description,
    children,
    buttons,
    titleComponent,
}) => {
    const isRtl = useIsRtl()

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            dir={isRtl ? 'rtl' : 'lte'}>
            {titleComponent ?? <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
                {children}
            </DialogContent>
            {buttons && (
                <DialogActions>
                    {buttons?.map((button, index) => (
                        <Button
                            variant={index === 0 ? 'text' : 'contained'}
                            key={button.label}
                            onClick={button.onClick}
                            loading={button.loading}
                            disabled={button.disabled}>
                            {button.label}
                        </Button>
                    ))}
                </DialogActions>
            )}
        </Dialog>
    )
}

export default Modal
