import {Card, CardContent, IconButton, styled} from '@mui/material'
import {Texts} from 'appearance'
import {dancesListKeys} from 'constants/dances'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {formatUserArrEng} from 'services/utils/data'

const DanceListItem = ({dance, index, handleRemoveDance, handleMoveDance}) => {
    const itemText = (key) =>
        key === 'choreographers' ? formatUserArrEng(dance?.[key]) : dance[key]

    return (
        <Container>
            <ButtonsContainer>
                <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => handleMoveDance(index, index - 1)}>
                    <ArrowCircleUpIcon />
                </IconButton>

                <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => handleMoveDance(index, index + 1)}>
                    <ArrowCircleDownIcon />
                </IconButton>

                <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => handleRemoveDance(index)}>
                    <HighlightOffIcon />
                </IconButton>
            </ButtonsContainer>
            <DanceDataWrapper variant="outlined">
                <DanceData>
                    {dancesListKeys.map((key) => (
                        <DanceDataText key={key}>{itemText(key)}</DanceDataText>
                    ))}
                </DanceData>
            </DanceDataWrapper>
        </Container>
    )
}

export default DanceListItem

const Container = styled('div')`
    display: flex;
    align-items: center;
    gap: 24px;
`

const DanceDataWrapper = styled(Card)`
    flex-grow: 1;
`

const DanceData = styled(CardContent)`
    padding: 10px;
    display: flex;
    justify-content: space-between;
    &:last-child {
        padding-bottom: 10px;
    }
`

const DanceDataText = styled(Texts.Text)`
    font-size: 14px;
    font-weight: 300;
    flex: 1;
`

const ButtonsContainer = styled('div')`
    display: flex;
    align-items: center;
    flex-grow: 0;
`
