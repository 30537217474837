import tables from 'constants/tables'
import {useUrlParams} from 'services/hooks'
import {setSortDirection, getSortDirection} from 'services/utils/tables'

const useTableSort = (itemName, itemKey) => {
    const tableName = `${itemName}s`
    const {urlParams, setUrlParam} = useUrlParams()
    const {[`${itemName}_sort`]: sort} = urlParams
    const direction = getSortDirection(itemKey, sort)

    const toggleDirection = () => {
        setSortDirection(
            itemName,
            itemKey,
            direction === 'asc' ? 'desc' : 'asc',
            setUrlParam
        )
    }

    const showSort = tables[tableName].sortableKeys?.includes(itemKey)

    return {showSort, toggleDirection, direction}
}

export default useTableSort
