import useTable from 'logic/Table/useTable'
import TableTitle from './TableTitle'
import TableComponent from './TableComponent'

const DataTable = ({
    itemName,
    useGetQuery,
    useDeleteMutation,
    paginationType,
    visibleKeys,
    allowEdit,
    allowDelete,
    onEdit,
    onCreate,
    translationKey,
    allowAdd,
    allowView,
    onViewItem,
    additionalActions,
    additionalRowActions,
}) => {
    const {
        tableName,
        tableData,
        totalItems,
        doDelete,
        editOrCreateItem,
        columns,
        isLoading,
        page,
        pageSize,
        pagination,
        setPagination,
        setColumnFilters,
        setSorting,
        columnFilters,
        sorting,
        tableSearch,
        setTableSearch,
        columnVisibility,
        setColumnVisibility,
    } = useTable({
        itemName,
        useGetQuery,
        paginationType,
        useDeleteMutation,
        visibleKeys,
        onEdit,
        onCreate,
        translationKey,
    })

    return (
        <>
            <TableTitle
                tableName={tableName}
                itemName={itemName}
                handleAddNew={editOrCreateItem}
                allowAdd={allowAdd}
                allowView={allowView}
                additionalActions={additionalActions}
            />

            <TableComponent
                columns={columns}
                data={tableData}
                allowEdit={allowEdit}
                allowDelete={allowDelete}
                allowView={allowView}
                onViewItem={onViewItem}
                doDelete={doDelete}
                editOrCreateItem={editOrCreateItem}
                totalItems={totalItems}
                isLoading={isLoading}
                paginationType={paginationType}
                pageSize={pageSize}
                page={page}
                pagination={pagination}
                setPagination={setPagination}
                setColumnFilters={setColumnFilters}
                setSorting={setSorting}
                columnFilters={columnFilters}
                sorting={sorting}
                tableSearch={tableSearch}
                setTableSearch={setTableSearch}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                additionalActions={additionalRowActions}
            />
        </>
    )
}

export default DataTable
