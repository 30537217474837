import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {useNavigate} from 'react-router-dom'

const useTableActions = (
    itemName,
    allowEdit,
    onDelete,
    onExpand,
    isExpanded,
    itemId,
    tableRowData,
    tableName
) => {
    const navigate = useNavigate()

    const getExpandIcon = (isExpanded) =>
        isExpanded ? ExpandLessIcon : ExpandMoreIcon

    const isVisible = () => allowEdit || onDelete || onExpand

    const ExpandIcon = getExpandIcon(isExpanded)

    const handleEdit = () => {
        navigate(`/${itemName}/${itemId}`)
    }

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete?') === true) {
            onDelete(itemId, tableRowData)
            navigate(`/${tableName}`)
        }
    }

    return {ExpandIcon, isVisible, handleEdit, handleDelete}
}

export default useTableActions
