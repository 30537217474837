import {object, string} from 'yup'

export const publicationSchema = object({
    link: string()
        .url('invalidUrl')
        .transform((currentValue) => {
            const doesNotStartWithHttp =
                currentValue &&
                !(
                    currentValue.startsWith('http://') ||
                    currentValue.startsWith('https://')
                )

            return doesNotStartWithHttp
                ? `http://${currentValue}`
                : currentValue
        }),
})
