import {styled} from '@mui/material'
import {Texts} from 'appearance'
import FormAutocomplete from 'components/Control/Form/FormAutocomplete'
import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import DancesList from './DancesList/DancesList'

const Dances = ({
    setInputValue,
    searchValue,
    dances,
    searchResults,
    onSelectValue,
    handleRemoveDance,
    handleMoveDance,
}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const {
        control,
        formState: {errors},
    } = formContext

    const getOptionLabel = ({
        _id,
        nameEng,
        nameHeb,
        choreographersDisplayEng,
    }) => {
        const parts = [_id, nameEng, nameHeb, choreographersDisplayEng].filter(
            Boolean
        )
        return parts.join(' | ')
    }

    return (
        <Container>
            <Title color="label">{i18n.t('danceCollections.addDance')}</Title>

            <FormAutocomplete
                fullWidth
                clearOnBlur
                name="dances"
                control={control}
                error={errors.country}
                options={searchResults?.data}
                getOptionLabel={getOptionLabel}
                inputValue={searchValue}
                onChangeInputValue={setInputValue}
                placeholder={i18n.t('danceCollections.dancesPlaceholder')}
                onSelectValue={onSelectValue}
            />

            <DancesList
                dances={dances}
                handleRemoveDance={handleRemoveDance}
                handleMoveDance={handleMoveDance}
            />
        </Container>
    )
}

export default Dances

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    padding-block: 12px;
`

const Title = styled(Texts.Text)`
    font-size: 16px;
    font-weight: 400;
`
