import {React} from 'common'
import IconButton from '@mui/material/IconButton'
import {useLogoutMutation} from 'store/Store'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailIcon from '@mui/icons-material/Mail'
import NotificationsIcon from '@mui/icons-material/Notifications'

const MobileMenu = ({
    mobileMenuId,
    handleCloseMenu,
    isMenuOpen,
    mobileMoreAnchorEl,
    // setMobileMoreAnchorEl,
}) => {
    const [doLogout] = useLogoutMutation()

    // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    // const handleProfileMenuOpen = (event) => {
    //     setAnchorEl(event.currentTarget)
    // }

    // const handleMobileMenuClose = () => {
    //     setMobileMoreAnchorEl(null)
    // }

    return (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen('mobile')}
            onClose={handleCloseMenu('mobile')}>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 4 new mails"
                    color="inherit">
                    <Badge badgeContent={4} color="error">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit">
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleCloseMenu('profile')}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit">
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
            <MenuItem onClick={doLogout}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit">
                    <AccountCircle />
                </IconButton>
                <p>Logout</p>
            </MenuItem>
        </Menu>
    )
}

export default MobileMenu
