import {DialogTitle, styled} from '@mui/material'
import Checkbox from 'components/Control/Checkbox'
import {useI18n} from 'store/Store'

const MessageModalTitle = ({title, seen, onMarkAsSeen}) => {
    const {i18n} = useI18n()
    return (
        <Container>
            <Title>{title}</Title>

            <Checkbox
                onChange={onMarkAsSeen}
                label={i18n.t('messages.markAsSeen')}
                value={seen}
            />
        </Container>
    )
}

export default MessageModalTitle

const Container = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px 0;
`

const Title = styled(DialogTitle)`
    padding: 0;
`
