import {React} from 'common'
import {useNavigate} from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import Button from 'components/Control/Button'
import {useIsRtl} from 'store/Store'

const TableAddButton = ({itemName, show}) => {
    const navigate = useNavigate()
    const isRtl = useIsRtl()

    if (!show) {
        return null
    }

    return (
        <Button
            onClick={() => navigate(`/${itemName}/new`)}
            variant="contained"
            sx={{
                height: 50,
                width: 50,
                minWidth: 50,
                borderRadius: '30px',
                marginLeft: isRtl ? '0px' : '20px',
                marginRight: isRtl ? '20px' : '0px',
            }}>
            <AddIcon />
        </Button>
    )
}

export default TableAddButton
