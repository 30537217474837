import {bannersLocations} from './banners'
import {danceCollectionTypes} from './danceCollections'
import {shapes} from './dances'
import {days} from './sessions'
import {clubTypes} from './users'

export const PAGE_SIZES = [5, 10, 25, 50, 100]
export const INITIAL_PAGE_SIZE = 5

const tables = {
    accounts: {
        visibleKeys: ['_id', 'name', 'apiKey', 'created', 'role', 'confirmed'],
        keyTypes: {
            name: 'text',
            apiKey: 'text',
            _id: 'text',
            created: 'datetime',
            role: 'text',
            confirmed: 'boolean',
        },
        sortableKeys: ['_id', 'name', 'role', 'created'],
        editableKeys: ['name'],
        requiredKeys: ['name'],
    },
    users: {
        visibleKeys: [
            '_id',
            'firstName',
            'lastName',
            'country',
            'email',
            'created',
            'lastActivity',
            'videoViews',
            'audioViews',
            'clubType',
            'oldUserId',
        ],
        keyTypes: {
            _id: 'longText',
            firstName: 'text',
            lastName: 'text',
            country: 'itemName',
            email: 'text',
            created: 'datetime',
            lastActivity: 'datetime',
            videoViews: 'text',
            audioViews: 'text',
            clubType: 'text',
            oldUserId: 'text',
        },
        filters: {
            firstName: {
                variant: 'text',
            },
            lastName: {
                variant: 'text',
            },
            country: {
                variant: 'autocomplete',
            },
            email: {variant: 'text'},
            created: {
                variant: 'date',
            },
            lastActivity: {
                variant: 'date',
            },
            clubType: {variant: 'multi-select', selectOptions: clubTypes},
            oldUserId: {variant: 'text'},
        },
        sortableKeys: [
            '_id',
            'firstName',
            'lastName',
            'country',
            'email',
            'created',
            'lastActivity',
            'videoViews',
            'audioViews',
            'clubType',
            'oldId',
        ],
    },
    artists: {
        visibleKeys: ['_id', 'nameEng', 'nameHeb'],
        keyTypes: {
            _id: 'longText',
            nameEng: 'text',
            nameHeb: 'text',
        },
        sortableKeys: ['_id', 'nameEng', 'nameHeb'],
    },
    countries: {
        visibleKeys: ['_id', 'nameEng', 'nameHeb'],
        keyTypes: {
            _id: 'text',
            nameEng: 'text',
            nameHeb: 'text',
        },
        sortableKeys: ['_id', 'nameEng', 'nameHeb'],
    },
    cities: {
        visibleKeys: ['_id', 'nameEng', 'nameHeb'],
        keyTypes: {
            _id: 'longText',
            nameEng: 'text',
            nameHeb: 'text',
        },
        sortableKeys: ['_id', 'nameEng', 'nameHeb'],
    },
    shapes: {
        visibleKeys: ['_id', 'nameEng', 'nameHeb'],
        keyTypes: {
            _id: 'longText',
            nameEng: 'text',
            nameHeb: 'text',
        },
        sortableKeys: ['_id', 'nameEng', 'nameHeb'],
    },
    types: {
        visibleKeys: ['_id', 'nameEng', 'nameHeb'],
        keyTypes: {
            _id: 'longText',
            nameEng: 'text',
            nameHeb: 'text',
        },
        sortableKeys: ['_id', 'nameEng', 'nameHeb'],
    },
    guides: {
        visibleKeys: [
            '_id',
            'firstNameHeb',
            'lastNameHeb',
            'firstNameEng',
            'lastNameEng',
            'email',
        ],
        keyTypes: {
            _id: 'longText',
            firstNameHeb: 'text',
            lastNameHeb: 'text',
            firstNameEng: 'text',
            lastNameEng: 'text',
            email: 'text',
        },
        sortableKeys: [
            'firstNameHeb',
            'lastNameHeb',
            'firstNameEng',
            'lastNameEng',
            'email',
        ],
    },
    dances: {
        visibleKeys: [
            '_id',
            'codeShort',
            'nameHeb',
            'choreographersDisplayHeb',
            'shapes',
            'videoViews',
            'yearCreated',
            'files',
        ],
        keyTypes: {
            _id: 'longText',
            codeShort: 'text',
            nameHeb: 'text',
            choreographersDisplayHeb: 'choreographersNames',
            shapes: 'translatedNamesArray',
            videoViews: 'videoViews',
            yearCreated: 'text',
            files: 'danceFiles',
        },
        filters: {
            nameHeb: {
                variant: 'text',
            },
            choreographers: {
                variant: 'autocomplete',
            },
            shapes: {
                variant: 'multi-select',
                selectOptions: shapes,
            },
            yearCreated: {
                variant: 'text',
            },
        },
        sortableKeys: [
            '_id',
            'codeShort',
            'nameHeb',
            'choreographersDisplayHeb',
            'shapes',
            'videoViews',
            'yearCreated',
        ],
    },
    danceCollections: {
        visibleKeys: [
            '_id',
            'created',
            'hidden',
            'nameHeb',
            'nameEng',
            'type',
            'dances',
        ],
        keyTypes: {
            _id: 'longText',
            created: 'date',
            hidden: 'boolean',
            nameHeb: 'text',
            nameEng: 'text',
            type: 'translateType',
            dances: 'total',
        },
        filters: {
            created: {
                variant: 'date',
            },
            hidden: {
                variant: 'checkbox',
            },
            nameHeb: {variant: 'text'},
            nameEng: {variant: 'text'},
            type: {
                variant: 'multi-select',
                selectOptions: danceCollectionTypes,
            },
        },
        sortableKeys: [
            '_id',
            'created',
            'hidden',
            'nameHeb',
            'nameEng',
            'type',
            'dances',
        ],
    },

    sessions: {
        visibleKeys: [
            '_id',
            'guides',
            'country',
            'city',
            'day',
            'description',
            'isWeekly',
            'isActive',
        ],
        keyTypes: {
            _id: 'longText',
            guides: 'namesArray',
            country: 'itemName',
            city: 'itemName',
            day: 'text',
            description: 'text',
            isWeekly: 'boolean',
            isActive: 'boolean',
        },
        filters: {
            guides: {
                variant: 'autocomplete',
            },
            country: {
                variant: 'autocomplete',
            },
            city: {
                variant: 'autocomplete',
            },
            day: {
                variant: 'multi-select',
                selectOptions: days,
            },

            description: {variant: 'text'},
            isWeekly: {variant: 'checkbox'},
            isActive: {variant: 'checkbox'},
        },
        sortableKeys: [
            '_id',
            'guides',
            'country',
            'city',
            'day',
            'description',
            'isWeekly',
            'isActive',
        ],
    },
    messages: {
        visibleKeys: [
            '_id',
            'created',
            'firstName',
            'lastName',
            'email',
            'message',
        ],
        keyTypes: {
            _id: 'longText',
            created: 'datetime',
            firstName: 'text',
            lastName: 'text',
            email: 'text',
            message: 'longText',
        },
        sortableKeys: ['created', 'firstName', 'lastName', 'email', 'message'],
    },
    payments: {
        visibleKeys: [
            '_id',
            'userName',
            'userEmail',
            'created',
            'amount',
            'currency',
            'packageId',
            'subscriptionId',
            'transactionId',
            'success',
            'dance',
        ],
        keyTypes: {
            _id: 'longText',
            userName: 'userName',
            userEmail: 'text',
            created: 'datetime',
            amount: 'text',
            currency: 'text',
            packageId: 'text',
            subscriptionId: 'text',
            transactionId: 'text',
            success: 'boolean',
            dance: 'itemName',
        },
        filters: {
            userEmail: {
                variant: 'text',
            },
            created: {
                variant: 'date',
            },
            amount: {
                variant: 'text',
            },
            currency: {
                variant: 'text',
            },
            packageId: {
                variant: 'text',
            },
            subscriptionId: {
                variant: 'text',
            },
            transactionId: {
                variant: 'text',
            },
            success: {
                variant: 'checkbox',
            },
            dance: {
                variant: 'autocomplete',
            },
        },
        sortableKeys: [
            '_id',
            'userEmail',
            'created',
            'amount',
            'currency',
            'packageId',
            'subscriptionId',
            'transactionId',
            'success',
            'dance',
        ],
    },
    publications: {
        visibleKeys: [
            '_id',
            'created',
            'date',
            'detailsEng',
            'detailsHeb',
            'guide',
        ],
        keyTypes: {
            _id: 'longText',
            created: 'datetime',
            date: 'date',
            detailsEng: 'text',
            detailsHeb: 'text',
            guide: 'name',
        },
        filters: {
            created: {
                variant: 'date',
            },
            date: {
                variant: 'date',
            },
            detailsEng: {variant: 'text'},
            detailsHeb: {variant: 'text'},
            guide: {variant: 'autocomplete'},
        },
        sortableKeys: ['created', 'date', 'detailsEng', 'detailsHeb', 'guide'],
    },
    banners: {
        visibleKeys: [
            '_id',
            'created',
            'name',
            'websiteUrl',
            'location',
            'imgUrl',
            'isActive',
            'isActiveEng',
        ],
        keyTypes: {
            _id: 'longText',
            created: 'datetime',
            name: 'text',
            websiteUrl: 'text',
            location: 'translatedNamesArray',
            imgUrl: 'bannerImage',
            isActive: 'isActive',
            isActiveEng: 'isActive',
        },
        filters: {
            created: {
                variant: 'date',
            },
            name: {variant: 'text'},
            websiteUrl: {variant: 'text'},
            location: {
                variant: 'multi-select',
                selectOptions: bannersLocations,
            },
            isActiveHeb: {variant: 'checkbox'},
            isActiveEng: {variant: 'checkbox'},
        },
        sortableKeys: [
            '_id',
            'created',
            'name',
            'websiteUrl',
            'location',
            'imgUrl',
            'isActiveHeb',
            'isActiveEng',
        ],
    },

    newspapers: {
        visibleKeys: [
            '_id',
            'issueNumber',
            'issueTitle',
            'downloadsCounter',
            'activated',
            'isVipOnly',
            'pdf',
            'thumbnail',
        ],
        keyTypes: {
            _id: 'longText',
            issueNumber: 'text',
            issueTitle: 'issueTitle',
            downloadsCounter: 'text',
            activated: 'isActive',
            isVipOnly: 'isActive',
            pdf: 'files',
            thumbnail: 'files',
        },
        sortableKeys: ['_id', 'issueNumber', 'issueTitle', 'downloadsCounter'],
    },
}

export default tables
