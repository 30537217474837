import baseApi, {provideTag, transformResponseFromData} from './baseApi'

const uploadsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        uploadImage: build.mutation({
            query: ({formData, folderName}) => {
                return {
                    url: `uploads/image/${folderName}`,
                    method: 'POST',
                    body: formData,
                }
            },
            transformResponse: transformResponseFromData,
            // invalidatesTags: () => provideTag('Upload'),
        }),
        getFilesForUpload: build.query({
            query: () => {
                return {
                    url: 'uploads/getFilesForUpload',
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Upload'),
        }),
        doUploadFilmedDate: build.mutation({
            query: ({filmedDate}) => {
                return {
                    url: `uploads/uploadFilmedDate`,
                    method: 'POST',
                    body: {filmedDate},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Upload'),
        }),
    }),

    overrideExisting: false,
})

export default uploadsApi

export const {
    useUploadImageMutation,
    useGetFilesForUploadQuery,
    useDoUploadFilmedDateMutation,
} = uploadsApi
