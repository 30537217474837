import {useFieldArray} from 'react-hook-form'

const useTimeTableForm = (control) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'timeTable',
    })

    const addItem = (values) => {
        const {timeStart, timeEnd} = values
        append({
            ...values,
            timeStart: timeStart?.format('HH:mm'),
            timeEnd: timeEnd?.format('HH:mm'),
        })
    }

    const removeItem = (index) => {
        remove(index)
    }

    return {fields, addItem, removeItem}
}

export default useTimeTableForm
