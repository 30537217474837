import {React, styled} from 'common'
import {Texts} from 'appearance'
import TableSearch from './TableTitleAndFilters/TableSearch'
import TableAddButton from './TableTitleAndFilters/TableAddButton'

const TableTitleWithSearch = ({
    title,
    itemName,
    useSearch,
    children,
    allowAdd,
}) => {
    if (!title) {
        return null
    }
    return (
        <Container>
            <Title>{title}</Title>
            {children}
            <TableSearch useSearch={useSearch} itemName={itemName} />
            <TableAddButton itemName={itemName} show={allowAdd} />
        </Container>
    )
}

export default TableTitleWithSearch

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
`

const Title = styled(Texts.Title)`
    flex: 1;
`
