import FormCalendarInput from 'components/Control/Form/FormCalendarInput'
import FormTextInput from 'components/Control/Form/FormTextInput'
import tables from 'constants/tables'

const formComponents = {
    text: FormTextInput,
    date: FormCalendarInput,
    datetime: FormCalendarInput,
    boolean: FormTextInput,
}

const FormComponent = ({itemKey, itemNames, ...props}) => {
    const {keyTypes} = tables[itemNames]
    const Comp = formComponents[keyTypes[itemKey]] || FormTextInput

    return <Comp {...props} />
}

export default FormComponent
