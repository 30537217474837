import DataTable from 'components/Display/DataTable/DataTable'
import {Page, PageContent} from 'components/Page'
import {
    useDeleteNewspaperMutation,
    useGetNewspapersQuery,
} from 'store/slices/api/newspapersApi'

const Newspapers = () => {
    return (
        <Page>
            <PageContent>
                <DataTable
                    allowEdit
                    allowDelete
                    itemName="newspaper"
                    paginationType="external"
                    useGetQuery={useGetNewspapersQuery}
                    useDeleteMutation={useDeleteNewspaperMutation}
                />
            </PageContent>
        </Page>
    )
}

export default Newspapers
