import {isNew} from 'services/utils/editForm'
import {useForm} from 'react-hook-form'
import {
    useCreateUserMutation,
    useGetUserQuery,
    useI18n,
    useSaveUserMutation,
} from 'store/Store'
import {useEffect, useState} from 'react'
import {useGetGuideQuery, useGetGuidesQuery} from 'store/slices/api/guidesApi'
import {useGetCountriesQuery} from 'store/slices/api/countriesApi'
import {Video} from '@styled-icons/fluentui-system-regular/Video'
import {MusicNote1} from '@styled-icons/fluentui-system-regular/MusicNote1'
import {ArrowDownload} from '@styled-icons/fluentui-system-filled/ArrowDownload'
import {CreditCard} from '@styled-icons/bootstrap/CreditCard'
import {useAlert} from 'components/Display/Alert/AlertContext'
import {useGetUserPurchasesQuery} from 'store/slices/api/purchasesApi'
import {useNavigate} from 'react-router-dom'
import {getLocale} from 'localization/i18n'

const useUserForm = ({userId}) => {
    const {i18n} = useI18n()
    const {showAlert} = useAlert()
    const navigate = useNavigate()
    const locale = getLocale()

    const [formError, setFormError] = useState(null)
    const [searchCountryValue, setCountrySearchValue] = useState()
    const [searchGuideValue, setSearchGuideValue] = useState()

    const [doSaveUser] = useSaveUserMutation()
    const [doCreateUser] = useCreateUserMutation()
    const {
        data: user,
        isFetching,
        isSuccess,
    } = useGetUserQuery(userId, {skip: isNew(userId)})
    const {data: guides, isFetching: isFetchingGuides} = useGetGuidesQuery(
        {search: searchGuideValue},
        {skip: !searchGuideValue}
    )
    const {data: guideObj} = useGetGuideQuery(user?.guide, {skip: !user?.guide})
    const {data: countries, isFetching: isFetchingCountries} =
        useGetCountriesQuery(
            {search: searchCountryValue},
            {skip: !searchCountryValue}
        )

    const {data: purchasedDances} = useGetUserPurchasesQuery(userId, {
        skip: isNew(userId),
    })

    const form = useForm({initialValues: user})
    const {handleSubmit, reset} = form

    const onSubmit = async (values) => {
        const guide = values.guide ? values.guide._id : null
        const body = {
            ...values,
            guide,
            language: locale,
        }
        let response
        if (isNew(userId)) {
            response = await doCreateUser(body)
        } else {
            response = await doSaveUser(body)
        }

        if (response?.data?._id) {
            const message = i18n.t('form.savedSuccessfully', {
                itemName: i18n.t('tables.user'),
            })
            navigate(`/users/${response.data._id}`)
            showAlert(message)
        }

        if (response.error) {
            setFormError(response.error.data.error)
        }
    }

    const submit = handleSubmit(onSubmit)

    useEffect(() => {
        if (isSuccess && !isFetching && user) {
            reset({...user, guide: guideObj ?? user.guide})
        }
    }, [user, isSuccess, isFetching, reset, guideObj])

    const stats = [
        {key: 'videoViews', value: user?.videoViews, icon: Video},
        {key: 'audioViews', value: user?.audioViews, icon: MusicNote1},
        {key: 'downloads', value: user?.usedDownloads, icon: ArrowDownload},
        {
            key: 'purchases',
            value: user?.purchasedDances.length,
            icon: CreditCard,
        },
    ]

    return {
        submit,
        form,
        user,
        guides,
        isFetchingGuides,
        countries,
        isFetchingCountries,
        formError,
        stats,
        searchCountryValue,
        setCountrySearchValue,
        searchGuideValue,
        setSearchGuideValue,
        purchasedDances: purchasedDances?.data,
    }
}

export default useUserForm
