import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const guidesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getPayments: build.query({
            query: (query) => {
                const url = constructUrl('payments', {
                    ...query,
                    sort: query.sort ?? JSON.stringify({created: 'desc'}),
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Payment'),
        }),
        deletePayment: build.mutation({
            query: (id) => {
                return {
                    url: `payments/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Payment'),
        }),
    }),
    overrideExisting: false,
})

export default guidesApi

export const {useGetPaymentsQuery, useDeletePaymentMutation} = guidesApi
