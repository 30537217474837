import {React} from 'common'
import {Page} from 'components/Page'
import {validationPatterns} from 'constants/auth'
import {
    FormActionButton,
    LoginFormContainer,
    FormErrorMessage,
} from 'components/Control/Form/index_'
import {CircularProgress} from '@mui/material'
import {styled} from '@mui/material/styles'
import FormTextInput from 'components/Control/Form/FormTextInput'
import useAuth from 'logic/Auth/useAuth'
import {Images} from 'appearance'
import {useI18n} from 'store/Store'
import {FormContainer} from 'components/Page/Form/Forms'

const Login = () => {
    const {i18n} = useI18n()
    const {formControl, login, isLoading, error, formErrors} = useAuth()

    return (
        <Page noHeader noDrawer>
            <LoginFormContainer style={{width: 400}}>
                <Logo src={Images.LOGO} alt="logo" />
                <FormContainer>
                    <FormTextInput
                        name="email"
                        label={i18n.t(`login.email`)}
                        control={formControl}
                        rules={{
                            required: true,
                            pattern: validationPatterns.EMAIL,
                        }}
                        fullWidth
                        type="email"
                        initialValue=""
                        errors={formErrors}
                    />
                    <FormTextInput
                        name="password"
                        label={i18n.t(`login.password`)}
                        control={formControl}
                        rules={{
                            required: true,
                        }}
                        fullWidth
                        type="password"
                        initialValue=""
                        errors={formErrors}
                    />
                </FormContainer>
            </LoginFormContainer>

            <StyledFormActionButton
                onClick={login}
                disabled={isLoading}
                variant="contained">
                {isLoading && <LoadingCircularProgress size={16} />}
                {i18n.t(`login.signIn`)}
            </StyledFormActionButton>
            <FormErrorMessage errorMessage={error?.data?.message} />
        </Page>
    )
}

export default Login

const Logo = styled('img')``

const LoadingCircularProgress = styled(CircularProgress)`
    margin-inline-end: 10px;
`

const StyledFormActionButton = styled(FormActionButton)`
    width: 400px;
`
