import {Card, CardContent, styled} from '@mui/material'

const PageContent = ({children}) => {
    return (
        <StyledCard variant="outlined">
            <StyledCardContent>{children}</StyledCardContent>
        </StyledCard>
    )
}

export default PageContent

const StyledCard = styled(Card)`
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    & > .MuiCardContent-root {
        height: 100%;
    }
`

const StyledCardContent = styled(CardContent)`
    overflow-y: auto;
`
