import {React} from 'common'
import {Page, PageContent} from 'components/Page'
import DownloadsTabs from 'components/Page/Dashboard/DownloadTabs/Tabs'
import {useGetCurrentUserQuery} from 'store/Store'

const Dashboard = () => {
    const {data, error} = useGetCurrentUserQuery()
    console.log('dashboard refresh - route2', {data, error})

    return (
        <Page>
            <PageContent>
                <DownloadsTabs />
            </PageContent>
        </Page>
    )
}

export default Dashboard
