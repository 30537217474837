import {useForm} from 'react-hook-form'
import {
    useDoUploadFilmedDateMutation,
    useGetFilesForUploadQuery,
} from 'store/slices/api/uploadsApi'
import {useI18n} from 'store/Store'

const useUploadFilmedDatesForm = () => {
    const {i18n} = useI18n()
    const {data: uploadData, isFetching} = useGetFilesForUploadQuery()

    const [doUploadFilmedDate, {isSuccess, isLoading}] =
        useDoUploadFilmedDateMutation()

    const {uploadStatus, batchFiles, youtubeUploadStatus} = uploadData || {}

    const statusText =
        typeof uploadStatus === 'string'
            ? uploadStatus
            : uploadStatus?.items?.[0]?.value

    const status = statusText
        ? i18n.t(`upload.processStatuses.${statusText}`)
        : '???'

    const {
        control,
        formState: {errors},
        handleSubmit,
        reset,
    } = useForm({defaultValues: {filmedDate: ''}})

    const onSubmit = async (values) => {
        const body = {...values}
        if (!values.filmedDate?.length) {
            delete body.filmedDate
        }
        await doUploadFilmedDate(values)
        if (isSuccess) {
            reset()
        }
    }

    const submit = handleSubmit(onSubmit)

    return {
        control,
        errors,
        filesForUpload: batchFiles,
        status,
        isFetching,
        isValid: !!batchFiles?.length,
        submit,
        youtubeUploadStatus,
        isUpload: isLoading,
    }
}

export default useUploadFilmedDatesForm
