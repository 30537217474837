import {Controller} from 'react-hook-form'
import ChipSelect from '../ChipSelect'
import {InputContainer} from 'components/Page/Form/Forms'

const FormChipSelect = ({
    hidden,
    name,
    label,
    error,
    control,
    options,
    getOptionLabel,
    itemsName,
    optionsName,
    single,
}) => {
    return (
        !hidden && (
            <InputContainer>
                <Controller
                    name={name}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <ChipSelect
                            name={name}
                            label={label}
                            error={error}
                            value={value || []}
                            itemsName={itemsName}
                            optionsName={optionsName}
                            onChange={(e) => {
                                if (!single) {
                                    return onChange(e)
                                }
                                onChange({
                                    ...e,
                                    target: e.target
                                        ? {value: e.target.value.slice(-1)}
                                        : {value: []},
                                })
                            }}
                            options={options}
                            getOptionLabel={getOptionLabel}
                        />
                    )}
                />
            </InputContainer>
        )
    )
}

export default FormChipSelect
