import DataTable from 'components/Display/DataTable/DataTable'
import Modal from 'components/Display/Modal/Modal'
import {Page, PageContent} from 'components/Page'
import ItemForm from 'components/Page/MoreData/ItemForm'
import useMoreDataForm from 'logic/Forms/useMoreDataForm'
import {FormProvider} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {useGetCitiesQuery} from 'store/slices/api/citiesApi'
import {
    useDeleteCountryMutation,
    useGetCountriesQuery,
} from 'store/slices/api/countriesApi'

const MoreData = () => {
    const {i18n} = useI18n()
    const itemsKey = 'moreData'

    const {
        form,
        submit,
        openModal,
        handleOpenModal,
        handleCloseModal,
        handleEditItem,
        modalTitle,
    } = useMoreDataForm()

    return (
        <Page>
            <PageContent>
                <DataTable
                    translationKey={itemsKey}
                    allowEdit
                    allowDelete
                    onEdit={(id) => handleEditItem(id, 'countrie')}
                    onCreate={() => handleOpenModal('countrie')}
                    itemName="countrie"
                    paginationType="external"
                    useGetQuery={useGetCountriesQuery}
                    useDeleteMutation={useDeleteCountryMutation}
                />

                <DataTable
                    translationKey={itemsKey}
                    allowEdit
                    allowDelete
                    onEdit={(id) => handleEditItem(id, 'citie')}
                    onCreate={() => handleOpenModal('citie')}
                    itemName="citie"
                    paginationType="external"
                    useGetQuery={useGetCitiesQuery}
                    useDeleteMutation={useDeleteCountryMutation}
                />

                <Modal
                    open={!!openModal}
                    title={modalTitle}
                    onClose={handleCloseModal}
                    buttons={[
                        {
                            label: i18n.t('form.cancel'),
                            onClick: handleCloseModal,
                        },
                        {
                            label: i18n.t('form.save'),
                            onClick: () => submit,
                        },
                    ]}>
                    <FormProvider {...form}>
                        <ItemForm itemName={openModal} />
                    </FormProvider>
                </Modal>
            </PageContent>
        </Page>
    )
}

export default MoreData
