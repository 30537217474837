import {styled} from '@mui/material'
import {Texts} from 'appearance'

const TextCell = ({children}) => {
    return <CellContainer>{children}</CellContainer>
}

export default TextCell

const CellContainer = styled(Texts.Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
`
