import {isRejectedWithValue} from '@reduxjs/toolkit'
import {protectedPaths} from 'navigation/NavigationRouter'
import {matchPath} from 'react-router-dom'

const rtkQueryErrorHandler = (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        const isProtected = protectedPaths.some((path) => {
            if (!path || typeof path !== 'string') {
                return false
            }
            return path !== '/' && matchPath(path, window.location.pathname)
        })
        console.log('We got a rejected action!', {
            action,
            api,
            isProtected,
        })
        if (action.payload.status === 401 && isProtected) {
            window.location.href = '/login'
        }
    }

    return next(action)
}

export default rtkQueryErrorHandler
