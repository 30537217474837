import {FormColumn, FormContainer, FormRow} from '../Form/Forms'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {useI18n} from 'store/Store'
import {useFormContext} from 'react-hook-form'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import FormSelect from 'components/Control/Form/FormSelect'
import {yesNoOptions} from 'constants/users'
import FormAutocomplete from 'components/Control/Form/FormAutocomplete'
import {formatUser} from 'services/utils/data'
import RichTextEditor from 'components/Control/Form/RichTextEditor'
import FormUpload from 'components/Control/Form/FormUpload'
import {urls} from 'services/utils/urls'

const PublicationForm = ({
    isNew,
    guidesResults,
    searchGuideValue,
    setSearchGuideValue,
    uploadFlyer,
}) => {
    const {i18n} = useI18n()

    const formContext = useFormContext()
    const {
        control,
        formState: {errors},
    } = formContext

    const flierUrl = formContext.watch('flierUrl')
    const url = flierUrl
        ? `${urls.base}/${urls.publicationFlyer}/${flierUrl}`
        : ''

    return (
        <FormContainer>
            <FormRow>
                <FormColumn>
                    <FormTextInput
                        readOnly
                        hidden={isNew}
                        name="_id"
                        label={i18n.t(`publications._id`)}
                        control={control}
                    />

                    <FormDatePicker
                        name="expirationDate"
                        label={i18n.t(`publications.expirationDate`)}
                        control={control}
                        error={errors.expirationDate}
                    />

                    <FormSelect
                        itemsName="users"
                        optionsName="yesNo"
                        name="isActive"
                        label={i18n.t(`publications.isActive`)}
                        options={yesNoOptions}
                        control={control}
                        error={errors.isActive}
                    />

                    <FormTextInput
                        name="detailsHeb"
                        label={i18n.t(`publications.detailsHeb`)}
                        control={control}
                        error={errors.detailsHeb}
                    />
                </FormColumn>
                <FormColumn>
                    <FormTextInput
                        readOnly
                        hidden={isNew}
                        name="created"
                        label={i18n.t(`publications.created`)}
                        control={control}
                    />

                    <FormDatePicker
                        name="date"
                        label={i18n.t(`publications.date`)}
                        control={control}
                        error={errors.date}
                    />

                    <FormAutocomplete
                        name="guide"
                        label={i18n.t(`publications.guide`)}
                        control={control}
                        error={errors.guides}
                        options={guidesResults?.data}
                        getOptionLabel={formatUser}
                        inputValue={searchGuideValue}
                        onChangeInputValue={setSearchGuideValue}
                    />

                    <FormTextInput
                        name="detailsEng"
                        label={i18n.t(`publications.detailsEng`)}
                        control={control}
                        error={errors.detailsEng}
                    />
                </FormColumn>
            </FormRow>

            <FormRow>
                <RichTextEditor
                    name="moreDetailsHeb"
                    label={i18n.t(`publications.moreDetailsHeb`)}
                    control={control}
                    error={errors.moreDetailsHeb}
                />
            </FormRow>

            <FormRow>
                <RichTextEditor
                    name="moreDetailsEng"
                    label={i18n.t(`publications.moreDetailsEng`)}
                    control={control}
                    error={errors.moreDetailsEng}
                />
            </FormRow>

            <FormRow>
                <FormTextInput
                    name="link"
                    label={i18n.t(`publications.link`)}
                    control={control}
                    error={errors.link?.message}
                />

                <FormTextInput
                    name="linkTitleEng"
                    label={i18n.t(`publications.linkTitleEng`)}
                    control={control}
                    error={errors.linkTitleEng}
                />

                <FormTextInput
                    name="linkTitleHeb"
                    label={i18n.t(`publications.linkTitleHeb`)}
                    control={control}
                    error={errors.linkTitleHeb}
                />
            </FormRow>

            <FormUpload
                label={i18n.t('publications.flyer')}
                name="flierFile"
                control={control}
                onUpload={uploadFlyer}
                url={url}
            />
        </FormContainer>
    )
}

export default PublicationForm
