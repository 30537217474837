import {InputLabel, styled} from '@mui/material'
import {useI18n} from 'store/Store'
import {useState} from 'react'
import {languages} from 'constants/newspapers'
import Select from 'components/Control/Select'
import FormUpload from 'components/Control/Form/FormUpload'
import {useFormContext} from 'react-hook-form'
import {getLocale} from 'localization/i18n'

const UploadFiles = ({uploadImage, imagesUrls}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const locale = getLocale()

    const [language, setLanguage] = useState(locale)
    const thumbnailInputName = `thumbnailUrl${
        language === 'he' ? 'Hebrew' : ''
    }`
    const pdfInputName = `pdfUrl${language === 'he' ? 'Hebrew' : ''}`

    return (
        <Container>
            <InputWrapper>
                <Label>{i18n.t('newspapers.uploadIssue')}</Label>
                <Select
                    value={language}
                    onChange={(event) => setLanguage(event.target.value)}
                    itemsName="newspapers"
                    optionsName="languages"
                    name="language"
                    label={i18n.t(`newspapers.language`)}
                    options={languages}
                    sx={{height: 32, fontSize: 14, width: '50%'}}
                />
                <FormUpload
                    name={pdfInputName}
                    control={formContext.control}
                    onUpload={() => uploadImage(pdfInputName)}
                    url={imagesUrls[pdfInputName]}
                    showImage={false}
                />
            </InputWrapper>

            <InputWrapper>
                <Label>{i18n.t('newspapers.uploadThumbnail')}</Label>
                <Select
                    value={language}
                    onChange={(event) => setLanguage(event.target.value)}
                    itemsName="newspapers"
                    optionsName="languages"
                    name="language"
                    label={i18n.t(`newspapers.language`)}
                    options={languages}
                    sx={{height: 32, fontSize: 14, width: '50%'}}
                />
                <FormUpload
                    name={thumbnailInputName}
                    control={formContext.control}
                    onUpload={() => uploadImage(thumbnailInputName)}
                    url={imagesUrls[thumbnailInputName]}
                    showImage={false}
                />
            </InputWrapper>
        </Container>
    )
}

export default UploadFiles

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-block: 12px;
    width: 50%;
`

const Label = styled(InputLabel)`
    font-size: 12px;
`

const InputWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 8px;
`
