import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const citiesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCities: build.query({
            query: (query) => {
                const url = constructUrl('cities', {
                    ...query,
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('City'),
        }),
        getCity: build.query({
            query: (id) => ({
                url: `cities/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('City', result),
        }),
        createCity: build.mutation({
            query: (body) => {
                return {
                    url: `cities`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('City'),
        }),
        saveCity: build.mutation({
            query: (body) => {
                return {
                    url: `cities/${body._id}`,
                    method: 'PUT',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('City', result),
        }),
        deleteCity: build.mutation({
            query: (id) => {
                return {
                    url: `cities/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('City'),
        }),
    }),
    overrideExisting: false,
})

export default citiesApi

export const {
    useGetCitiesQuery,
    useGetCityQuery,
    useCreateCityMutation,
    useSaveCityMutation,
    useDeleteCityMutation,
} = citiesApi
