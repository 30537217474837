import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localeData from 'dayjs/plugin/localeData'
import updateLocale from 'dayjs/plugin/updateLocale'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'dayjs/locale/he'
import 'dayjs/locale/en'

dayjs.extend(utc)
dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.extend(localizedFormat)

export default dayjs
