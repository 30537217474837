import {Button, IconButton, InputLabel, styled} from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import {UploadCloud2} from '@styled-icons/remix-line/UploadCloud2'
import {useI18n} from 'store/Store'
import {Texts} from 'appearance'
import {useState} from 'react'
import PreviewImage from './PreviewImage'

const UploadComponent = ({
    label,
    value,
    onChange,
    accept,
    onUpload,
    url,
    showImage = true,
}) => {
    const {i18n} = useI18n()

    const [fileName, setFileName] = useState()

    const handleChange = (event) => {
        setFileName(event.target.files[0].name)
        onChange(event.target.files[0])
    }

    return (
        <Container>
            {label && <Label>{label}</Label>}
            <Wrapper>
                <SelectFileButton
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}>
                    {i18n.t('form.selectFile')}
                    <VisuallyHiddenInput
                        type="file"
                        accept={accept || 'image/*'}
                        onChange={handleChange}
                    />
                </SelectFileButton>

                <FileName>{fileName ?? i18n.t('form.noFile')}</FileName>

                {onUpload && (
                    <IconButton
                        disabled={!value}
                        color="primary"
                        size="small"
                        onClick={onUpload}>
                        <UploadCloud2 size={24} />
                    </IconButton>
                )}
            </Wrapper>
            <PreviewImage
                src={url}
                alt={label}
                showImage={showImage && !!url?.length}
            />
        </Container>
    )
}

export default UploadComponent

const Container = styled('div')``

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
})

const Label = styled(InputLabel)`
    margin-bottom: 10px;
    font-size: 12px;
`

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
    gap: 16px;
`
const SelectFileButton = styled(Button)`
    font-size: 12px;
`

const FileName = styled(Texts.Text)`
    font-weight: 300;
    font-size: 14px;
`
