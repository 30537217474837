import {styled, useTheme} from '@mui/material'
import {Texts} from 'appearance'

const StatsItem = ({Icon, label, count}) => {
    const theme = useTheme()

    return (
        <Container>
            <Icon color={theme.palette.primary.main} size={24} />
            <Label>{`${count} ${label}`}</Label>
        </Container>
    )
}

export default StatsItem

const Container = styled('div')`
    display: flex;
    align-items: center;
    gap: 12px;
`

const Label = styled(Texts.Text)`
    font-size: 14px;
    font-weight: 600;
`
