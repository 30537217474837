import {styled} from '@mui/material'

import {useI18n} from 'store/Store'
import StatsItem from './StatsItem'

const Stats = ({itemsName, stats}) => {
    const {i18n} = useI18n()

    return (
        <Container>
            {stats.map((item) => (
                <StatsItem
                    Icon={item.icon}
                    label={i18n.t(`${itemsName}.${item.key}`)}
                    count={item.value || 0}
                />
            ))}
        </Container>
    )
}

export default Stats

const Container = styled('div')`
    display: flex;
    padding: 24px 12px;
    gap: 48px;
`
