const colors = {
    APP_WHITE: '#fefefe',
    APP_BLACK: '#000',
    APP_GRAY: '#ccc',
    APP_GRAY_LIGHT: '#eee',
    APP_GRAY_DARK: '#aaa',

    PRIMARY_COLOR: 'blue',
    PRIMARY_TEXT: '#45484b',
    ERROR_TEXT: 'red',
}

export default colors
