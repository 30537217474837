import {React} from 'common'
import {Styles, Texts} from 'appearance'
import Toolbar from '@mui/material/Toolbar'
import {styled} from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiAppBar from '@mui/material/AppBar'
import LanguageMenu from 'components/Page/Header/LanguageMenu'
import MobileMenu from 'components/Page/Header/MobileMenu'
import MainMenuButton from './Header/MainMenuButton'
import LanguageMenuButton from './Header/LanguageMenuButton'
import HeaderActions from './Header/HeaderActions'
import MobileMenuButton from './Header/MobileMenuButton'
import MainMenu from './Header/MainMenu'
import {useI18n} from 'store/Store'
import useHeaderMenu from 'logic/Menu/useHeaderMenu'
import {Link} from '@mui/material'

const Header = ({toggleDrawer, open}) => {
    const {i18n} = useI18n()

    const {
        anchors,
        handleSetLanguage,
        isMenuOpen,
        handleOpenMenu,
        handleCloseMenu,
        loggedInEmail,
    } = useHeaderMenu()

    const menuId = 'primary-search-account-menu'
    const languageMenuId = 'primary-search-account-menu-language'
    const mobileMenuId = 'primary-search-account-menu-mobile'

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <MainMenuButton toggleDrawer={toggleDrawer} />
                    <Link href="/" underline="none">
                        <Texts.Title>{i18n.t('header.title')}</Texts.Title>
                    </Link>
                    <Box sx={{flexGrow: 1}} />
                    <LanguageMenuButton
                        menuId={languageMenuId}
                        handleOpenMenu={handleOpenMenu}
                    />

                    <HeaderActions
                        menuId={menuId}
                        handleOpenMenu={handleOpenMenu}
                    />
                    <MobileMenuButton
                        mobileMenuId={mobileMenuId}
                        handleOpenMenu={handleOpenMenu}
                    />
                </Toolbar>
            </AppBar>
            <MainMenu
                menuId={menuId}
                anchorEl={anchors.profile}
                isMenuOpen={isMenuOpen}
                handleCloseMenu={handleCloseMenu}
                setMobileMoreAnchorEl={handleOpenMenu}
                loggedInEmail={loggedInEmail}
            />
            <MobileMenu
                mobileMenuId={mobileMenuId}
                handleCloseMenu={handleCloseMenu}
                mobileMoreAnchorEl={anchors.mobile}
                isMenuOpen={isMenuOpen}
            />
            <LanguageMenu
                isOpen={isMenuOpen('language')}
                languageAnchorEl={anchors.language}
                handleCloseMenu={handleCloseMenu}
                handleSetLanguage={handleSetLanguage}
            />
        </Box>
    )
}

export default Header

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: Styles.DRAWER_WIDTH,
        width: `calc(100% - ${Styles.DRAWER_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))
