import {styled} from 'common'
import TableCell from '@mui/material/TableCell'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import useTableActions from 'logic/Table/useTableActions'

const TableActions = ({
    itemId,
    itemName,
    tableName,
    tableRowData,
    allowEdit,
    allowDelete,
    onDelete,
    onExpand,
    isExpanded,
}) => {
    const {isVisible, ExpandIcon, handleDelete, handleEdit} = useTableActions(
        itemName,
        allowEdit,
        onDelete,
        onExpand,
        isExpanded,
        itemId,
        tableRowData,
        tableName
    )

    if (!isVisible()) {
        return null
    }

    return (
        <TableCell component="th" scope="row">
            <Actions>
                {allowEdit && <EditIcon onClick={handleEdit} />}
                {allowDelete && <DeleteIcon onClick={handleDelete} />}
                {onExpand && (
                    <ExpandIcon
                        onClick={() => onExpand(isExpanded ? null : itemId)}
                    />
                )}
            </Actions>
        </TableCell>
    )
}

export default TableActions

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    width: 100px;
    justify-content: space-evenly;
`
