import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const usersApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query({
            query: (query) => {
                const url = constructUrl('users', {
                    ...query,
                    sort: query.sort ?? JSON.stringify({created: 'desc'}),
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('User'),
        }),
        getUser: build.query({
            query: (id) => ({
                url: `users/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('User', result),
        }),
        createUser: build.mutation({
            query: (body) => {
                return {
                    url: `users`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('User'),
        }),
        saveUser: build.mutation({
            query: (body) => {
                return {
                    url: `users/${body._id}`,
                    method: 'PUT',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('User', result),
        }),
        deleteUser: build.mutation({
            query: (id) => {
                return {
                    url: `users/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('User'),
        }),
    }),
    overrideExisting: false,
})

export default usersApi

export const {
    useGetUserQuery,
    useGetUsersQuery,
    useCreateUserMutation,
    useSaveUserMutation,
    useDeleteUserMutation,
} = usersApi
