import {styled} from '@mui/material'
import DanceListItem from './DanceListItem'
import {useFormContext} from 'react-hook-form'

const DancesList = ({handleRemoveDance, handleMoveDance}) => {
    const formContext = useFormContext()
    const dancesValues = formContext.watch('dances')

    return (
        <Container>
            {dancesValues?.map((dance, index) => (
                <DanceListItem
                    key={dance._id}
                    dance={dance}
                    index={index}
                    handleRemoveDance={handleRemoveDance}
                    handleMoveDance={handleMoveDance}
                />
            ))}
        </Container>
    )
}

export default DancesList

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    padding-block: 24px;
`
