import {yupResolver} from '@hookform/resolvers/yup'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {isNew} from 'services/utils/editForm'
import {danceMediaSchema} from 'services/validations/schemas/danceMedia'
import {
    useGetDanceQuery,
    useGetDancesQuery,
    useUploadDanceMediaMutation,
} from 'store/slices/api/dancesApi'

const useDanceMedia = () => {
    const form = useForm({mode: 'all', resolver: yupResolver(danceMediaSchema)})

    const [danceId, setDanceId] = useState()
    const [searchDanceValue, setSearchDanceValue] = useState()

    const [doUploadMedia, {isLoading}] = useUploadDanceMediaMutation()
    const {data: dances} = useGetDancesQuery(
        {
            search: searchDanceValue,
            pageSize: 50,
        },
        {skip: !searchDanceValue}
    )
    const {data: dance} = useGetDanceQuery(danceId, {skip: isNew(danceId)})

    const toggleDialog = (id) => {
        form.reset()
        setDanceId(id)
    }

    const handleSelectDance = (dance) => {
        form.setValue('dance', dance)
    }

    const onSubmit = async (values) => {
        const formData = new FormData()
        formData.append('files', values.file)
        formData.append('fileType', values.fileType)
        formData.append('danceId', values.dance._id)

        const response = await doUploadMedia(formData)
        if (response?.data?.url) {
            toggleDialog()
        }
    }

    const submit = form.handleSubmit(onSubmit)

    useEffect(() => {
        if (dance && !isNew(danceId)) {
            form.setValue('dance', dance)
        }
    }, [danceId, form, dance])

    return {
        toggleDialog,
        form,
        dialogOpen: !!danceId,
        dances,
        submit,
        handleSelectDance,
        searchDanceValue,
        setSearchDanceValue,
        isLoading,
        isValidForm: form.formState.isDirty && form.formState.isValid,
    }
}

export default useDanceMedia
