import {Autocomplete as RawAutocomplete, TextField} from '@mui/material'
import {debounce} from 'lodash'
import {useMemo, useState} from 'react'
import {useI18n} from 'store/Store'

const Autocomplete = ({
    name,
    label,
    required,
    error,
    placeholder,
    isLoading,
    options,
    getOptionLabel,
    value,
    onChange,
    onInputChange,
    variant,
}) => {
    const {i18n} = useI18n()

    const [inputValue, setInputValue] = useState('')

    const debouncedSearch = useMemo(
        () =>
            debounce((searchValue) => {
                onInputChange(null, searchValue)
            }, 500),
        [onInputChange]
    )

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue)
        debouncedSearch(newInputValue)
    }

    return (
        <RawAutocomplete
            fullWidth
            autoHighlight
            disablePortal
            key={name}
            required={required}
            loading={isLoading}
            name={name}
            options={options || []}
            clearOnBlur={false}
            getOptionLabel={getOptionLabel}
            filterOptions={(x) => x}
            onChange={onChange}
            inputValue={inputValue || ''}
            defaultValue={inputValue || ''}
            onInputChange={handleInputChange}
            value={value}
            clearIcon={null}
            noOptionsText={i18n.t('form.autocompleteNoOptionsText')}
            renderInput={(params) => {
                //to fix a styling bug in MUI lib (className prop)
                const {
                    InputProps: {ref, endAdornment},
                } = params

                return (
                    <TextField
                        {...params}
                        variant={variant}
                        label={label}
                        placeholder={placeholder}
                        error={error}
                        helperText={error && i18n.t(`formErrors.${error}`)}
                        inputProps={params.inputProps}
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            ref,
                            endAdornment,
                        }}
                    />
                )
            }}
        />
    )
}

export default Autocomplete
