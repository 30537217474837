import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {FormRow, ModalFormContainer} from '../Form/Forms'
import FormTextInput from 'components/Control/Form/FormTextInput'
import Checkbox from 'components/Control/Checkbox'
import {styled} from '@mui/material'

const MessageForm = ({textAlign, changeTextDirection}) => {
    const {i18n} = useI18n()

    const formContext = useFormContext()
    const {
        control,
        formState: {errors},
    } = formContext

    return (
        <ModalFormContainer>
            <FormRow>
                <FormTextInput
                    fullWidth
                    readOnly
                    name="firstName"
                    label={i18n.t(`messages.firstName`)}
                    control={control}
                    error={errors.firstName}
                />
                <FormTextInput
                    fullWidth
                    readOnly
                    name="lastName"
                    label={i18n.t(`messages.lastName`)}
                    control={control}
                    error={errors.lastName}
                />
            </FormRow>
            <FormRow>
                <FormTextInput
                    fullWidth
                    readOnly
                    name="email"
                    label={i18n.t(`messages.email`)}
                    control={control}
                    error={errors.email}
                />
                <FormTextInput
                    fullWidth
                    readOnly
                    name="mobilePhone"
                    label={i18n.t(`messages.mobilePhone`)}
                    control={control}
                    error={errors.mobilePhone}
                />
            </FormRow>

            <FormRow>
                <FormTextInput
                    fullWidth
                    readOnly
                    name="address"
                    label={i18n.t(`messages.address`)}
                    control={control}
                    error={errors.address}
                />
                <FormTextInput
                    fullWidth
                    readOnly
                    name="city"
                    label={i18n.t(`messages.city`)}
                    control={control}
                    error={errors.city}
                />
            </FormRow>

            <MessageContainer>
                <Checkbox
                    name="rightToLeft"
                    label={i18n.t('messages.rightToLeft')}
                    value={textAlign === 'right'}
                    onChange={changeTextDirection}
                />

                <FormTextInput
                    fullWidth
                    readOnly
                    name="message"
                    label={i18n.t(`messages.message`)}
                    control={control}
                    error={errors.message}
                    inputStyle={{textAlign}}
                />
            </MessageContainer>
        </ModalFormContainer>
    )
}

export default MessageForm

const MessageContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 4px;
`
