import {React} from 'common'
import {useNavigate} from 'react-router-dom'
import {Page, PageContent} from 'components/Page'
import {Controller} from 'react-hook-form'
import Button from 'components/Control/Button'
import {
    FormActionButton,
    LoginFormContainer,
    FormTitle,
    FormInput,
    FormRow,
    FormAdditionalButton,
    FormAdditionalText,
    FormErrorMessage,
} from 'components/Control/Form/index_'
import FormCheckbox from 'components/Control/Form/FormChecbox'
import useAuth from 'logic/Auth/useAuth'

const ResetPassword = ({match}) => {
    const navigate = useNavigate()

    const {params} = match || {}
    const {token} = params || {}

    const {formControl, resetPassword, isLoading, response, formErrors, error} =
        useAuth(token)

    if (response?.message) {
        return (
            <Page noHeader noDrawer>
                <PageContent>{response.message}</PageContent>
                <Button onClick={() => navigate('/login')} variant="contained">
                    Continue
                </Button>
            </Page>
        )
    }

    return (
        <Page noHeader noDrawer>
            <PageContent centered>
                <LoginFormContainer>
                    <FormTitle>Enter New Password</FormTitle>
                    <Controller
                        name={'password'}
                        control={formControl}
                        rules={{
                            required: true,
                        }}
                        render={({field: {onChange, value}}) => (
                            <FormInput
                                type="password"
                                onChange={onChange}
                                value={value}
                                fullWidth
                                label="Password"
                            />
                        )}
                    />
                    <FormErrorMessage
                        errors={formErrors}
                        name={'password'}
                        errorMessage={'password is required.'}
                    />
                    <Controller
                        name={'confirmPassword'}
                        control={formControl}
                        rules={{
                            required: true,
                        }}
                        render={({field: {onChange, value}}) => (
                            <FormInput
                                type="password"
                                onChange={onChange}
                                value={value}
                                fullWidth
                                label="Confirm Password"
                            />
                        )}
                    />
                    <FormErrorMessage
                        errors={formErrors}
                        name={'password'}
                        errorMessage={'confirm password is required.'}
                    />
                    <FormCheckbox
                        name={'rememberMe'}
                        control={formControl}
                        label="Remember Me"
                    />
                </LoginFormContainer>

                <FormActionButton onClick={resetPassword} variant="contained">
                    Reset Password
                </FormActionButton>
                <FormErrorMessage errorMessage={error} />
                <FormRow>
                    <FormAdditionalText>Have an Account?</FormAdditionalText>
                    <FormAdditionalButton
                        disabled={isLoading}
                        onClick={() => navigate('/login')}>
                        Login
                    </FormAdditionalButton>
                </FormRow>
            </PageContent>
        </Page>
    )
}

export default ResetPassword
