import {
    Box,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    styled,
} from '@mui/material'
import {inputHeight} from 'appearance/theme'
import {useI18n} from 'store/Store'

const ChipSelect = ({
    name,
    label,
    value,
    onChange,
    placeholder,
    options,
    error,
    getOptionLabel,
    itemsName,
    optionsName,
}) => {
    const {i18n} = useI18n()

    const formatOptionLabel = (option) => {
        if (getOptionLabel) return getOptionLabel(option)

        return itemsName && optionsName
            ? i18n.t(`${itemsName}.${optionsName}.${option}`)
            : option
    }

    const onDelete = (deleted) => () => {
        const newValue = [...value]
        newValue.splice(value.indexOf(deleted), 1)
        onChange(newValue)
    }

    return (
        <FormControl fullWidth>
            <InputLabel id={name} shrink>
                {label}
            </InputLabel>
            <Select
                labelId={name}
                multiple
                value={value}
                onChange={onChange}
                input={
                    <OutlinedInput
                        sx={{height: inputHeight}}
                        notched
                        label={label}
                        placeholder={placeholder}
                        InputLabelProps={{shrink: true}}
                    />
                }
                renderValue={(selected) => (
                    <ChipsContainer>
                        {selected?.map((value) => (
                            <Chip
                                key={value}
                                label={formatOptionLabel(value)}
                                onDelete={onDelete(value)}
                                onMouseDown={(event) => event.stopPropagation()}
                            />
                        ))}
                    </ChipsContainer>
                )}>
                {options?.map((option, index) => (
                    <MenuItem key={option?._id || index} value={option}>
                        {formatOptionLabel(option)}
                    </MenuItem>
                ))}
            </Select>
            {!!error && (
                <FormHelperText error={!!error}>
                    {i18n.t(`formErrors.${error}`)}
                </FormHelperText>
            )}
        </FormControl>
    )
}

export default ChipSelect

const ChipsContainer = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
`
