import FormTextInput from 'components/Control/Form/FormTextInput'
import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {FormColumn, FormContainer, FormRow} from '../Form/Forms'
import FormSelect from 'components/Control/Form/FormSelect'
import {yesNoOptions} from 'constants/users'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import FormChipAutocomplete from 'components/Control/Form/FormChipAutocomplete'
import {formatItemName, formatUser, formatShapeType} from 'services/utils/data'
import FormFreeSoloChipInput from 'components/Control/Form/FormFreeSoloChipInput'
import RichTextEditor from 'components/Control/Form/RichTextEditor'
import {styled} from '@mui/system'
import {shapes} from 'constants/dances'
import FormChipSelect from 'components/Control/Form/FormChipSelect'
import {getLocale} from 'localization/i18n'
import DanceVideos from './DanceVideos'

// eslint-disable-next-line complexity
const DanceForm = ({
    isNew,
    choreographers,
    artists,
    searchChoreographer,
    setSearchChoreographer,
    searchArtist,
    setSearchArtist,
    activeInput,
    onDeleteVideo,
    isGuidesLoading,
    isArtistsLoading,
    videos,
}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const activeLocale = getLocale()
    const {
        watch,
        control,
        formState: {errors},
    } = formContext

    const youtubeIds = watch('youtubeIds')
    console.log('formContext', formContext.getValues())

    return (
        <FormContainer>
            <FormRow>
                <FormColumn>
                    <FormTextInput
                        readOnly
                        hidden={isNew}
                        fullWidth
                        name="_id"
                        label={i18n.t(`users._id`)}
                        control={control}
                        error={errors._id}
                    />
                    <FormSelect
                        fullWidth
                        itemsName="users"
                        optionsName="yesNo"
                        name="hidden"
                        label={i18n.t(`dances.isHidden`)}
                        options={yesNoOptions}
                        control={control}
                        error={errors.hidden}
                    />

                    <FormRow>
                        <FormTextInput
                            fullWidth
                            name="yearCreated"
                            label={i18n.t(`dances.yearCreated`)}
                            control={control}
                            error={errors.yearCreated}
                        />

                        <FormDatePicker
                            fullWidth
                            name="filmedDate"
                            label={i18n.t(`dances.filmedDate`)}
                            control={control}
                            error={errors.filmedDate}
                            valueFormat="YYYY-MM-DD"
                        />
                    </FormRow>
                </FormColumn>
                <FormColumn>
                    <FormTextInput
                        fullWidth
                        name="nameEng"
                        label={i18n.t(`dances.nameEng`)}
                        control={control}
                        error={errors.nameEng?.message}
                    />

                    <FormTextInput
                        fullWidth
                        name="nameHeb"
                        label={i18n.t(`dances.nameHeb`)}
                        control={control}
                        error={errors.nameHeb?.message}
                    />

                    <FormTextInput
                        fullWidth
                        name="songName"
                        label={i18n.t(`dances.songName`)}
                        control={control}
                        error={errors.songName}
                    />
                </FormColumn>
            </FormRow>

            <DanceVideos onDeleteVideo={onDeleteVideo} videos={videos} />

            <FormRow>
                <FormColumn>
                    <FormChipAutocomplete
                        name="choreographers"
                        label={i18n.t(`dances.choreographers`)}
                        options={choreographers?.data}
                        control={control}
                        error={errors.choreographers}
                        getOptionLabel={formatUser}
                        inputValue={
                            activeInput === 'choreographers' &&
                            searchChoreographer
                        }
                        setInputValue={setSearchChoreographer}
                        isLoading={isGuidesLoading}
                    />

                    <FormChipAutocomplete
                        fullWidth
                        name="composers"
                        label={i18n.t(`dances.composers`)}
                        options={artists?.data}
                        control={control}
                        error={errors.composers}
                        getOptionLabel={formatItemName}
                        inputValue={activeInput === 'composers' && searchArtist}
                        setInputValue={setSearchArtist}
                        isLoading={
                            activeInput === 'composers' && isArtistsLoading
                        }
                    />

                    <FormChipAutocomplete
                        fullWidth
                        name="companies"
                        label={i18n.t(`dances.companies`)}
                        options={artists?.data}
                        control={control}
                        error={errors.companies}
                        getOptionLabel={formatItemName}
                        inputValue={activeInput === 'companies' && searchArtist}
                        setInputValue={setSearchArtist}
                        isLoading={
                            activeInput === 'companies' && isArtistsLoading
                        }
                    />

                    <FormFreeSoloChipInput
                        fullWidth
                        name="youtubeIds"
                        label={i18n.t(`dances.youtubeIds`)}
                        control={control}
                        error={errors.youtubeIds}
                        getOptionLabel={(option) => option}
                    />
                    {youtubeIds?.join(', ')}
                </FormColumn>
                <FormColumn>
                    <FormChipAutocomplete
                        fullWidth
                        name="performers"
                        label={i18n.t(`dances.performers`)}
                        options={artists?.data}
                        control={control}
                        error={errors.performers}
                        getOptionLabel={formatItemName}
                        inputValue={
                            activeInput === 'performers' && searchArtist
                        }
                        setInputValue={setSearchArtist}
                        isLoading={
                            activeInput === 'performers' && isArtistsLoading
                        }
                    />

                    <FormChipAutocomplete
                        fullWidth
                        name="lyricists"
                        label={i18n.t(`dances.lyricists`)}
                        options={artists?.data}
                        control={control}
                        error={errors?.lyricists}
                        getOptionLabel={formatItemName}
                        inputValue={activeInput === 'lyricists' && searchArtist}
                        setInputValue={setSearchArtist}
                        isLoading={
                            activeInput === 'lyricists' && isArtistsLoading
                        }
                    />

                    <FormChipSelect
                        fullWidth
                        name="shapes"
                        itemsName="dances"
                        optionsName="shapesTypes"
                        label={i18n.t(`dances.shapes`)}
                        options={shapes}
                        control={control}
                        error={errors.shapes}
                        getOptionLabel={(option) =>
                            formatShapeType(option, i18n, activeLocale)
                        }
                        single
                    />
                </FormColumn>
            </FormRow>
            <TextsEditorsContainer>
                <RichTextEditor
                    name="lyrics"
                    label={i18n.t(`dances.lyricsHeb`)}
                    control={control}
                    error={errors.lyricsHeb}
                />

                <RichTextEditor
                    name="lyricsEng"
                    label={i18n.t(`dances.lyricsEng`)}
                    control={control}
                    error={errors.lyricsEng}
                />

                <RichTextEditor
                    name="danceSteps"
                    label={i18n.t(`dances.danceStepsHeb`)}
                    control={control}
                    error={errors.danceStepsHeb}
                />

                <RichTextEditor
                    name="danceStepsEng"
                    label={i18n.t(`dances.danceStepsEng`)}
                    control={control}
                    error={errors.danceStepsEng}
                />

                <RichTextEditor
                    name="danceStory"
                    label={i18n.t(`dances.danceStoryHeb`)}
                    control={control}
                    error={errors.danceStoryHeb}
                />

                <RichTextEditor
                    name="danceStoryEng"
                    label={i18n.t(`dances.danceStoryEng`)}
                    control={control}
                    error={errors.danceStoryEng}
                />
            </TextsEditorsContainer>
        </FormContainer>
    )
}

export default DanceForm

const TextsEditorsContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 12px;
`
