import {Visibility, VisibilityOff} from '@mui/icons-material'
import {IconButton, InputAdornment, TextField} from '@mui/material'
import {inputHeight} from 'appearance/theme'
import {useState} from 'react'
import {useI18n} from 'store/Store'

const Input = ({
    label,
    onChange,
    value,
    placeholder,
    initialValue = '',
    required,
    disabled,
    readOnly,
    error,
    name,
    type,
    fullWidth,
    inputProps,
    InputProps,
    inputStyle,
    ...props
}) => {
    const {i18n} = useI18n()
    const [showPassword, setShowPassword] = useState(false)

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <TextField
            onChange={onChange}
            value={value || initialValue}
            fullWidth={fullWidth}
            label={label}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            type={showPassword ? 'text' : type}
            inputProps={{...inputProps, readOnly, style: inputStyle}}
            error={!!error}
            helperText={error && i18n.t(`formErrors.${error}`)}
            FormHelperTextProps={{
                error: !!error,
            }}
            InputLabelProps={{shrink: true}}
            InputProps={{
                ...InputProps,
                style: {...InputProps?.style, height: inputHeight},
                endAdornment: type === 'password' && (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    )
}
export default Input
