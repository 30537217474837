import {useForm} from 'react-hook-form'
import {useUploadDanceMediaMutation} from 'store/slices/api/dancesApi'

const useUploadDanceMediaForm = (danceId, toggleModal) => {
    const [doUploadMedia, {isSuccess, isLoading}] =
        useUploadDanceMediaMutation()

    const form = useForm()
    const {handleSubmit} = form

    const onSubmit = async (values) => {
        const formData = new FormData()
        formData.append('files', values.file)
        formData.append('fileType', values.fileType)
        formData.append('danceId', danceId)

        const response = await doUploadMedia(formData)
        if (response?.data?.url) {
            toggleModal?.()
        }
    }

    const submit = handleSubmit(onSubmit)

    return {
        form,
        submit,
        isLoading,
        isSuccess,
        isValidForm: form.formState.isDirty && form.formState.isValid,
    }
}

export default useUploadDanceMediaForm
