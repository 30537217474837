import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {isNew} from 'services/utils/editForm'
import {yupResolver} from '@hookform/resolvers/yup'
import {sessionSchema} from 'services/validations/schemas/sessions'
import {useGetCitiesQuery} from 'store/slices/api/citiesApi'
import {useGetCountriesQuery} from 'store/slices/api/countriesApi'
import {useGetGuidesQuery} from 'store/slices/api/guidesApi'
import {
    useCreateSessionMutation,
    useGetSessionQuery,
    useSaveSessionMutation,
} from 'store/slices/api/sessionsApi'
import {useI18n} from 'store/Store'
import {useAlert} from 'components/Display/Alert/AlertContext'
import {urls} from 'services/utils/urls'
import {useUploadImageMutation} from 'store/slices/api/uploadsApi'
import {useNavigate} from 'react-router-dom'

const defaultValues = {
    isActive: true,
    isWeekly: true,
    airConditioner: true,
}

const useSessionForm = (sessionId) => {
    const {i18n} = useI18n()
    const {showAlert} = useAlert()
    const navigate = useNavigate()

    const form = useForm({
        mode: 'all',
        resolver: yupResolver(sessionSchema),
        defaultValues,
    })
    const {handleSubmit} = form

    const formValues = form.watch()

    const [formError, setFormError] = useState()
    const [searchGuideValue, setSearchGuideValue] = useState()
    const [searchCountryValue, setSearchCountryValue] = useState()
    const [searchCityValue, setSearchCityValue] = useState()

    const [doCreateSession] = useCreateSessionMutation()
    const [doSaveSession] = useSaveSessionMutation()
    const {data: session} = useGetSessionQuery(sessionId, {
        skip: isNew(sessionId),
    })

    const {data: guides} = useGetGuidesQuery(
        {
            search: searchGuideValue,
        },
        {skip: !searchGuideValue}
    )
    const {data: countries} = useGetCountriesQuery(
        {search: searchCountryValue},
        {skip: !searchCountryValue}
    )
    const {data: cities} = useGetCitiesQuery(
        {search: searchCityValue},
        {skip: !searchCityValue}
    )

    const [doUploadImage] = useUploadImageMutation()

    useEffect(() => {
        if (session) {
            form.reset(session)
        }
    }, [form, session])

    const formatBody = (values) => {
        const guides = values.guides?.map((guide) => guide._id)
        const city = values.city?._id
        const country = values.country?._id
        const learnedDances =
            values.learnedDances?.map((dance) => dance._id) || []

        return {
            ...values,
            guides,
            city,
            country,
            learnedDances,
        }
    }

    const onSubmit = async (values) => {
        let response
        const body = formatBody(values)
        if (values._id) {
            response = await doSaveSession(body)
        } else {
            response = await doCreateSession(body)
        }
        if (response?.data?._id) {
            const message = i18n.t('form.savedSuccessfully', {
                itemName: i18n.t('tables.session'),
            })
            navigate(`/sessions/${response.data._id}`)
            showAlert(message)
        }
        if (response.error) {
            setFormError(response.error.data.error)
        }
    }

    const submit = handleSubmit(onSubmit)

    const handleSetValue = (value, name) => {
        form.setValue(name, value)
    }

    const getImageUrl = () => {
        const {base, sessionsPictures} = urls
        if (session?.imageUrl) {
            // backward compatibility
            return session.imageUrl.includes(sessionsPictures)
                ? `https://rokdim.co.il/${session.imageUrl}`
                : `${base}/${sessionsPictures}/${session.imageUrl}`
        } else if (session?.hasPicture) {
            return `${base}/${sessionsPictures}/sp${session._id}`
        }
    }

    const uploadImage = async () => {
        const formData = new FormData()
        formData.append('files', formValues.imageUrl)
        formData.append('folderName', urls.sessionsPictures)

        const response = await doUploadImage({
            formData,
            folderName: urls.sessionsPictures,
        })

        const link = response?.data?.files?.[0].link

        if (link) {
            form.setValue('imageUrl', link)
        }
    }

    return {
        form,
        submit,
        session,
        formError,
        guidesResults: guides,
        searchGuideValue,
        setSearchGuideValue,
        countriesResults: countries,
        searchCountryValue,
        setSearchCountryValue,
        citiesResults: cities,
        searchCityValue,
        setSearchCityValue,
        handleSetValue,
        imageUrl: getImageUrl(),
        uploadImage,
    }
}

export default useSessionForm
