import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const countriesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCountries: build.query({
            query: (query) => {
                const url = constructUrl('countries', {
                    ...query,
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Country'),
        }),
        getCountry: build.query({
            query: (id) => ({
                url: `countries/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Country', result),
        }),
        createCountry: build.mutation({
            query: (body) => {
                return {
                    url: `countries`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Country'),
        }),
        saveCountry: build.mutation({
            query: (body) => {
                return {
                    url: `countries/${body._id}`,
                    method: 'PUT',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Country', result),
        }),
        deleteCountry: build.mutation({
            query: (id) => {
                return {
                    url: `countries/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Country'),
        }),
    }),
    overrideExisting: false,
})

export default countriesApi

export const {
    useGetCountriesQuery,
    useGetCountryQuery,
    useCreateCountryMutation,
    useSaveCountryMutation,
    useDeleteCountryMutation,
} = countriesApi
