import {useI18n} from 'store/Store'
import {Video} from '@styled-icons/fluentui-system-regular/Video'
import {MusicNote1} from '@styled-icons/fluentui-system-regular/MusicNote1'
import {RecordVoiceOver} from '@styled-icons/material-outlined/RecordVoiceOver'
import {PersonVideo2} from '@styled-icons/bootstrap/PersonVideo2'
import {styled, Tooltip} from '@mui/material'

const TableFiles = ({dance}) => {
    const {i18n} = useI18n()
    const {videoDance, videoFull, videoTeach} = dance.videos

    return (
        <Container>
            {videoFull?.videoUrl && (
                <Tooltip title={i18n.t(`dances.videoFull`)}>
                    <Video height={18} color="green" />
                </Tooltip>
            )}

            {videoTeach?.videoUrl && (
                <Tooltip title={i18n.t(`dances.videoTeach`)}>
                    <RecordVoiceOver height={18} color="green" />
                </Tooltip>
            )}

            {videoDance?.videoUrl && (
                <Tooltip title={i18n.t(`dances.videoDance`)}>
                    <PersonVideo2 height={17} color="green" />
                </Tooltip>
            )}

            {!!dance?.songs?.[0]?.urlFull && (
                <Tooltip title={i18n.t(`dances.audio`)}>
                    <MusicNote1 height={18} color="green" />
                </Tooltip>
            )}
        </Container>
    )
}

export default TableFiles

const Container = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`
