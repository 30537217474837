import {CircularProgress, List, styled} from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import {useI18n} from 'store/Store'
import Texts from 'appearance/texts'
import Button from 'components/Control/Button'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import {Page, PageContent} from 'components/Page'
import useUploadFilmedDatesForm from 'logic/Forms/useUploadFilmedDatesForm'
import FilmedDateForUploadItem from './FilmedDateForUploadItem'

const UploadFilmedDates = () => {
    const {i18n} = useI18n()

    const {
        control,
        errors,
        filesForUpload,
        status,
        isFetching,
        isUpload,
        submit,
        youtubeUploadStatus,
    } = useUploadFilmedDatesForm()

    return (
        <Page>
            <PageContent>
                <Container>
                    <Texts.Title>
                        {i18n.t('dances.uploadFullFilmedDate')}
                    </Texts.Title>
                    <Texts.RegularText>
                        {i18n.t('upload.processStatus', {
                            status,
                        })}
                    </Texts.RegularText>

                    <InputWrapper>
                        <FormDatePicker
                            name="filmedDate"
                            label={i18n.t(`dances.filmedDate`)}
                            control={control}
                            error={errors.filmedDate}
                            timezone="UTC"
                        />

                        <Button
                            variant="contained"
                            style={{height: '39px', gap: 0, width: '120px'}}
                            startIcon={<FileUploadIcon />}
                            onClick={submit}
                            loading={isUpload}>
                            {i18n.t('upload.uploadButton')}
                        </Button>
                    </InputWrapper>

                    <div>
                        <Texts.RegularText>
                            {i18n.t('upload.filesWaitingForUpload')}
                        </Texts.RegularText>
                        <List>
                            {isFetching && <CircularProgress size={16} />}
                            {filesForUpload && !filesForUpload.length ? (
                                <Texts.RegularText>
                                    {i18n.t('upload.noFiles')}
                                </Texts.RegularText>
                            ) : (
                                filesForUpload?.map((file) => (
                                    <FilmedDateForUploadItem
                                        key={file.name}
                                        file={file}
                                    />
                                ))
                            )}
                        </List>
                    </div>

                    <div>
                        <Texts.RegularText>
                            {i18n.t('dances.youtubeUploadStatus')}:
                        </Texts.RegularText>
                        <List>
                            {Object.keys(youtubeUploadStatus || {}).map(
                                (key) => (
                                    <Texts.RegularText key={key}>
                                        {`${key}: ${Object.keys(
                                            youtubeUploadStatus[key]
                                        )
                                            .map(
                                                (statusKey) =>
                                                    `${statusKey} - ${youtubeUploadStatus[key][statusKey]}`
                                            )
                                            .join(', ')}`}
                                        <br />
                                    </Texts.RegularText>
                                )
                            )}
                        </List>
                    </div>
                </Container>
            </PageContent>
        </Page>
    )
}

export default UploadFilmedDates

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 12px;
`

const InputWrapper = styled('div')`
    display: flex;
    align-items: center;
    gap: 12px;
    width: 50%;
`
