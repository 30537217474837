import {useEffect, useState} from 'react'
import {
    filterToState,
    getItemId,
    getTableData,
    mergeOldAndNewFilters,
    sortToState,
    stateToSort,
} from 'services/utils/tables'
import {INITIAL_PAGE_SIZE} from 'constants/tables'
import {useI18n} from 'store/Store'
import {useNavigate} from 'react-router-dom'
import {useUrlParams} from 'services/hooks'
import dayjs from 'services/utils/dayjs'

const useTable = ({
    itemName,
    useGetQuery,
    paginationType,
    useDeleteMutation,
    visibleKeys,
    allowExpand,
    onEdit,
    onCreate,
    translationKey,
}) => {
    const {i18n} = useI18n()
    const navigate = useNavigate()

    const tableName = `${itemName}s`

    const {urlParams, setUrlParam} = useUrlParams()
    const [visibleColumns, setVisibleColumns] = useState()

    const {
        [`${itemName}_search`]: search,
        [`${itemName}_page`]: page,
        [`${itemName}_pageSize`]: pageSize,
        [`${itemName}_sort`]: sort,
        ...filter
    } = urlParams

    const initialPagination = {
        pageIndex: +(page || 0),
        pageSize: +(pageSize || INITIAL_PAGE_SIZE),
    }

    const handlePagination = (updater) => {
        if (updater instanceof Function) {
            const newPagination = updater(initialPagination)
            setUrlParam(`${itemName}_page`, newPagination.pageIndex)
            setUrlParam(`${itemName}_pageSize`, newPagination.pageSize)
        }
    }

    const sorting = sortToState(sort)

    const onSortingChange = (updater) => {
        if (updater instanceof Function) {
            const newSorting = updater(sorting)
            setUrlParam(`${itemName}_sort`, stateToSort(newSorting))
        }
    }

    const handleTableSearch = (searchValue) => {
        setUrlParam(`${itemName}_search`, searchValue)
    }

    const handleColumnFilters = (updater) => {
        if (updater instanceof Function) {
            const newColumnFilters = updater(filterToState(filter))

            const oldFilters = mergeOldAndNewFilters(filter, newColumnFilters)
            const combinedFilters = [...oldFilters, ...newColumnFilters]

            combinedFilters.forEach(({id, value}) => {
                if (dayjs.isDayjs(value)) {
                    value = value.format('YYYY-MM-DD')
                }
                setUrlParam(id, value)
            })
        }
    }

    const {data, refetch, isFetching} = useGetQuery(
        paginationType === 'external'
            ? {
                  page: page || 0,
                  pageSize: pageSize || INITIAL_PAGE_SIZE,
                  search,
                  sort,
                  ...filter,
              }
            : {}
    )

    useEffect(() => {
        if (paginationType === 'external') {
            refetch()
        }
    }, [refetch, paginationType, sort])

    const [errorMessage, setErrorMessage] = useState(null)
    const [doDelete, {error}] = useDeleteMutation()

    useEffect(() => {
        setErrorMessage(error?.data?.message)
    }, [error])

    const {tableKeys, tableData, keyTypes, tableItems, totalItems, columns} =
        getTableData(
            tableName,
            visibleKeys,
            data,
            page,
            pageSize,
            search,
            sort,
            paginationType,
            i18n,
            translationKey
        )

    const [expandedId, setExpandedId] = useState(null)

    const handleExpand = () => (allowExpand ? setExpandedId : null)

    const getExpandedItem = () =>
        tableItems.find((item) => getItemId(item) === expandedId)

    const editOrCreateItem = (id) => {
        if (onEdit) return onEdit(id)
        if (onCreate) return onCreate()

        const itemId = id || 'new'
        navigate(`/${tableName}/${itemId}`)
    }

    const setColumnVisibility = (updater) => {
        if (updater instanceof Function) {
            const newColumns = updater(visibleColumns)
            setVisibleColumns(newColumns)
            localStorage.setItem('columns', JSON.stringify(newColumns))
        }
    }

    useEffect(() => {
        if (!visibleColumns) {
            const columnsVisibility = localStorage.getItem('columns')
            columnsVisibility &&
                setVisibleColumns(JSON.parse(columnsVisibility))
        }
    }, [visibleColumns])

    return {
        tableName,
        tableKeys,
        tableData,
        keyTypes,
        tableItems,
        totalItems,
        errorMessage,
        doDelete,
        expandedId,
        handleExpand,
        getExpandedItem,
        editOrCreateItem,
        columns,
        isLoading: isFetching,
        pagination: initialPagination,
        setPagination: handlePagination,
        sorting,
        setSorting: onSortingChange,
        columnFilters: filterToState(filter),
        setColumnFilters: handleColumnFilters,
        tableSearch: search,
        setTableSearch: handleTableSearch,
        columnVisibility: visibleColumns,
        setColumnVisibility,
    }
}

export default useTable
