import TableCellRaw from '@mui/material/TableCell'
import {useIsRtl} from 'store/Store'
import {styled} from 'common'
import {formatKeyFunctionsByType} from 'services/utils/tables'

const TableRow = ({itemKey, itemId, tableRowData, keyTypes, keyActions}) => {
    const isRtl = useIsRtl()
    const value = tableRowData[itemKey]
    const type = keyTypes?.[itemKey]
    const displayValue = formatKeyFunctionsByType[type]?.(value) || value

    return (
        <TableCellRaw
            align={isRtl ? 'right' : 'left'}
            component="th"
            scope="row">
            {keyActions?.[itemKey] ? (
                <Action onClick={() => keyActions?.[itemKey]?.(itemId)}>
                    {displayValue}
                </Action>
            ) : (
                displayValue
            )}
        </TableCellRaw>
    )
}

export default TableRow

const Action = styled.span`
    color: blue;
    cursor: pointer;
`
