import FormSelect from 'components/Control/Form/FormSelect'
import {fileTypes} from 'constants/dances'
import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import FormUpload from 'components/Control/Form/FormUpload'
import FormAutocomplete from 'components/Control/Form/FormAutocomplete'
import {formatDance} from 'services/utils/data'
import {ModalFormContainer} from '../Form/Forms'

const UploadMediaForm = ({
    danceId,
    dances,
    searchDanceValue,
    setSearchDanceValue,
}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const selectedFileType = formContext.watch('fileType')

    return (
        <ModalFormContainer>
            {!danceId && (
                <FormAutocomplete
                    fullWidth
                    name="dance"
                    label={i18n.t(`dances.selectDance`)}
                    control={formContext.control}
                    error={formContext.formState.errors.dance}
                    options={dances?.data || []}
                    getOptionLabel={formatDance}
                    inputValue={searchDanceValue}
                    onChangeInputValue={setSearchDanceValue}
                />
            )}
            <FormSelect
                itemsName="dances"
                optionsName="fileTypes"
                name="fileType"
                label={i18n.t(`dances.fileType`)}
                options={fileTypes}
                control={formContext.control}
                error={formContext.formState.errors.fileType}
            />

            <FormUpload
                name="file"
                control={formContext.control}
                accept={
                    selectedFileType === 'af'
                        ? '.mp3,audio/*'
                        : 'video/mp4,video/*'
                }
            />
        </ModalFormContainer>
    )
}

export default UploadMediaForm
