import {React, styled} from 'common'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {useI18n} from 'store/Store'
import useSideMenuItem from 'logic/Menu/useSideMenuItem'
import {Link} from 'react-router-dom'
import {Badge} from '@mui/material'

const SideMenuItem = ({
    open,
    text,
    index,
    onClick,
    children,
    messagesCount,
}) => {
    const {i18n} = useI18n()

    const {icon, isSelected, itemPath} = useSideMenuItem(text, index)

    const linkTo = onClick ? '' : itemPath

    return (
        <StyledLink to={linkTo}>
            <StyledListItemButton
                sx={{px: 2.5}}
                open={open}
                onClick={onClick}
                selected={isSelected}>
                {icon ? (
                    <ListItemIcon>
                        <Badge
                            badgeContent={messagesCount}
                            color="primary"
                            invisible={text !== 'messages'}>
                            {icon}
                        </Badge>
                    </ListItemIcon>
                ) : null}
                {!!text && (
                    <ListItemText
                        primary={i18n.t(`menu.${text}`, {defaultValue: text})}
                        primaryTypographyProps={{sx: {fontWeight: 'normal'}}}
                        sx={{
                            opacity: open ? 1 : 0,
                            color: 'rgba(0, 0, 0, 0.87)',
                        }}
                    />
                )}
                {children}
            </StyledListItemButton>
        </StyledLink>
    )
}

export default SideMenuItem

const StyledListItemButton = styled(ListItemButton)`
    min-height: 48px;
    justify-content: ${(p) => (p.open ? 'flex-start' : 'center')};
`
const StyledLink = styled(Link)`
    text-decoration: none;
`
