const he = {
    dir: 'rtl',
    header: {
        search: 'חיפוש',
        title: 'רוקדים',
    },
    formErrors: {
        required: 'שדה חובה',
        emailError: 'כתובת מייל לא תקינה',
        numberError: 'ערכים מספריים בלבד',
        invalidDate: 'תאריך לא תקין',
        startTimeAfterEnd: 'שעת התחלה צריכה להיות לפני שעת סיום',
        endTimeBeforeStart: 'שעת סיום צריכה להיות לאחר שעת ההתחלה',
        invalidEmail: 'כתובת מייל לא תקינה',
        duplicateKey: '{{key}} כבר קיים במערכת',
        somethingWentWrong: 'משהו השתבש',
        invalidUrl: 'כתובת קישור לא תקינה',
    },
    login: {
        email: 'דוא״ל',
        password: 'סיסמה',
        forgotPassword: 'שכחת סיסמה?',
        signIn: 'התחבר',
        rememberMe: 'זכור אותי',
    },
    dashboard: {
        downloadsReport: 'דוח הורדות',
        filmedDateDownload: 'הורדת תאריכי צילום',
        from: 'מתאריך',
        to: 'עד תאריך',
        download: 'הורדה',
        filmedDate: 'תאריך צילום',
        language: 'שפה',
        languages: {
            he: 'עברית',
            en: 'אנגלית',
        },
    },
    profileMenu: {
        loggedInAs: ':מחובר כ',
        logout: 'התנתק',
    },
    menu: {
        home: 'ראשי',
        users: 'משתמשים',
        guides: 'מדריכים',
        artists: 'אומנים',
        dances: 'ריקודים',
        danceCollections: 'אוספי ריקודים',
        sessions: 'הרקדות',
        moreData: 'מידע',
        messages: 'הודעות',
        payments: 'רכישות',
        publications: 'פרסומים',
        banners: 'באנרים',
        newspapers: 'עיתונים',
    },
    form: {
        update: 'עדכון',
        create: 'יצירת',
        edit: 'עריכת',
        link: 'קישור',
        add: 'הוספה',
        keyError: 'נדרש/ת {{key}} חוקי/ת.',
        not: 'לא',
        save: 'שמירה',
        cancel: 'ביטול',
        autocompleteNoOptionsText: 'אין תוצאות',
        selectFile: 'בחר קובץ',
        noFile: 'לא נבחר קובץ',
        upload: 'העלה',
        ok: 'אישור',
        new: 'חדש',
        savedSuccessfully: 'ה{{itemName}} נשמר בהצלחה',
        dragAndDrop: 'גרור ושחרר קובץ כאן או לחץ לבחירת קובץ',
        filterBy: "סנן לפי '{{key}}'",
    },
    general: {
        invalid_password: 'סיסמה חייבת להיות בת 8 תוים לפחות',
        invalid_verify_password: 'הסיסמה לא תואמת את שדה הסיסמה הראשון',
    },
    tables: {
        actions: 'פעולות',
        users: 'משתמשים',
        user: 'משתמש',
        invites: 'מוזמנים',
        accounts: 'חשבונות',
        countries: 'מדינות',
        countrie: 'מדינה',
        artists: 'אמנים',
        artist: 'אמן',
        cities: 'ערים',
        citie: 'עיר',
        shapes: 'צורה',
        shape: 'צורה',
        types: 'סוגים',
        type: 'סוג',
        guides: 'מדריכים',
        guide: 'מדריך',
        dances: 'ריקודים',
        dance: 'ריקוד',
        danceCollections: 'אוספי ריקודים',
        danceCollection: 'אוסף ריקודים',
        yes: 'כן',
        no: 'לא',
        sessions: 'הרקדות',
        session: 'הרקדה',
        messages: 'הודעות',
        message: 'הודעה',
        payments: 'רכישות',
        payment: 'רכישה',
        publications: 'פרסומים',
        publication: 'פרסום',
        banners: 'באנרים',
        banner: 'באנר',
        newspapers: 'עיתונים',
        newspaper: 'עיתון',
        addNew: 'הוסף {{itemName}}',
        copyToClipboard: 'העתק',
        copiedToClipboard: 'הועתק ללוח',
    },
    accounts: {
        _id: 'ID',
        name: 'שם',
        apiKey: 'Api Key',
        created: 'נוצר',
        updated: 'נערך',
        role: 'תפקיד',
        confirmed: 'מאושר',
    },
    users: {
        _id: 'ID',
        name: 'שם',
        email: 'דוא״ל',
        created: 'נוצר',
        role: 'תפקיד',
        videoViews: 'צפיות בסרטונים',
        audioViews: 'האזנות',
        downloads: 'הורדות',
        purchases: 'רכישות',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        isSuper: 'מנהל מערכת',
        lastActivity: 'פעילות אחרונה',
        homePhone: 'טלפון בית',
        mobilePhone: 'טלפון נייד',
        facebookId: 'מזהה פייסבוק',
        country: 'מדינה',
        clubType: 'סוג מועדון',
        clubPeriod: 'תקופת מועדון',
        clubRegistrationDate: 'תאריך הרשמה למועדון',
        giftDownloads: 'מתנות להורדה',
        remainingDownloads: 'נותרו להורדה',
        guide: 'מדריך',
        purchasedDances: 'ריקודים שנרכשו',
        updated: 'עודכן',
        oldUserId: 'Old ID',
        isSupers: {
            yes: 'כן',
            no: 'לא',
        },
        clubTypes: {
            none: 'לא רשום',
            vip: 'VIP',
            vip_plus: 'VIP plus',
        },
        clubPeriods: {
            monthly: 'חודשי',
            yearly: 'שנתי',
        },
        firstNameHeb: 'שם פרטי עברית',
        lastNameHeb: 'שם משפחה עברית',
        firstNameEng: 'שם פרטי אנגלית',
        lastNameEng: 'שם משפחה אנגלית',
        fax: 'פקס',
        address: 'כתובת',
        city: 'עיר',
        websiteUrl: 'כתובת אתר',
        imageUrl: 'כתובת תמונה',
        dateOfBirth: 'תאריך לידה',
        isDead: 'נפטר',
        dateOfDeath: 'תאריך פטירה',
        resumeEng: 'קורות חיים אנגלית',
        resumeHeb: 'קורות חיים עברית',
        inIsrael: 'בישראל',
        yesNo: {
            yes: 'כן',
            no: 'לא',
        },
    },
    moreData: {
        _id: 'ID',
        nameEng: 'שם באנגלית',
        nameHeb: 'שם בעברית',
    },
    guides: {
        _id: 'ID',
        firstNameHeb: 'שם פרטי עברית',
        lastNameHeb: 'שם משפחה עברית',
        firstNameEng: 'שם פרטי אנגלית',
        lastNameEng: 'שם משפחה אנגלית',
        email: 'מייל',
    },
    dances: {
        _id: 'ID',
        codeShort: 'קוד קצר',
        nameEng: 'שם באנגלית',
        nameHeb: 'שם בעברית',
        choreographers: 'כוריאוגרפים',
        choreographersDisplayHeb: 'כוריאוגרפים',
        shapes: 'צורות',
        videoViews: 'צפיות בוידאו',
        videoFullViews: 'צפיות מלא',
        videoTeachViews: 'צפיות לימוד',
        videoDanceViews: 'צפיות ריקוד',
        yearCreated: 'שנת יצירה',
        files: 'קבצים',
        isHidden: 'מוסתר',
        youtubeId: 'מזהה יוטיוב',
        youtubeStatus: 'סטטוס יוטיוב',
        videoFile: 'קובץ וידאו',
        songName: 'שם השיר',
        filmedDate: 'תאריך צילום',
        composers: 'מלחינים',
        performers: 'מבצעים',
        companies: 'חברות',
        lyricists: 'כותבים',
        youtubeIds: 'מזהה יוטיוב',
        facebookIds: 'מזהה פייסבוק',
        lyricsHeb: 'מילים עברית',
        lyricsEng: 'מילים אנגלית',
        danceStepsHeb: 'צעדים עברית',
        danceStepsEng: 'צעדים אנגלית',
        danceStoryHeb: 'סיפור עברית',
        danceStoryEng: 'סיפור אנגלית',
        audioViews: 'האזנות',
        downloads: 'הורדות',
        types: {
            SPECIAL_NEEDS: 'קהילה עם צרכים מיוחדים',
            SEATING_AND_STANDING: 'ריקודי עם יושבים-עומדים',
            WOMEN_ONLY: 'נשים בלבד',
            GOLDEN_AGE: 'גיל הזהב',
            SALSA_AND_RUEDA: 'סלסה ורואדה',
            BALLROOM_AND_LATIN: 'באלרום ולטיני',
            CHILDREN: 'ילדים ונוער',
            INTERNATIONAL: 'ריקודי עמים',
            WORKSHOPS: 'השתלמויות וסדנאות',
            NOSTALGIA: 'נוסטלגיה',
            MARATHONS_AND_SPECIAL: 'מרתונים והרקדות מיוחדות',
            FESTIVALS_WEEKENDS_TRIPS: 'פסטיבלים, סופי שבוע, טיולים',
            WEEKLY: 'חוגים והרקדות שבועיות',
        },
        shapesTypes: {
            SESSION: 'הרקדה',
            IMITATION_FOR_CHILDREN: 'ילדים - חיקוי',
            WHEELCHAIRS: 'כסאות גלגלים',
            CHILDREN_LINES: 'ילדים - שורות',
            TRIOS: 'שלשות',
            MEDLEY: 'מחרוזות',
            CHILDREN_COUPLES: 'ילדים - זוגות',
            CHILDREN_CIRCLE: 'ילדים - מעגל',
            PERFORMANCE: 'מופעים',
            DANCE_DELIGHT: 'שעשועי מחול',
            LINES: 'שורות',
            COUPLES: 'זוגות',
            CIRCLE: 'מעגל',
        },
        shapess: {
            SESSION: 'הרקדה',
            IMITATION_FOR_CHILDREN: 'ילדים - חיקוי',
            WHEELCHAIRS: 'כסאות גלגלים',
            CHILDREN_LINES: 'ילדים - שורות',
            TRIOS: 'שלשות',
            MEDLEY: 'מחרוזות',
            CHILDREN_COUPLES: 'ילדים - זוגות',
            CHILDREN_CIRCLE: 'ילדים - מעגל',
            PERFORMANCE: 'מופעים',
            DANCE_DELIGHT: 'שעשועי מחול',
            LINES: 'שורות',
            COUPLES: 'זוגות',
            CIRCLE: 'מעגל',
        },
        uploadMedia: 'העלה קובץ',
        fileType: 'בחר סוג קובץ',
        fileTypes: {
            fv: 'וידאו מלא',
            af: 'אודיו מלא',
            dv: 'וידאו ריקוד',
            tv: 'וידאו לימוד',
        },
        deleteVideoButton: 'מחק וידאו',
        uploadFullFilmedDate: 'העלה תאריך צילום',
        youtubeUploadStatus: 'סטטוס העלאת יוטיוב',
        selectDance: 'בחר ריקוד',
        newDance: 'ריקוד חדש',
        videoTeach: 'וידאו לימוד',
        videoDance: 'וידאו ריקוד',
        videoFull: 'וידאו מלא',
        audio: 'אודיו',
    },
    danceCollections: {
        _id: 'ID',
        created: 'נוצר',
        hidden: 'מוסתר',
        nameHeb: 'שם בעברית',
        nameEng: 'שם באנגלית',
        type: 'סוג',
        dances: 'ריקודים',
        addDance: 'הוספת ריקוד',
        types: {
            top_hits: 'להיטים',
            release_batch: 'שחרור סדרה',
            course: 'קורס',
            contest: 'תחרות',
        },
        dancesPlaceholder: 'חפש ריקוד',
    },
    sessions: {
        _id: 'ID',
        guides: 'מדריכים',
        country: 'מדינה',
        city: 'עיר',
        day: 'יום',
        description: 'תיאור',
        isWeekly: 'שבועי',
        isActive: 'פעיל',
        highlight: 'הדגשה',
        price: 'מחיר',
        airConditioner: 'מזגן',
        type: 'סוג',
        address: 'כתובת',
        placeName: 'שם מקום',
        occurrence: 'תדירות',
        occurrences: {
            ONCE: 'חד פעמית',
            WEEKLY: 'שבועית',
            MONTHLY: 'חודשית',
        },
        week: 'שבוע',
        startDateAndTime: 'תאריך ושעת התחלה',
        endDateAndTime: 'תאריך ושעת סיום',
        startDateBeginners: 'תאריך התחלה למתחילים',
        timeTable: 'לוח זמנים',
        time: 'זמנים',
        hour: 'שעה',
        timeStart: 'משעה',
        timeEnd: 'עד שעה',
        shape: 'צורה',
        level: 'רמה',
        levels: {
            BEGINNERS: 'מתחילים',
            MIDDLES: 'בינוניים',
            ADVANCED: 'מתקדמים',
            MIXED: 'משולב',
        },
        picture: 'תמונה',
        days: {
            1: 'ראשון',
            2: 'שני',
            3: 'שלישי',
            4: 'רביעי',
            5: 'חמישי',
            6: 'שישי',
            7: 'שבת',
        },
        learnedDances: 'ריקודים שנלמדו לאחרונה',
    },
    messages: {
        _id: 'ID',
        created: 'נוצר',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        email: 'מייל',
        mobilePhone: 'טלפון נייד',
        address: 'כתובת',
        city: 'עיר',
        message: 'הודעה',
        modalTitle: 'הודעה',
        markAsSeen: 'סמן כנקרא',
        rightToLeft: 'ימין לשמאל',
        deleteMessage: 'האם אתה בטוח שברצונך למחוק הודעה זו?',
    },
    payments: {
        _id: 'ID',
        userName: 'שם משתמש',
        userEmail: 'מייל',
        created: 'נוצר',
        amount: 'סכום',
        currency: 'מטבע',
        packageId: 'חבילה',
        subscriptionId: 'מנוי',
        transactionId: 'מזהה עסקה',
        success: 'הצלחה',
        dance: 'ריקוד',
    },
    publications: {
        _id: 'ID',
        created: 'נוצר',
        date: 'תאריך',
        detailsEng: 'פרטים אנגלית',
        detailsHeb: 'פרטים עברית',
        guide: 'מדריך',
        expirationDate: 'תאריך תפוגה',
        isActive: 'פעיל',
        moreDetailsHeb: 'פרטים נוספים עברית',
        moreDetailsEng: 'פרטים נוספים אנגלית',
        link: 'קישור (http://...)',
        linkTitleEng: 'כותרת קישור אנגלית',
        linkTitleHeb: 'כותרת קישור עברית',
        flyerUrl: 'קישור לפלייר',
        flyer: 'פלייר',
    },
    banners: {
        _id: 'ID',
        created: 'נוצר',
        name: 'שם',
        websiteUrl: 'כתובת אתר',
        location: 'מיקום',
        imgUrl: 'באנר',
        isActive: 'פעיל עברית',
        isActiveEng: 'פעיל אנגלית',
        width: '(פיקסלים) רוחב',
        height: '(פיקסלים) גובה',
        locations: {
            home: 'דף הבית',
            dance: 'עמוד ריקוד',
        },
        picture: 'תמונה',
    },
    newspapers: {
        _id: 'ID',
        issueNumber: "מס' עיתון",
        issueTitle: 'כותרת עיתון',
        issueTitleHebrew: 'כותרת עיתון בעברית',
        downloadsCounter: 'הורדות',
        activated: 'פעיל',
        isVipOnly: 'VIP',
        vipOnly: 'VIP בלבד',
        pdf: 'PDF\nEN | HE',
        pdfUrl: 'קישור קובץ עיתון (PDF)',
        pdfUrlHebrew: 'קישור קובץ עיתון עברית (PDF)',
        thumbnail: 'JPG\nEN | HE',
        thumbnailUrl: 'קישור לתמונה ממוזערת (JPG)',
        thumbnailUrlHebrew: 'קישור לתמונה ממוזערת בעברית (JPG)',
        uploadIssue: 'העלה קובץ עיתון (PDF)',
        uploadThumbnail: 'העלה קובץ תמונה (JPG)',
        fileType: 'סוג קובץ',
        language: 'שפה',
        fileTypes: {
            pdf: 'PDF',
            thumbnail: 'תמונה',
        },
        languages: {
            he: 'עברית',
            en: 'אנגלית',
        },
    },
    upload: {
        processStatus: 'סטטוס התליך: {{status}}',
        processStatuses: {
            idle: 'מוכן',
            waiting: 'ממתין',
            running: 'מבצע העלאה',
        },
        filmedDate: 'תאריך צילום',
        filesWaitingForUpload: 'קבצים ממתינים להעלאה:',
        uploadButton: 'העלה',
        noFiles: 'אין קבצים ממתינים',
        fileMissing: 'קובץ חסר',
        fileExists: 'קובץ קיים',
    },
}

export default he
