import {Button, styled} from '@mui/material'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import useDownloadsReport from 'logic/Reports/useDownloadsReport'
import {useI18n} from 'store/Store'

const DownloadsReport = () => {
    const {i18n} = useI18n()
    const {control, errors, formValues, getDownloadUrl} = useDownloadsReport()

    return (
        <Container>
            <FormContainer>
                <FormDatePicker
                    fullWidth
                    name="from"
                    label={i18n.t('dashboard.from')}
                    control={control}
                    error={errors.from}
                    valueFormat="YYYY-MM-DD"
                />

                <FormDatePicker
                    fullWidth
                    name="to"
                    label={i18n.t('dashboard.to')}
                    control={control}
                    error={errors.to}
                    valueFormat="YYYY-MM-DD"
                />
            </FormContainer>
            <Button
                variant="contained"
                style={{alignSelf: 'center'}}
                href={getDownloadUrl()}
                disabled={!formValues.from || !formValues.to}>
                {i18n.t('dashboard.download')}
            </Button>
        </Container>
    )
}

export default DownloadsReport

const Container = styled('div')`
    display: flex;
    flex-direction: column;
`

const FormContainer = styled('div')`
    display: flex;
    gap: 36px;
    padding: 48px 24px;
`
