import {getLocale} from 'localization/i18n'
import {useForm} from 'react-hook-form'
import baseUrl from 'store/slices/api/baseUrl'
import {constructUrl} from 'store/slices/api/utils'

const useDownloadsReport = () => {
    const locale = getLocale()
    const {
        control,
        formState: {errors},
        watch,
    } = useForm()

    const formValues = watch()

    const getDownloadUrl = () => {
        return constructUrl(`${baseUrl}/purchases/downloadsReport`, {
            ...formValues,
            locale,
        })
    }

    return {control, errors, formValues, getDownloadUrl}
}

export default useDownloadsReport
