import {React, styled} from 'common'
import {Colors, Texts} from 'appearance'
import Header from 'components/Page/Header'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import {useGetAccountsQuery, useGetCurrentUserQuery, useI18n} from 'store/Store'
import {sendAxiosRequest} from 'store/slices/api/baseApi'
import SideMenuDrawerHeader from './SideMenu/SideMenuDrawerHeader'
import SideMenu from './SideMenu'
import useSideMenu from 'logic/Menu/useSideMenu'
import {useGetUnreadCountQuery} from 'store/slices/api/messagesApi'

const Page = ({title, menuTitle, children, noHeader, noDrawer}) => {
    const {i18n} = useI18n()
    const {open, toggleMenu, marginStart} = useSideMenu(noDrawer)
    const {data: user} = useGetCurrentUserQuery()
    const {data: accounts} = useGetAccountsQuery({}, {skip: !user})
    const {data: count} = useGetUnreadCountQuery({}, {skip: !user})

    return (
        <Container dir={i18n.t('dir') || 'ltr'}>
            <CssBaseline />

            {!noHeader && (
                <Header
                    toggleDrawer={toggleMenu}
                    open={open}
                    user={user}
                    accounts={accounts}
                />
            )}

            {!noDrawer && (
                <SideMenu
                    open={open}
                    toggleDrawer={toggleMenu}
                    accounts={accounts}
                    user={user}
                    messagesCount={count?.unread}
                />
            )}
            <Content
                component="main"
                marginstart={marginStart}
                noheader={`${noHeader}`}>
                <SideMenuDrawerHeader />

                <Texts.Title>
                    {menuTitle ? i18n.t(`menu.${menuTitle}`) : title}
                </Texts.Title>
                {children}
            </Content>
            <ClearTestDataButton
                data-test-id="clear-test-data"
                onClick={() =>
                    sendAxiosRequest(null, 'tests/clearTestData', 'GET')
                }
            />
        </Container>
    )
}

export default Page

const Container = styled(Box)`
    flex-grow: 1;
    background-color: ${Colors.APP_GRAY_LIGHT};
    height: 100vh;
    overflow-x: hidden;
`

const Content = styled(Box)`
    flex-grow: 1;
    height: 100vh;
    padding: 18px;
    display: flex;
    flex-direction: column;
    align-items: ${(p) => (p.noheader === 'true' ? 'center' : 'flex-start')};
    margin-inline-start: ${(p) => p.marginstart}px;
`

const ClearTestDataButton = styled.div`
    width: 5px;
    height: 5px;
    position: fixed;
    z-index: 100000;
    top: 0px;
    left: 0px;
`
