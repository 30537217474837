import {React} from 'common'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'

const HeaderActions = ({menuId, handleOpenMenu}) => {
    return (
        <Box sx={{display: {xs: 'none', md: 'flex'}}}>
            <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleOpenMenu('profile')}
                color="inherit">
                <AccountCircle />
            </IconButton>
        </Box>
    )
}

export default HeaderActions
