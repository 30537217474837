import Add from '@mui/icons-material/Add'
import {styled} from '@mui/material'
import {Texts} from 'appearance'
import Button from 'components/Control/Button'
import {useI18n} from 'store/Store'

const TableTitle = ({
    tableName,
    itemName,
    handleAddNew,
    allowAdd = true,
    additionalActions,
}) => {
    const {i18n} = useI18n()
    return (
        <Container>
            <TableName>{i18n.t(`tables.${tableName}`)}</TableName>
            <ButtonsContainer>
                {additionalActions?.map((button) => (
                    <Button
                        variant="contained"
                        key={button.label}
                        onClick={button.onClick}
                        startIcon={button.icon}>
                        {button.label}
                    </Button>
                ))}

                {allowAdd && (
                    <AddButton
                        startIcon={<Add />}
                        onClick={() => handleAddNew()}>
                        {i18n.t('tables.addNew', {
                            itemName: i18n.t(`tables.${itemName}`),
                        })}
                    </AddButton>
                )}
            </ButtonsContainer>
        </Container>
    )
}

export default TableTitle

const Container = styled('div')`
    display: flex;
    justify-content: space-between;
    padding: 24px 12px;
`

const ButtonsContainer = styled('div')`
    display: flex;
    gap: 12px;
`

const TableName = styled(Texts.Text)(({theme}) => ({
    fontWeight: 500,
    fontSize: 24,
    color: theme.palette.primary.main,
}))

const AddButton = styled(Button)`
    font-weight: 500;
`
