import baseApi, {
    transformResponseFromData,
    // transformResponseFromMessage,
    provideTags,
    provideTag,
} from 'store/slices/api/baseApi'

const accountsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAccounts: build.query({
            query: ({page, pageSize, search, sort}) => {
                console.log('get accounts query')
                const paginationStr = `page=${page}&pageSize=${pageSize}`
                const searchStr = search ? `&search=${search}` : ''
                const sortStr = sort ? `&sort=${sort}` : ''

                return {
                    url: `accounts?${paginationStr}${searchStr}${sortStr}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTags('Account'),
        }),
        getAccount: build.query({
            query: (accountId) => ({
                url: `accounts/${accountId}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Account', result),
        }),
        createAccount: build.mutation({
            query: ({name}) => {
                console.log('create account mutation req', {name})
                return {
                    url: `accounts`,
                    method: 'POST',
                    body: {name},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTags('Account'),
        }),
        saveAccount: build.mutation({
            query: (account) => {
                console.log('save account mutation req', account)
                return {
                    url: `accounts/${account._id}`,
                    method: 'PUT',
                    body: account,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Account', result),
        }),
        deleteAccount: build.mutation({
            query: (id) => {
                console.log('delete account mutation req', id)
                return {
                    url: `accounts/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Account'),
        }),
        inviteToAccount: build.mutation({
            query: ({accountId, userId, role}) => {
                console.log('invite account mutation req', {
                    accountId,
                    userId,
                    role,
                })
                return {
                    url: `accounts/invite`,
                    method: 'POST',
                    body: {accountId, userId, role},
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Account', result),
        }),
        confirmAccount: build.mutation({
            query: ({accountId}) => {
                console.log('delete account mutation req', accountId)
                return {
                    url: `accounts/confirm/${accountId}`,
                    method: 'POST',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: ['Invites'], //(result) => provideTag('Account', result),
        }),
    }),
    overrideExisting: false,
})

export default accountsApi

export const {
    useGetAccountQuery,
    useGetAccountsQuery,
    useCreateAccountMutation,
    useSaveAccountMutation,
    useDeleteAccountMutation,
    useInviteToAccountMutation,
    useConfirmAccountMutation,
} = accountsApi
