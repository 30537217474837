import RawCard from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {styled} from 'common'

const Card = ({children, ...p}) => {
    return (
        <StyledCard {...p}>
            <CardContent>{children}</CardContent>
        </StyledCard>
    )
}

export default Card

const StyledCard = styled(RawCard)``
