import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {FormContainer, FormRow} from '../Form/Forms'
import FormTextInput from 'components/Control/Form/FormTextInput'
import FormSelect from 'components/Control/Form/FormSelect'
import {yesNoOptions} from 'constants/users'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import Dances from './Dances'
import {danceCollectionTypes} from 'constants/danceCollections'

const DanceCollectionForm = ({
    isNew,
    setInputValue,
    searchValue,
    danceCollectionDances,
    searchResults,
    onSelectValue,
    handleRemoveDance,
    handleMoveDance,
}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const {
        control,
        formState: {errors},
    } = formContext

    return (
        <FormContainer>
            <FormRow>
                <FormTextInput
                    readOnly
                    hidden={isNew}
                    fullWidth
                    name="_id"
                    label={i18n.t(`danceCollections._id`)}
                    control={control}
                    error={errors._id}
                />
                <FormSelect
                    fullWidth
                    itemsName="users"
                    optionsName="yesNo"
                    name="hidden"
                    label={i18n.t(`danceCollections.hidden`)}
                    options={yesNoOptions}
                    control={control}
                    error={errors.hidden}
                />

                <FormDatePicker
                    hidden={isNew}
                    fullWidth
                    name="created"
                    label={i18n.t(`danceCollections.created`)}
                    control={control}
                    error={errors.created}
                />

                <FormSelect
                    fullWidth
                    itemsName="danceCollections"
                    optionsName="types"
                    name="type"
                    label={i18n.t(`danceCollections.type`)}
                    options={danceCollectionTypes}
                    control={control}
                    error={errors.type?.message}
                />
            </FormRow>
            <FormRow>
                <FormTextInput
                    fullWidth
                    name="nameHeb"
                    label={i18n.t(`danceCollections.nameHeb`)}
                    control={control}
                    error={errors.nameHeb?.message}
                />

                <FormTextInput
                    fullWidth
                    name="nameEng"
                    label={i18n.t(`danceCollections.nameEng`)}
                    control={control}
                    error={errors.nameEng?.message}
                />
            </FormRow>
            <Dances
                dances={danceCollectionDances}
                searchResults={searchResults}
                setInputValue={setInputValue}
                searchValue={searchValue}
                onSelectValue={onSelectValue}
                handleRemoveDance={handleRemoveDance}
                handleMoveDance={handleMoveDance}
            />
        </FormContainer>
    )
}

export default DanceCollectionForm
