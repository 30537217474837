import {videoTypes} from 'constants/dances'
import {FormColumn, FormRow} from '../Form/Forms'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {Button, styled, Typography} from '@mui/material'
import {useI18n} from 'store/Store'
import {useFormContext} from 'react-hook-form'
import {inputHeight} from 'appearance/theme'

const DanceVideos = ({onDeleteVideo, videos}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const {control, errors} = formContext

    return (
        <Container>
            {videoTypes.map((videoType) => (
                <div>
                    <Typography variant="subtitle2">
                        {i18n.t(`dances.${videoType}`)}
                    </Typography>
                    <VideoFileContainer>
                        <FormTextInput
                            fullWidth
                            readOnly
                            name={`videos.${videoType}.videoUrl`}
                            label={i18n.t(`dances.videoFile`)}
                        />
                        <Button
                            variant="contained"
                            sx={{height: inputHeight, width: 140}}
                            onClick={() => onDeleteVideo(videoType)}
                            disabled={!videos?.[videoType]?.videoUrl}>
                            {i18n.t('dances.deleteVideoButton')}
                        </Button>
                    </VideoFileContainer>

                    <FormRow>
                        <FormTextInput
                            fullWidth
                            readOnly
                            name={`videos.${videoType}.youtubeId`}
                            label={i18n.t(`dances.youtubeId`)}
                            control={control}
                            error={errors?.[videoType]?.youtubeId}
                        />
                        <FormTextInput
                            fullWidth
                            readOnly
                            name={`videos.${videoType}.youtubeStatus`}
                            label={i18n.t(`dances.youtubeStatus`)}
                            control={control}
                        />
                    </FormRow>
                </div>
            ))}
        </Container>
    )
}

export default DanceVideos

const Container = styled(FormColumn)`
    margin-bottom: 16px;
`

const VideoFileContainer = styled('div')`
    display: flex;
    align-items: start;
    gap: 12px;
    margin-block: 12px;
`
