import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const guidesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getGuides: build.query({
            query: (query) => {
                const url = constructUrl('guides', {
                    ...query,
                    sort: query.sort ?? JSON.stringify({created: 'desc'}),
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Guide'),
        }),
        getGuide: build.query({
            query: (id) => ({
                url: `guides/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Guide', result),
        }),
        createGuide: build.mutation({
            query: (body) => {
                return {
                    url: `guides`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Guide'),
        }),
        saveGuide: build.mutation({
            query: (body) => {
                return {
                    url: `guides/${body._id}`,
                    method: 'PUT',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Guide', result),
        }),
        deleteGuide: build.mutation({
            query: (id) => {
                return {
                    url: `guides/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Guide'),
        }),
    }),
    overrideExisting: false,
})

export default guidesApi

export const {
    useGetGuideQuery,
    useGetGuidesQuery,
    useCreateGuideMutation,
    useSaveGuideMutation,
    useDeleteGuideMutation,
} = guidesApi
