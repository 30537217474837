import {React} from 'common'
import {useParams} from 'react-router-dom'
import {Page} from 'components/Page'
import {useI18n} from 'store/Store'

import {FormProvider} from 'react-hook-form'
import Stats from 'components/Page/Stats/Stats'
import UserForm from 'components/Page/User/UserForm'
import PageContent from 'components/Page/PageContent'
import useUserForm from 'logic/Forms/useUserForm'
import FormTitle from 'components/Page/Form/FormTitle'
import {isNew} from 'services/utils/editForm'
import PurchasedDances from 'components/Page/User/PurchasedDances'

const User = () => {
    const {i18n} = useI18n()
    let {userId} = useParams()

    const {
        form,
        submit,
        guides,
        countries,
        stats,
        searchCountryValue,
        setCountrySearchValue,
        searchGuideValue,
        setSearchGuideValue,
        purchasedDances,
    } = useUserForm({userId})

    return (
        <Page>
            <PageContent>
                <FormTitle
                    itemId={userId}
                    itemLabel={i18n.t(`tables.user`)}
                    submit={submit}
                />

                {!isNew(userId) && <Stats itemsName="users" stats={stats} />}

                <FormProvider {...form}>
                    <UserForm
                        isNew={isNew(userId)}
                        guides={guides?.data}
                        countries={countries?.data}
                        searchCountry={searchCountryValue}
                        setSearchCountry={setCountrySearchValue}
                        searchGuide={searchGuideValue}
                        setSearchGuide={setSearchGuideValue}
                    />
                </FormProvider>

                {!isNew(userId) && (
                    <PurchasedDances purchasedDances={purchasedDances} />
                )}
            </PageContent>
        </Page>
    )
}
export default User
