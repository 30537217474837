import {Controller} from 'react-hook-form'
import TimePicker from '../TimePicker'

const FormTimePicker = ({hidden, name, control, label, error}) => {
    return (
        !hidden && (
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value}}) => (
                    <TimePicker
                        label={label}
                        value={value}
                        onChange={onChange}
                        error={error}
                    />
                )}
            />
        )
    )
}

export default FormTimePicker
