import {isNew} from 'services/utils/editForm'
import {Page, PageContent} from 'components/Page'
import GuideForm from 'components/Page/Guides/GuideForm'
import useGuideForm from 'logic/Forms/useGuideForm'
import {FormProvider} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {useParams} from 'react-router-dom'
import FormTitle from 'components/Page/Form/FormTitle'

const Guide = () => {
    const {i18n} = useI18n()
    const {guideId} = useParams()
    const {
        form,
        submit,
        cities,
        countries,
        searchCityValue,
        searchCountryValue,
        setCityInputValue,
        setCountryInputValue,
    } = useGuideForm(guideId)

    return (
        <Page>
            <PageContent>
                <FormTitle
                    itemId={guideId}
                    itemLabel={i18n.t(`tables.guide`)}
                    submit={submit}
                />
                <FormProvider {...form}>
                    <GuideForm
                        isNew={isNew(guideId)}
                        cities={cities?.data}
                        countries={countries?.data}
                        searchCityValue={searchCityValue}
                        searchCountryValue={searchCountryValue}
                        setCityInputValue={setCityInputValue}
                        setCountryInputValue={setCountryInputValue}
                    />
                </FormProvider>
            </PageContent>
        </Page>
    )
}

export default Guide
