import React from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {OutRoute, ProtectedRoute} from 'navigation/RouteManager'
//screens
import Dashboard from 'screens/Dashboard'
import Login from 'screens/Login'
import Register from 'screens/Register'
import ForgotPassword from 'screens/ForgotPassword'
import ResetPassword from 'screens/ResetPassword'
import Accounts from 'screens/Accounts'
import Account from 'screens/Account'
import Users from 'screens/Users'
import User from 'screens/User'
import {useGetCurrentUserQuery} from 'store/Store'
import MoreData from 'screens/MoreData'
import Guides from 'screens/Guides'
import Guide from 'screens/Guide'
import Artists from 'screens/Artists'
import Artist from 'screens/Artist'
import Dances from 'screens/Dances'
import Dance from 'screens/Dance'
import DanceCollections from 'screens/DanceCollections'
import DanceCollection from 'screens/DanceCollection'
import Sessions from 'screens/Sessions'
import Session from 'screens/Session'
import Messages from 'screens/Messages'
import Payments from 'screens/Payments'
import Publications from 'screens/Publications'
import Publication from 'screens/Publication'
import Banners from 'screens/Banners'
import Banner from 'screens/Banner'
import Newspapers from 'screens/Newspapers'
import Newspaper from 'screens/Newspaper'
import UploadFilmedDates from 'components/Page/Dances/FilmedDates/UploadFilmedDates'

const navigationRouter = (
    <Router>
        <Routes>
            <Route element={<OutRoute />}>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route path="/register/:token" element={<Register />} />
                <Route path="/register" element={<Register />} />
                <Route
                    path="/resetPassword/:token"
                    element={<ResetPassword />}
                />
            </Route>
            <Route element={<ProtectedRoute />}>
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/accounts" element={<Accounts />} />
                <Route path="/accounts/:accountId" element={<Account />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/:userId" element={<User />} />
                <Route path="/guides" element={<Guides />} />
                <Route path="/guides/:guideId" element={<Guide />} />
                <Route path="/artists" element={<Artists />} />
                <Route path="/artists/:artistId" element={<Artist />} />
                <Route path="/dances" element={<Dances />} />
                <Route path="/dances/:danceId" element={<Dance />} />
                <Route path="/upload" element={<UploadFilmedDates />} />
                <Route
                    path="/danceCollections"
                    element={<DanceCollections />}
                />
                <Route
                    path="/danceCollections/:danceCollectionId"
                    element={<DanceCollection />}
                />
                <Route path="/sessions" element={<Sessions />} />
                <Route path="/sessions/:sessionId" element={<Session />} />
                <Route path="/moreData" element={<MoreData />} />
                <Route path="/messages" element={<Messages />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/publications" element={<Publications />} />
                <Route
                    path="/publications/:publicationId"
                    element={<Publication />}
                />
                <Route path="/banners" element={<Banners />} />
                <Route path="/banners/:bannerId" element={<Banner />} />
                <Route path="/newspapers" element={<Newspapers />} />
                <Route
                    path="/newspapers/:newspaperId"
                    element={<Newspaper />}
                />
            </Route>
        </Routes>
    </Router>
)

const NavigationRouter = () => {
    const {data, error} = useGetCurrentUserQuery()
    console.log('navigation router', {data, error})
    return <div>{navigationRouter}</div>
}

export default NavigationRouter

const getRouterProtectedPaths = (router) => {
    const outlets = router.props.children.props.children

    const protectedOutlet = outlets.filter(
        (outlet) => outlet.props.element.type.name === ProtectedRoute.name
    )?.[0]

    return protectedOutlet.props.children.map((route) => route.props.path)
}

export const protectedPaths = getRouterProtectedPaths(navigationRouter)
