import DataTable from 'components/Display/DataTable/DataTable'
import {Page, PageContent} from 'components/Page'
import {
    useDeleteSessionMutation,
    useGetSessionsQuery,
} from 'store/slices/api/sessionsApi'

const Sessions = () => {
    return (
        <Page>
            <PageContent>
                <DataTable
                    allowEdit
                    allowDelete
                    itemName="session"
                    paginationType="external"
                    useGetQuery={useGetSessionsQuery}
                    useDeleteMutation={useDeleteSessionMutation}
                />
            </PageContent>
        </Page>
    )
}

export default Sessions
