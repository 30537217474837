import {React} from 'common'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {useIsRtl} from 'store/Store'
import SideMenuItem from 'components/Page/SideMenu/SideMenuItem'
import SideMenuDrawer from './SideMenu/SideMenuDrawer'
import SideMenuDrawerHeader from './SideMenu/SideMenuDrawerHeader'
import {menuItems} from 'constants/sideMenu'

const SideMenu = ({open, toggleDrawer, messagesCount}) => {
    const isRtl = useIsRtl()

    return (
        <SideMenuDrawer variant="permanent" open={open}>
            <SideMenuDrawerHeader>
                <IconButton onClick={toggleDrawer}>
                    {isRtl ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </SideMenuDrawerHeader>
            <List>
                {menuItems.map((text, index) => (
                    <SideMenuItem
                        messagesCount={messagesCount}
                        key={text}
                        open={open}
                        text={text}
                        index={index}
                    />
                ))}
            </List>
        </SideMenuDrawer>
    )
}

export default SideMenu
