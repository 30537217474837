import {isNew} from 'services/utils/editForm'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {
    useCreateBannerMutation,
    useGetBannerQuery,
    useSaveBannerMutation,
} from 'store/slices/api/bannersApi'
import {useAlert} from 'components/Display/Alert/AlertContext'
import {useI18n} from 'store/Store'
import {useUploadImageMutation} from 'store/slices/api/uploadsApi'
import {urls} from 'services/utils/urls'
import {useNavigate} from 'react-router-dom'

const useBannerForm = (bannerId) => {
    const form = useForm()
    const {handleSubmit} = form
    const {showAlert} = useAlert()
    const {i18n} = useI18n()
    const navigate = useNavigate()

    const [formError, setFormError] = useState()

    const [doUploadImage] = useUploadImageMutation()

    const [doCreateBanner] = useCreateBannerMutation()
    const [doSaveBanner] = useSaveBannerMutation()
    const {data: banner} = useGetBannerQuery(bannerId, {skip: isNew(bannerId)})

    const formValues = form.watch()

    useEffect(() => {
        if (banner) {
            const initialValues = {
                ...banner,
                bannerFile:
                    banner.hasPicture &&
                    `${urls.base}/${urls.banners_pictures}/bp${banner._id}`,
            }
            form.reset(initialValues)
        }
    }, [form, banner])

    const onSubmit = async (values) => {
        let response
        if (values._id) {
            response = await doSaveBanner(values)
        } else {
            response = await doCreateBanner(values)
        }
        if (response?.data?._id) {
            const message = i18n.t('form.savedSuccessfully', {
                itemName: i18n.t('tables.banner'),
            })
            navigate(`/banners/${response.data._id}`)
            showAlert(message)
        }
        if (response.error) {
            setFormError(response.error.data.error)
        }
    }

    const submit = handleSubmit(onSubmit)

    const uploadBanner = async () => {
        const folderName = urls.banners_pictures

        const formData = new FormData()
        formData.append('files', formValues.imgUrl)
        formData.append('folderName', folderName)

        const response = await doUploadImage({
            formData,
            folderName,
        })

        const link = response?.data?.files?.[0].link

        if (link) {
            const url = link.split('/')[2]
            form.setValue('imgUrl', url)
        }
    }

    return {
        form,
        submit,
        formError,
        uploadBanner,
    }
}

export default useBannerForm
