import {styled} from '@mui/material'
import {useI18n} from 'store/Store'
import {getAvailableLanaguages} from 'localization/i18n'
import FormSelect from 'components/Control/Form/FormSelect'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import Button from 'components/Control/Button'
import useDownloadFilmedDates from 'logic/Reports/useDownloadFilmedDates'

const FilmedDates = () => {
    const {i18n} = useI18n()

    const {getDownloadUrl, filmedDates, control, errors, formValues} =
        useDownloadFilmedDates()

    return (
        <Container>
            <FormContainer>
                <FormDatePicker
                    fullWidth
                    name="filmedDate"
                    label={i18n.t(`dashboard.filmedDate`)}
                    control={control}
                    error={errors.filmedDate}
                    valueFormat="YYYY-MM-DD"
                    allowedDates={filmedDates}
                />

                <FormSelect
                    fullWidth
                    itemsName="dashboard"
                    optionsName="languages"
                    name="language"
                    label={i18n.t(`dashboard.language`)}
                    options={getAvailableLanaguages()}
                    control={control}
                    error={errors.language}
                />
            </FormContainer>
            <Button
                variant="contained"
                href={getDownloadUrl()}
                disabled={!formValues.filmedDate}
                style={{alignSelf: 'center'}}>
                {i18n.t('dashboard.download')}
            </Button>
        </Container>
    )
}

export default FilmedDates

const Container = styled('div')`
    display: flex;
    flex-direction: column;
`

const FormContainer = styled('div')`
    display: flex;
    gap: 36px;
    padding: 48px 24px;
`
