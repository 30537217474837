import {isNew} from 'services/utils/editForm'
import {Page, PageContent} from 'components/Page'
import {FormProvider} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {useParams} from 'react-router-dom'
import FormTitle from 'components/Page/Form/FormTitle'
import usePublicationForm from 'logic/Forms/usePublicationForm'
import PublicationForm from 'components/Page/Publication/PublicationForm'

const Publication = () => {
    const {i18n} = useI18n()
    const {publicationId} = useParams()
    const {
        form,
        submit,
        publication,
        guides,
        guideSearchValue,
        setGuideSearchValue,
        uploadFlyer,
    } = usePublicationForm(publicationId)

    return (
        <Page>
            <PageContent>
                <FormTitle
                    itemId={publicationId}
                    itemLabel={i18n.t(`tables.publication`)}
                    submit={submit}
                />
                <FormProvider {...form}>
                    <PublicationForm
                        publication={publication}
                        isNew={isNew(publicationId)}
                        guidesResults={guides}
                        searchGuideValue={guideSearchValue}
                        setSearchGuideValue={setGuideSearchValue}
                        uploadFlyer={uploadFlyer}
                    />
                </FormProvider>
            </PageContent>
        </Page>
    )
}

export default Publication
