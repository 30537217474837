import FormSelect from 'components/Control/Form/FormSelect'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {yesNoOptions} from 'constants/users'
import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {FormColumn, FormRow, ModalFormContainer} from '../Form/Forms'

const ItemForm = ({itemName, isNew = true}) => {
    const {i18n} = useI18n()

    const formContext = useFormContext()
    const {
        control,
        formState: {errors},
    } = formContext

    return (
        <ModalFormContainer>
            <FormColumn>
                {!isNew && (
                    <FormTextInput
                        fullWidth
                        name="_id"
                        label={i18n.t(`moreData._id`)}
                        control={control}
                        error={errors._id}
                    />
                )}
                <FormRow>
                    <FormTextInput
                        fullWidth
                        name="nameEng"
                        label={i18n.t(`moreData.nameEng`)}
                        control={control}
                        error={errors.nameEng?.message}
                    />
                    <FormTextInput
                        fullWidth
                        name="nameHeb"
                        label={i18n.t(`moreData.nameHeb`)}
                        control={control}
                        error={errors.nameHeb?.message}
                    />
                </FormRow>

                {itemName === 'citie' && (
                    <FormSelect
                        fullWidth
                        itemsName="users"
                        optionsName="yesNo"
                        name="inIsrael"
                        label={i18n.t(`users.inIsrael`)}
                        options={yesNoOptions}
                        control={control}
                        error={errors.clubType}
                    />
                )}
            </FormColumn>
        </ModalFormContainer>
    )
}

export default ItemForm
