import {MaterialReactTable, useMaterialReactTable} from 'material-react-table'
import Actions from './Actions'
import {useI18n, useIsRtl} from 'store/Store'
import {MRT_Localization_HE} from 'material-react-table/locales/he'
import dayjs from 'services/utils/dayjs'
import {PAGE_SIZES} from 'constants/tables'

const TableComponent = ({
    columns,
    data,
    allowEdit,
    allowDelete,
    doDelete,
    editOrCreateItem,
    totalItems,
    isLoading,
    paginationType,
    allowView,
    onViewItem,
    pagination,
    setPagination,
    setColumnFilters,
    setSorting,
    columnFilters,
    sorting,
    tableSearch,
    setTableSearch,
    setColumnVisibility,
    columnVisibility = {},
    additionalActions,
}) => {
    const {i18n} = useI18n()
    const isRtl = useIsRtl()

    const otherElementsHeight = 382

    const table = useMaterialReactTable({
        columns,
        data,
        layoutMode: 'semantic',
        localization: isRtl && MRT_Localization_HE,
        getRowId: (row) => row._id,
        enableColumnResizing: true,
        enableColumnActions: true,
        enableRowActions: allowEdit || allowDelete || allowView,
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: i18n.t('tables.actions'),
                size: 70,
            },
        },
        enableStickyHeader: true,
        enableStickyFooter: true,
        positionActionsColumn: 'last',
        renderRowActions: ({row}) => (
            <Actions
                rowId={row.id}
                allowEdit={allowEdit}
                allowDelete={allowDelete}
                allowView={allowView}
                handleDelete={doDelete}
                handleEdit={editOrCreateItem}
                handleView={onViewItem}
                additionalActions={additionalActions}
            />
        ),
        rowCount: totalItems || 0,
        isLoading,
        initialState: {
            density: 'compact',
            columnPinning: {right: ['mrt-row-actions']},
            showGlobalFilter: true,
        },
        muiTableContainerProps: {
            sx: {
                maxHeight: `calc(100vh - ${otherElementsHeight}px)`,
            },
        },
        muiTablePaperProps: {
            variant: 'outlined',
            elevation: 0,
            sx: {
                maxHeight: '85%',
            },
        },
        muiBottomToolbarProps: {
            sx: {
                minHeight: '56px',
            },
        },
        muiTableHeadCellProps: {
            align: 'center',
            sx: {
                background: '#F5F5F5',
            },
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
        muiTopToolbarProps: {
            sx: {background: '#F5F5F5'},
        },
        muiCopyButtonProps: {
            sx: {
                fontWeight: 'normal',
            },
        },
        muiFilterDatePickerProps: ({column}) => ({
            value:
                column.getFilterValue() && dayjs.utc(column.getFilterValue()),
            format: 'DD/MM/YYYY',
        }),
        manualPagination: paginationType === 'external',
        onPaginationChange: setPagination,
        muiPaginationProps: {
            rowsPerPageOptions: PAGE_SIZES,
        },
        manualFiltering: true,
        onGlobalFilterChange: setTableSearch,
        onColumnFiltersChange: setColumnFilters,
        enableFilterMatchHighlighting: false,
        manualSorting: true,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        state: {
            pagination,
            sorting,
            globalFilter: tableSearch,
            columnFilters,
            columnVisibility,
        },
    })

    return <MaterialReactTable table={table} />
}

export default TableComponent
