import {Tab, Tabs} from '@mui/material'
import {useState} from 'react'
import {useI18n} from 'store/Store'
import TabContent from './TabContent'
import FilmedDates from './FilmedDates'
import DownloadsReport from './DownloadsReport'

const DownloadsTabs = () => {
    const {i18n} = useI18n()

    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    return (
        <>
            <Tabs value={value} onChange={handleChange} aria-label="reports">
                <Tab
                    disableRipple
                    label={i18n.t('dashboard.downloadsReport')}
                    {...a11yProps(0)}
                />
                <Tab
                    disableRipple
                    label={i18n.t('dashboard.filmedDateDownload')}
                    {...a11yProps(1)}
                />
            </Tabs>
            <TabContent value={value} index={0}>
                <DownloadsReport />
            </TabContent>
            <TabContent value={value} index={1}>
                <FilmedDates />
            </TabContent>
        </>
    )
}

export default DownloadsTabs
