import {isNew} from 'services/utils/editForm'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {
    useCreateDanceCollectionMutation,
    useGetDanceCollectionQuery,
    useSaveDanceCollectionMutation,
} from 'store/slices/api/danceCollectionsApi'
import {useGetDancesQuery} from 'store/slices/api/dancesApi'
import {useAlert} from 'components/Display/Alert/AlertContext'
import {yupResolver} from '@hookform/resolvers/yup'
import {danceCollectionSchema} from 'services/validations/schemas/danceCollection'
import {useNavigate} from 'react-router-dom'

const useDanceCollectionForm = ({danceCollectionId}) => {
    const {i18n} = useI18n()
    const {showAlert} = useAlert()
    const navigate = useNavigate()

    const [formError, setFormError] = useState()
    const [searchDanceInputValue, setSearchDanceInputValue] = useState()

    const {data: danceCollection} = useGetDanceCollectionQuery(
        danceCollectionId,
        {skip: isNew(danceCollectionId)}
    )

    const [doCreateDanceCollection] = useCreateDanceCollectionMutation()
    const [doSaveDanceCollection] = useSaveDanceCollectionMutation()

    const {data: dances} = useGetDancesQuery(
        {search: searchDanceInputValue},
        {skip: !searchDanceInputValue}
    )

    const form = useForm({
        defaultValues: danceCollection,
        mode: 'onSubmit',
        resolver: yupResolver(danceCollectionSchema),
    })
    const {handleSubmit} = form

    const dancesValues = form.watch('dances')

    useEffect(() => {
        if (danceCollection) {
            form.reset(danceCollection)
        }
    }, [danceCollection, form])

    const onSubmit = async (values) => {
        let response

        const body = {
            ...values,
            dances: values.dances?.map((dance) => dance._id),
        }

        if (values._id) {
            response = await doSaveDanceCollection(body)
        } else {
            response = await doCreateDanceCollection(body)
        }

        if (response?.data?._id) {
            const message = i18n.t('form.savedSuccessfully', {
                itemName: i18n.t('tables.danceCollection'),
            })
            navigate(`/danceCollections/${response.data._id}`)
            showAlert(message)
        }
        if (response?.error) {
            setFormError(response.error.data.error)
        }
    }

    const submit = handleSubmit(onSubmit)

    const handleRemoveDance = (index) => {
        const updatedDances = [...dancesValues]
        updatedDances.splice(index, 1)
        form.setValue('dances', updatedDances)
    }

    const handleMoveDance = (index, newIndex) => {
        if (newIndex < 0 || newIndex >= dancesValues.length) return

        const updatedDances = [...dancesValues]
        const dance = dancesValues[index]
        updatedDances.splice(index, 1)
        updatedDances.splice(newIndex, 0, dance)
        form.setValue('dances', updatedDances)
    }

    const handleAddDance = (dance) => {
        const value = [...(dancesValues || []), dance]
        form.setValue('dances', value)
    }

    return {
        form,
        submit,
        formError,
        dancesResults: dances,
        danceCollectionDances: danceCollection?.dances,
        setSearchDanceInputValue,
        searchValue: searchDanceInputValue,
        handleRemoveDance,
        handleMoveDance,
        handleAddDance,
    }
}

export default useDanceCollectionForm
