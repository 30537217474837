import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const purchasesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getDownlodsReport: build.query({
            query: (body) => {
                const url = constructUrl('purchases/downloadsReport', body)
                return {
                    url,
                    method: 'GET',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Purchase'),
        }),
        getUserPurchases: build.query({
            query: (userId) => {
                return {
                    url: `purchases/${userId}`,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Purchase'),
        }),
    }),
    overrideExisting: false,
})

export default purchasesApi

export const {useGetDownlodsReportQuery, useGetUserPurchasesQuery} =
    purchasesApi
