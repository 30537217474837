import {getFormError, isNew} from 'services/utils/editForm'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {
    useCreateDanceMutation,
    useDeleteVideoMutation,
    useSaveDanceMutation,
} from 'store/slices/api/dancesApi'
import {useGetGuidesQuery} from 'store/slices/api/guidesApi'
import {useNavigate} from 'react-router-dom'
import {useGetArtistsQuery} from 'store/slices/api/artistsApi'
import {Video} from '@styled-icons/fluentui-system-regular/Video'
import {MusicNote1} from '@styled-icons/fluentui-system-regular/MusicNote1'
import {ArrowDownload} from '@styled-icons/fluentui-system-filled/ArrowDownload'
import {formatItemName} from 'services/utils/data'
import {useI18n} from 'store/Store'
import Add from '@mui/icons-material/Add'
import {UploadCloud2} from '@styled-icons/remix-line/UploadCloud2'
import {defaultValues} from 'constants/forms'
import {useAlert} from 'components/Display/Alert/AlertContext'
import {videoTypes} from 'constants/dances'
import {yupResolver} from '@hookform/resolvers/yup'
import {danceSchema} from 'services/validations/schemas/dance'

const useDanceForm = ({danceId, dance}) => {
    const navigate = useNavigate()
    const {i18n} = useI18n()
    const searchPageSize = 50

    const {showAlert} = useAlert()
    const [activeInput, setActiveInput] = useState()
    const [searchChoreographer, setSearchChoreographerInputValue] = useState()
    const [searchArtist, setSearchArtistInputValue] = useState()
    const [uploadModalOpen, setUploadModalOpen] = useState(false)

    const [formError, setFormError] = useState()

    const [doCreateDance] = useCreateDanceMutation()
    const [doSaveDance] = useSaveDanceMutation()
    const [doDeleteVideo] = useDeleteVideoMutation()

    const form = useForm({mode: 'onSubmit', resolver: yupResolver(danceSchema)})

    const {handleSubmit} = form

    const handleDeleteVideo = async (videoType) => {
        const videoData = {danceId, videoType}
        await doDeleteVideo(videoData)

        window.location.reload()
    }

    useEffect(() => {
        if (!isNew(danceId) && dance) {
            form.reset(dance)
        } else if (isNew(danceId)) {
            form.reset(defaultValues.dance)
        }
    }, [form, dance, danceId])

    const {data: choreographers, isFetching: isGuidesLoading} =
        useGetGuidesQuery(
            {search: searchChoreographer, pageSize: searchPageSize},
            {skip: !searchChoreographer}
        )
    const {data: artists, isFetching: isArtistsLoading} = useGetArtistsQuery(
        {search: searchArtist, pageSize: searchPageSize},
        {skip: !searchArtist}
    )

    const formatVideos = (videos) => {
        const formattedVideos = {}

        Object.keys(videos).forEach((videoType) => {
            const videoData = {...videos[videoType]}

            if (videoData.youtubeStatus === null || !videoData.youtubeStatus) {
                delete videoData.youtubeStatus
            }
            if (videoData.youtubeId === null) {
                delete videoData.youtubeId
            }

            formattedVideos[videoType] = videoData
        })

        return formattedVideos
    }

    const onSubmit = async (values) => {
        let response

        const choreographersIds = values.choreographers?.map((item) => item._id)
        const composersIds = values.composers?.map((item) => item._id)
        const lyricistsIds = values.lyricists?.map((item) => item._id)
        const companiesIds = values.companies?.map((item) => item._id)
        const performersIds = values.performers?.map((item) => item._id)

        const body = {
            ...values,
            choreographers: choreographersIds,
            composers: composersIds,
            lyricists: lyricistsIds,
            companies: companiesIds,
            performers: performersIds,
            videos: formatVideos(values.videos),
        }
        if (values._id) {
            response = await doSaveDance(body)
        } else {
            response = await doCreateDance(body)
        }

        if (response.error) {
            const errorMessage = getFormError(
                response.error?.data?.message,
                `${i18n.t('dances.nameEng')} / ${i18n.t(`dances.nameHeb`)}`,
                i18n
            )

            showAlert(errorMessage, 'error')
        }

        if (response?.data?._id) {
            const message = i18n.t('form.savedSuccessfully', {
                itemName: i18n.t('tables.dance'),
            })
            navigate(`/dances/${response.data._id}`)
            showAlert(message)
        }
        if (response.error) {
            setFormError(response.error.data.error)
        }
    }

    const submit = handleSubmit(onSubmit)

    const viewsStats = videoTypes.map((type) => ({
        key: `${type}Views`,
        value: dance?.videos?.[type]?.views || 0,
        icon: Video,
    }))

    const stats = [
        ...viewsStats,
        {key: 'audioViews', value: dance?.audioViews, icon: MusicNote1},
        {key: 'downloads', value: dance?.downloads, icon: ArrowDownload},
    ]

    const handleSearchArtist = (value, name) => {
        setActiveInput(name)
        setSearchArtistInputValue(value)
    }

    const danceName = formatItemName(dance).length
        ? formatItemName(dance)
        : null

    const handleNewDance = () => {
        // TODO: fix reset form not working
        form.reset(defaultValues.dance)
        navigate(`/dances/new`)
    }

    const toggleUploadModal = () => {
        setUploadModalOpen(!uploadModalOpen)
    }

    const headerButtons = [
        {
            key: 'upload',
            onClick: toggleUploadModal,
            label: i18n.t('dances.uploadMedia'),
            icon: <UploadCloud2 size={20} />,
            variant: 'outlined',
            disabled: isNew(danceId),
        },
        {
            key: 'new',
            onClick: handleNewDance,
            label: i18n.t('dances.newDance'),
            icon: <Add />,
            variant: 'outlined',
        },
    ]

    return {
        form,
        submit,
        formError,
        choreographers,
        searchChorecographerValue: searchChoreographer,
        setSearchChoreographer: setSearchChoreographerInputValue,
        artists,
        searchArtistValue: searchArtist,
        setSearchArtist: handleSearchArtist,
        stats,
        danceName,
        activeInput,
        headerButtons,
        uploadModalOpen,
        toggleUploadModal,
        videos: dance?.videos,
        handleDeleteVideo,
        isGuidesLoading,
        isArtistsLoading,
    }
}

export default useDanceForm
