import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const bannersApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getBanners: build.query({
            query: (query) => {
                const url = constructUrl('banners', {
                    ...query,
                    sort: query.sort ?? JSON.stringify({created: 'desc'}),
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('Banner'),
        }),
        getBanner: build.query({
            query: (id) => ({
                url: `banners/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('Banner', result),
        }),
        createBanner: build.mutation({
            query: (body) => {
                return {
                    url: `banners`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Banner'),
        }),
        saveBanner: build.mutation({
            query: (body) => {
                return {
                    url: `banners/${body._id}`,
                    method: 'PUT',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('Banner', result),
        }),
        deleteBanner: build.mutation({
            query: (id) => {
                return {
                    url: `banners/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('Banner'),
        }),
    }),
    overrideExisting: false,
})

export default bannersApi

export const {
    useGetBannersQuery,
    useGetBannerQuery,
    useCreateBannerMutation,
    useSaveBannerMutation,
    useDeleteBannerMutation,
} = bannersApi
