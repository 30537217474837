import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'

// import {composeWithDevTools} from 'redux-devtools-extension'

import {createLogger} from 'redux-logger'

import baseApi from './slices/api/baseApi'
import app from './slices/app'

import rtkQueryErrorHandler from './middleware/rtkQueryErrorHandler'

const dummyMiddleware = (api) => (next) => (action) => next(action)

// create and filter specific actions
const logger = createLogger({
    predicate: (getState, action) => action.type !== 'SET_PROCESSING',
})

const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
        app,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(process.env.NODE_ENV === 'test' ? dummyMiddleware : logger)
            .concat(baseApi.middleware)
            .concat(rtkQueryErrorHandler),
    // devTools: process.env.NODE_ENV !== 'production',
})

setupListeners(store.dispatch)

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export const RootState = typeof store.getState
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export const AppDispatch = typeof store.dispatch

export * from './slices/api/authApi'
export * from './slices/api/accountsApi'
export * from './slices/api/usersApi'
export * from './slices/api/invitesApi'
export * from './slices/app'

export default store
