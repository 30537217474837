import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {FormColumn, FormContainer, FormRow} from '../Form/Forms'
import FormTextInput from 'components/Control/Form/FormTextInput'
import FormSelect from 'components/Control/Form/FormSelect'
import {yesNoOptions} from 'constants/users'
import FormAutocomplete from 'components/Control/Form/FormAutocomplete'
import {formatItemName, formatUser} from 'services/utils/data'
import {types} from 'constants/dances'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import FormDateTimePicker from 'components/Control/Form/FormDateTimePicker'
import TimeTable from './TimeTable'
import FormChipAutocomplete from 'components/Control/Form/FormChipAutocomplete'
import {days, occurrences} from 'constants/sessions'
import FormAddressInput from 'components/Control/Form/FormAddressInput'
import {getLocale} from 'localization/i18n'
import FormUpload from 'components/Control/Form/FormUpload'
import LearnedDances from './LearnedDances'

const SessionForm = ({
    isNew,
    session,
    guidesResults,
    searchGuideValue,
    setSearchGuideValue,
    countriesResults,
    searchCountryValue,
    setSearchCountryValue,
    citiesResults,
    searchCityValue,
    setSearchCityValue,
    handleSetValue,
    imageUrl,
    uploadImage,
}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const locale = getLocale()

    const {
        control,
        formState: {errors},
    } = formContext

    const occurrenceValue = formContext.watch('occurrences')
    const addressInputName =
        locale === 'he' ? 'formattedAddressHeb' : 'formattedAddressEng'

    return (
        <FormContainer>
            <FormRow>
                <FormColumn>
                    <FormTextInput
                        readOnly
                        hidden={isNew}
                        name="_id"
                        label={i18n.t(`sessions._id`)}
                        control={control}
                        error={errors._id?.message}
                    />

                    <FormChipAutocomplete
                        name="guides"
                        label={i18n.t(`sessions.guides`)}
                        options={guidesResults?.data}
                        control={control}
                        error={errors.guides?.message}
                        getOptionLabel={formatUser}
                        inputValue={searchGuideValue}
                        setInputValue={setSearchGuideValue}
                    />

                    <FormTextInput
                        name="description"
                        label={i18n.t(`sessions.description`)}
                        control={control}
                        error={errors.description?.message}
                    />

                    <FormTextInput
                        name="price"
                        label={i18n.t(`sessions.price`)}
                        control={control}
                        error={errors.price?.message}
                    />

                    <FormSelect
                        itemsName="dances"
                        optionsName="types"
                        name="type"
                        label={i18n.t(`sessions.type`)}
                        options={types}
                        control={control}
                        error={errors.type?.message}
                    />

                    <FormRow>
                        <FormAutocomplete
                            name="country"
                            label={i18n.t(`users.country`)}
                            control={control}
                            error={errors.country?.message}
                            options={countriesResults?.data}
                            getOptionLabel={formatItemName}
                            inputValue={searchCountryValue}
                            onChangeInputValue={setSearchCountryValue}
                        />

                        <FormAutocomplete
                            name="city"
                            label={i18n.t(`users.city`)}
                            control={control}
                            error={errors.city?.message}
                            options={citiesResults?.data}
                            getOptionLabel={formatItemName}
                            inputValue={searchCityValue}
                            onChangeInputValue={setSearchCityValue}
                        />
                    </FormRow>

                    <FormRow>
                        <FormTextInput
                            name="placeName"
                            label={i18n.t(`sessions.placeName`)}
                            control={control}
                            error={errors.placeName?.message}
                        />

                        <FormAddressInput
                            name={`googleLocation.${addressInputName}`}
                            label={i18n.t(`sessions.address`)}
                            control={control}
                            setGoogleLocation={handleSetValue}
                            error={errors.address?.message}
                        />
                    </FormRow>
                </FormColumn>

                <FormColumn>
                    <FormRow>
                        <FormSelect
                            itemsName="users"
                            optionsName="yesNo"
                            name="isActive"
                            label={i18n.t(`sessions.isActive`)}
                            options={yesNoOptions}
                            control={control}
                            error={errors.isActive?.message}
                        />
                        <FormSelect
                            itemsName="users"
                            optionsName="yesNo"
                            name="highlight"
                            label={i18n.t(`sessions.highlight`)}
                            options={yesNoOptions}
                            control={control}
                            error={errors.highlight?.message}
                        />
                    </FormRow>

                    <FormSelect
                        itemsName="users"
                        optionsName="yesNo"
                        name="isWeekly"
                        label={i18n.t(`sessions.isWeekly`)}
                        options={yesNoOptions}
                        control={control}
                        error={errors.isWeekly?.message}
                    />

                    <FormSelect
                        itemsName="users"
                        optionsName="yesNo"
                        name="airConditioner"
                        label={i18n.t(`sessions.airConditioner`)}
                        options={yesNoOptions}
                        control={control}
                        error={errors.airConditioner?.message}
                    />

                    <FormRow>
                        <FormSelect
                            name="occurrences"
                            itemsName="sessions"
                            optionsName="occurrences"
                            label={i18n.t(`sessions.occurrence`)}
                            options={occurrences}
                            control={control}
                            error={errors.occurrences?.message}
                        />

                        {(occurrenceValue === 'WEEKLY' ||
                            occurrenceValue === 'MONTHLY') && (
                            <FormSelect
                                itemsName="sessions"
                                optionsName="days"
                                name="day"
                                label={i18n.t(`sessions.day`)}
                                options={days}
                                control={control}
                                error={errors.day?.message}
                            />
                        )}

                        {occurrenceValue === 'MONTHLY' && (
                            <FormSelect
                                name="week"
                                label={i18n.t(`sessions.week`)}
                                control={control}
                                error={errors.week?.message}
                                options={Array.from({length: 4}, (_, index) =>
                                    (index + 1).toString()
                                )}
                            />
                        )}
                    </FormRow>

                    {occurrenceValue === 'ONCE' && (
                        <FormRow>
                            <FormDateTimePicker
                                name="startDateAndTime"
                                label={i18n.t(`sessions.startDateAndTime`)}
                                control={control}
                                error={errors.startDateAndTime?.message}
                            />

                            <FormDateTimePicker
                                name="endDateAndTime"
                                label={i18n.t(`sessions.endDateAndTime`)}
                                control={control}
                                error={errors.endDateAndTime?.message}
                            />
                        </FormRow>
                    )}
                    <FormDatePicker
                        name="startDateBeginners"
                        label={i18n.t(`sessions.startDateBeginners`)}
                        control={control}
                        error={errors.startDateBeginners?.message}
                    />
                </FormColumn>
            </FormRow>

            <TimeTable data={session?.timeTable} />

            <FormUpload
                label={i18n.t('sessions.picture')}
                name="imageUrl"
                control={control}
                onUpload={uploadImage}
                url={imageUrl}
            />

            <LearnedDances learnedDances={session?.learnedDances} />
        </FormContainer>
    )
}

export default SessionForm
