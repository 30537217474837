import {Controller} from 'react-hook-form'
import UploadComponent from '../Upload/UploadComponent'

const FormUpload = ({
    name,
    control,
    label,
    accept,
    onUpload,
    url,
    showImage,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}}) => (
                <UploadComponent
                    label={label}
                    onChange={onChange}
                    value={value}
                    accept={accept}
                    onUpload={onUpload}
                    url={url}
                    showImage={showImage}
                />
            )}
        />
    )
}

export default FormUpload
