import {List, ListItem, ListItemText, styled} from '@mui/material'
import {Texts} from 'appearance'
import {useI18n} from 'store/Store'

const LearnedDances = ({learnedDances}) => {
    const {i18n} = useI18n()

    console.log({learnedDances})

    return (
        <Container>
            <Title>{i18n.t('sessions.learnedDances')}</Title>
            <List>
                {learnedDances?.map((dance) => (
                    <ListItem disableGutters key={dance._id}>
                        <ListItemText primary={dance.nameHeb} />
                    </ListItem>
                ))}
            </List>
        </Container>
    )
}

export default LearnedDances

const Container = styled('div')`
    padding: 12px;
    display: flex;
    flex-direction: column;
`

const Title = styled(Texts.Text)(({theme}) => ({
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.label,
}))
