import {React} from 'common'
import {useI18n, useLogoutMutation} from 'store/Store'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import {styled} from '@mui/material'
import {Texts} from 'appearance'

const MainMenu = ({
    menuId,
    anchorEl,
    isMenuOpen,
    handleCloseMenu,
    loggedInEmail,
}) => {
    const {i18n} = useI18n()
    const [doLogout] = useLogoutMutation()

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen('profile')}
            onClose={handleCloseMenu('profile')}>
            <StyledMenuItem>
                <LoggedInAs variant="caption">
                    {i18n.t('profileMenu.loggedInAs')}
                </LoggedInAs>
                <UserEmail variant="subtitle2">{loggedInEmail}</UserEmail>
            </StyledMenuItem>
            <MenuItem onClick={doLogout}>
                {i18n.t('profileMenu.logout')}
            </MenuItem>
        </Menu>
    )
}

export default MainMenu

const StyledMenuItem = styled(MenuItem)`
    display: flex;
    flex-direction: column;
    align-items: start;
`

const LoggedInAs = styled(Texts.Text)``

const UserEmail = styled(Texts.Text)``
