import {shapes, types} from 'constants/dances'
import {levels, occurrences} from 'constants/sessions'
import {object, string, date, mixed, array, lazy} from 'yup'

const isEndAfterStart = (start, end) => {
    if (!start || !end) return false
    const startDate = new Date(start)
    const endDate = new Date(end)
    return endDate.getTime() < startDate.getTime()
}

export const timeTableSchema = object({
    timeStart: lazy((values, {parent}) => {
        const {timeStart, timeEnd} = parent
        if (!parent || !timeStart) return string().required('required')

        return isEndAfterStart(timeStart, timeEnd)
            ? mixed().typeError('startTimeAfterEnd')
            : mixed()
    }),
    timeEnd: lazy((values, {parent}) => {
        const {timeStart, timeEnd} = parent
        if (!parent || !timeEnd) return string().required('required')

        return isEndAfterStart(timeStart, timeEnd)
            ? mixed().typeError('endTimeBeforeStart')
            : mixed()
    }),
    shape: mixed().oneOf(shapes).required('required'),
    level: mixed().oneOf(levels).required('required'),
})

export const sessionSchema = object({
    description: string(),
    googleLocation: object({
        googlePlaceId: string(),
        lat: string(),
        lng: string(),
    }),
    day: lazy((values, {parent}) => {
        if (
            parent?.occurrences === 'MONTHLY' ||
            parent?.occurrences === 'WEEKLY'
        ) {
            return string().required('required')
        }
        return mixed()
    }),
    timeTable: array().of(timeTableSchema).required('required'),
    startDateAndTime: lazy((values, {parent}) => {
        if (parent?.occurrences === 'ONCE') {
            return date().typeError('invalidDate').required('required')
        }
        return mixed()
    }),
    endDateAndTime: lazy((values, {parent}) => {
        if (parent?.occurrences === 'ONCE') {
            return date().typeError('invalidDate').required('required')
        }
        return mixed()
    }),
    startDateBeginners: date().typeError('invalidDate'),
    occurrences: mixed().oneOf(occurrences).required('required'),
    placeName: string().required('required'),
    week: lazy((values, {parent}) => {
        if (parent?.occurrences === 'MONTHLY') {
            return string().required('required')
        }
        return mixed()
    }),
    type: mixed().oneOf(types).required('required'),
    city: object({_id: string()}).required('required'),
    country: object({_id: string()}).required('required'),
    guides: array().required('required'),
})
