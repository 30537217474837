import {
    Select as RawSelect,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
} from '@mui/material'
import {InputContainer} from 'components/Page/Form/Forms'
import {useI18n, useIsRtl} from 'store/Store'

const Select = ({
    name,
    label,
    value,
    onChange,
    required,
    disabled,
    readOnly,
    error,
    options,
    getOptionLabel,
    itemsName,
    optionsName,
    fullWidth = true,
    ...p
}) => {
    const {i18n} = useI18n()
    const isRtl = useIsRtl()

    const formatOptionLabel = (option) => {
        if (getOptionLabel) return getOptionLabel(option)

        return itemsName && optionsName
            ? i18n.t(`${itemsName}.${optionsName}.${option}`)
            : option
    }

    return (
        <InputContainer>
            <FormControl fullWidth={fullWidth}>
                <InputLabel shrink error={!!error}>
                    {label}
                </InputLabel>
                <RawSelect
                    notched
                    fullWidth
                    value={value || ''}
                    onChange={onChange}
                    label={label}
                    required={required}
                    disabled={disabled}
                    inputProps={{readOnly}}
                    error={!!error}
                    {...p}>
                    {options?.map((option, index) => (
                        <MenuItem
                            key={option?._id || index}
                            value={option}
                            dir={isRtl ? 'rtl' : 'ltr'}>
                            {formatOptionLabel(option)}
                        </MenuItem>
                    ))}
                </RawSelect>
                {!!error && (
                    <FormHelperText error={!!error}>
                        {i18n.t(`formErrors.${error}`)}
                    </FormHelperText>
                )}
            </FormControl>
        </InputContainer>
    )
}

export default Select
