import {IconButton, styled, useTheme} from '@mui/material'
import {Edit} from '@styled-icons/fluentui-system-regular/Edit'
import {Delete} from '@styled-icons/fluentui-system-regular/Delete'
import {Eye} from '@styled-icons/ionicons-outline/Eye'

const Actions = ({
    rowId,
    handleEdit,
    handleDelete,
    handleView,
    allowEdit,
    allowDelete,
    allowView,
    additionalActions,
}) => {
    const theme = useTheme()

    return (
        <Container>
            {additionalActions?.map((action, index) => {
                const ActionIcon = action.icon
                return (
                    <IconButton
                        key={index}
                        onClick={() => action.onClick(rowId)}
                        size="small">
                        <ActionIcon size={20} color={theme.palette.grey.main} />
                    </IconButton>
                )
            })}
            {allowEdit && (
                <IconButton onClick={() => handleEdit(rowId)} size="small">
                    <Edit size={20} color={theme.palette.grey.main} />
                </IconButton>
            )}

            {allowView && (
                <IconButton onClick={() => handleView(rowId)} size="small">
                    <Eye size={20} color={theme.palette.grey.main} />
                </IconButton>
            )}

            {allowDelete && (
                <IconButton onClick={() => handleDelete(rowId)} size="small">
                    <Delete size={20} color={theme.palette.grey.main} />
                </IconButton>
            )}
        </Container>
    )
}

export default Actions

const Container = styled('div')`
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`
