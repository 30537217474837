import Input from 'components/Control/Input'
import Card from 'components/Display/Card'
import Button from 'components/Control/Button'
import {Controller} from 'react-hook-form'
import {Texts, Colors} from 'appearance'
import {Paper, styled} from '@mui/material'

export const FormCard = ({children, ...p}) => {
    return (
        <Card {...p}>
            <Controller
                name={p.controllerName}
                control={p.control}
                rules={
                    p.rules || {
                        required: true,
                    }
                }
                render={({field: {onChange, value}}) => (
                    <FormInput
                        onChange={p.onChange}
                        value={p.value}
                        fullWidth
                        label={p.label}
                        placeholder={p.placeholder}
                    />
                )}
            />
            {p.errors[p.controllerName] && (
                <FormErrorText>{p.errorMessage}</FormErrorText>
            )}
            {children}
        </Card>
    )
}

export const FormErrorMessage = ({errors, name, errorMessage}) => {
    const passedErrorsAndHasError = errors && errors[name]
    const onlyErrorMessage = !errors && errorMessage
    if (passedErrorsAndHasError || onlyErrorMessage) {
        return <FormErrorText>{errorMessage}</FormErrorText>
    }
    return null
}

export const LoginFormContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    padding: 0 24px 36px;
`

export const FormTitle = styled(Texts.Title)`
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
`

export const FormInput = styled(Input)`
    margin-bottom: 10px;
`

export const FormRow = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

export const FormActionButton = styled(Button)`
    width: 400px;
    margin-top: 30px;
    margin-bottom: 10px;
`

export const FormAdditionalButton = styled(Button)`
    margin-bottom: 10px;
`

export const FormErrorText = styled(Texts.RegularText)`
    color: ${Colors.ERROR_TEXT};
`

export const FormAdditionalText = styled(Texts.RegularText)``
