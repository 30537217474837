import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {
    useCreateCityMutation,
    useGetCityQuery,
    useSaveCityMutation,
} from 'store/slices/api/citiesApi'
import {
    useCreateCountryMutation,
    useGetCountryQuery,
    useSaveCountryMutation,
} from 'store/slices/api/countriesApi'
import {useI18n} from 'store/Store'

const useMoreDataForm = () => {
    const {i18n} = useI18n()

    const [itemId, setItemId] = useState()
    const [openModal, setOpenModal] = useState()

    const {data: city} = useGetCityQuery(itemId, {
        skip: !itemId || openModal !== 'citie',
    })

    const {data: country} = useGetCountryQuery(itemId, {
        skip: !itemId || openModal !== 'countrie',
    })

    const [doCreateCounry] = useCreateCountryMutation()
    const [doSaveCountry] = useSaveCountryMutation()

    const [doCreateCity] = useCreateCityMutation()
    const [doSaveCity] = useSaveCityMutation()

    const defaultValues = useMemo(() => ({nameEng: '', nameHeb: ''}), [])
    const form = useForm({defaultValues})
    const {handleSubmit} = form

    const dataTypes = useMemo(
        () => ({
            countrie: {
                create: doCreateCounry,
                update: doSaveCountry,
            },
            citie: {
                create: doCreateCity,
                update: doSaveCity,
            },
        }),
        [doCreateCounry, doCreateCity, doSaveCountry, doSaveCity]
    )

    useEffect(() => {
        const resetValues = {
            citie: city,
            countrie: country,
            default: defaultValues,
        }

        if (itemId) {
            form.reset(resetValues[openModal] || defaultValues)
        } else {
            form.reset(defaultValues)
        }
    }, [itemId, city, country, form, defaultValues, openModal])

    const handleOpenModal = (itemName) => {
        setOpenModal(itemName)
    }

    const handleCloseModal = () => {
        setItemId()
        setOpenModal()
    }

    const handleEditItem = (id, itemName) => {
        setItemId(id)
        setOpenModal(itemName)
    }

    const onSubmit = async (values) => {
        const operationType = values._id ? 'update' : 'create'
        const response = await dataTypes[openModal]?.[operationType]?.(values)

        if (response.data._id) {
            handleCloseModal()
        }
    }

    const getModalTitle = () => {
        const firstString = i18n.t(`form.${itemId ? 'edit' : 'create'}`)
        const secondString = i18n.t(`tables.${openModal}`)
        return `${firstString} ${secondString}`
    }

    const submit = handleSubmit(onSubmit)

    return {
        form,
        submit,
        openModal,
        handleOpenModal,
        handleCloseModal,
        handleEditItem,
        itemId,
        modalTitle: getModalTitle(),
    }
}

export default useMoreDataForm
