import {styled} from '@mui/material'
import {FormRow} from '../Form/Forms'
import {useI18n} from 'store/Store'
import {shapes} from 'constants/dances'
import {levels} from 'constants/sessions'
import {useForm} from 'react-hook-form'
import FormTimePicker from 'components/Control/Form/FormTimePicker'
import FormSelect from 'components/Control/Form/FormSelect'
import {yupResolver} from '@hookform/resolvers/yup'
import {timeTableSchema} from 'services/validations/schemas/sessions'
import Button from 'components/Control/Button'

const TimeTableForm = ({addItem}) => {
    const {i18n} = useI18n()

    const {
        control,
        formState: {errors, isValid},
        reset,
        watch,
        trigger,
    } = useForm({
        mode: 'all',
        resolver: yupResolver(timeTableSchema),
    })

    const handleAddItem = () => {
        const values = watch()
        trigger()
        addItem(values)
        reset()
    }

    return (
        <Container>
            <FormRow>
                <FormTimePicker
                    name="timeStart"
                    label={i18n.t(`sessions.timeStart`)}
                    control={control}
                    error={errors?.timeStart?.message}
                />
                <FormTimePicker
                    name="timeEnd"
                    label={i18n.t(`sessions.timeEnd`)}
                    control={control}
                    error={errors?.timeEnd?.message}
                />

                <FormSelect
                    name="shape"
                    itemsName="dances"
                    optionsName="shapesTypes"
                    label={i18n.t(`sessions.shape`)}
                    control={control}
                    options={shapes}
                    error={errors?.shape?.message}
                />

                <FormSelect
                    name="level"
                    itemsName="sessions"
                    optionsName="levels"
                    label={i18n.t(`sessions.level`)}
                    options={levels}
                    control={control}
                    error={errors?.level?.message}
                />

                <Button
                    sx={{height: 39}}
                    variant="outlined"
                    onClick={handleAddItem}
                    disabled={!isValid}>
                    {i18n.t('form.add')}
                </Button>
            </FormRow>
        </Container>
    )
}

export default TimeTableForm

const Container = styled('div')``
