export const formatUser = ({
    firstNameHeb,
    lastNameHeb,
    firstNameEng,
    lastNameEng,
}) => {
    const hebName = [firstNameHeb, lastNameHeb].filter(Boolean).join(' ')
    const engName = [firstNameEng, lastNameEng].filter(Boolean).join(' ')

    return [hebName, engName].filter(Boolean).join(' | ')
}

export const formatUserName = (user, locale) => {
    if (!user) return ''
    const {firstNameHeb, lastNameHeb, firstNameEng, lastNameEng} = user
    const hebName = [firstNameHeb, lastNameHeb].filter(Boolean).join(' ')
    const engName = [firstNameEng, lastNameEng].filter(Boolean).join(' ')
    return locale === 'he' ? hebName : engName
}

export const formatUserArrEng = (choreographers) =>
    choreographers
        ?.map(
            (choreographer) =>
                `${choreographer.firstNameEng} ${choreographer.lastNameEng}`
        )
        .join(', ')

export const formatItemName = (item) =>
    item ? [item?.nameEng, item?.nameHeb].filter(Boolean).join(' | ') : ''

export const formatShapeType = (shapeType, i18n, activeLocale) => {
    i18n.locale = 'he'
    const shapeHebName = i18n.t(`dances.shapesTypes.${shapeType}`)
    i18n.locale = 'en'
    const shapeEngName = i18n.t(`dances.shapesTypes.${shapeType}`)
    i18n.locale = activeLocale
    return shapeHebName + ' | ' + shapeEngName
}

export const formatItemByLocale = (item, locale) => {
    if (!item) return ''
    const {nameHeb, nameEng} = item
    return locale === 'he' ? nameHeb : nameEng
}

const getFullName = (firstNameEng, lastNameEng) =>
    [firstNameEng, lastNameEng].filter(Boolean).join(' ')

export const formatDance = (dance) => {
    if (!dance) return ''
    const {choreographers, nameEng, nameHeb} = dance
    const choreographer = choreographers?.[0]

    const choreographerEng = getFullName(
        choreographer?.firstNameEng,
        choreographer?.lastNameEng
    )
    const choreographerHeb = getFullName(
        choreographer?.firstNameHeb,
        choreographer?.lastNameHeb
    )

    const english = [nameEng, choreographerEng].filter(Boolean).join(' - ')
    const hebrew = [nameHeb, choreographerHeb].filter(Boolean).join(' - ')

    return `${english} | ${hebrew}`
}

export const isBase64 = (str) =>
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(str)
