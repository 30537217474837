import {React} from 'common'
import {useNavigate} from 'react-router-dom'
import {Page, PageContent} from 'components/Page'
import {Controller} from 'react-hook-form'
import {validationPatterns} from 'constants/auth'
import Button from 'components/Control/Button'
import {
    FormActionButton,
    LoginFormContainer,
    FormTitle,
    FormInput,
    FormRow,
    FormAdditionalButton,
    FormErrorMessage,
    FormAdditionalText,
} from 'components/Control/Form/index_'
import useAuth from 'logic/Auth/useAuth'

const ForgotPassword = () => {
    const navigate = useNavigate()

    const {formControl, forgotPassword, isLoading, response, formErrors} =
        useAuth()

    if (response?.message) {
        return (
            <Page noHeader noDrawer>
                <PageContent>{response.message}</PageContent>
                <Button onClick={() => navigate('/Login')} variant="contained">
                    Continue
                </Button>
            </Page>
        )
    }

    return (
        <Page noHeader noDrawer>
            <PageContent centered>
                <LoginFormContainer style={{width: 400}}>
                    <FormTitle>Password Reset</FormTitle>
                    <Controller
                        name={'email'}
                        control={formControl}
                        rules={{
                            required: true,
                            pattern: validationPatterns.EMAIL,
                        }}
                        render={({field: {onChange, value}}) => (
                            <FormInput
                                onChange={onChange}
                                value={value}
                                fullWidth
                                label="Email"
                                type="email"
                            />
                        )}
                    />
                    <FormErrorMessage
                        errors={formErrors}
                        name={'email'}
                        errorMessage={'valid email is required.'}
                    />
                </LoginFormContainer>

                <FormActionButton onClick={forgotPassword} variant="contained">
                    Send Password Reset Email
                </FormActionButton>
                <FormRow>
                    <FormAdditionalText>Have an Account?</FormAdditionalText>
                    <FormAdditionalButton
                        disabled={isLoading}
                        onClick={() => navigate('/login')}>
                        Login
                    </FormAdditionalButton>
                </FormRow>
            </PageContent>
        </Page>
    )
}

export default ForgotPassword
