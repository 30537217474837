import {Controller} from 'react-hook-form'
import Select from '../Select'

const FormSelect = ({
    name,
    label,
    control,
    error,
    required,
    disabled,
    readOnly,
    options,
    itemsName,
    optionsName,
    hidden,
    getOptionLabel,
}) => {
    const trnasformValue = (onChange) => (event) => {
        let {value} = event.target
        let trnasformedValue = value

        if (optionsName === 'yesNo') {
            trnasformedValue = value === 'yes'
        }
        onChange(trnasformedValue)
    }

    const parseValue = (value) => {
        if (optionsName === 'yesNo') {
            return value ? 'yes' : 'no'
        }
        return value
    }

    return (
        !hidden && (
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value}}) => (
                    <Select
                        name={name}
                        required={required}
                        disabled={disabled}
                        readOnly={readOnly}
                        label={label}
                        itemsName={itemsName}
                        optionsName={optionsName}
                        error={error}
                        value={parseValue(value)}
                        onChange={trnasformValue(onChange)}
                        options={options}
                        getOptionLabel={getOptionLabel}
                    />
                )}
            />
        )
    )
}

export default FormSelect
