import {React} from 'common'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import {useLanguage} from 'store/Store'

const LanguageMenuButton = ({menuId, handleOpenMenu}) => {
    const language = useLanguage()
    return (
        <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleOpenMenu('language')}
            color="inherit">
            <Typography
                variant="h5"
                noWrap
                component="div"
                sx={{display: {xs: 'none', sm: 'block'}}}>
                {language?.toUpperCase()}
            </Typography>
        </IconButton>
    )
}

export default LanguageMenuButton
