import {Page, PageContent} from 'components/Page'
import {FormProvider} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {useParams} from 'react-router-dom'
import FormTitle from 'components/Page/Form/FormTitle'
import {isNew} from 'services/utils/editForm'
import DanceForm from 'components/Page/Dances/DanceForm'
import useDanceForm from 'logic/Forms/useDanceForm'
import Stats from 'components/Page/Stats/Stats'
import Modal from 'components/Display/Modal/Modal'
import UploadMediaForm from 'components/Page/Dances/UploadMediaForm'
import {useGetDanceQuery} from 'store/slices/api/dancesApi'
import useUploadDanceMediaForm from 'logic/Forms/useUploadMediaForm'

const Dance = () => {
    const {i18n} = useI18n()
    const {danceId} = useParams()

    const {data: dance} = useGetDanceQuery(danceId, {
        skip: isNew(danceId),
    })

    const {
        form,
        submit,
        choreographers,
        artists,
        searchChorecographerValue,
        setSearchChoreographer,
        stats,
        danceName,
        activeInput,
        searchArtistValue,
        setSearchArtist,
        headerButtons,
        toggleUploadModal,
        uploadModalOpen,
        videos,
        handleDeleteVideo,
        isGuidesLoading,
        isArtistsLoading,
    } = useDanceForm({danceId, dance})

    const {
        form: uploadMediaForm,
        submit: submitMedia,
        isLoading,
        isValidForm,
    } = useUploadDanceMediaForm(danceId, toggleUploadModal)

    return (
        <Page>
            <PageContent>
                <FormTitle
                    itemId={danceId}
                    itemName="dance"
                    itemLabel={danceName}
                    submit={submit}
                    buttons={headerButtons}
                />

                {!isNew(danceId) && <Stats itemsName="dances" stats={stats} />}

                <FormProvider {...form}>
                    <DanceForm
                        isNew={isNew(danceId)}
                        activeInput={activeInput}
                        choreographers={choreographers}
                        artists={artists}
                        searchArtistValue={searchArtistValue}
                        setSearchArtist={setSearchArtist}
                        searchChorecographerValue={searchChorecographerValue}
                        setSearchChoreographer={setSearchChoreographer}
                        onDeleteVideo={handleDeleteVideo}
                        videos={videos}
                        isGuidesLoading={isGuidesLoading}
                        isArtistsLoading={isArtistsLoading}
                    />
                </FormProvider>

                <Modal
                    open={uploadModalOpen}
                    title={danceName}
                    onClose={toggleUploadModal}
                    buttons={[
                        {
                            label: i18n.t('form.cancel'),
                            onClick: toggleUploadModal,
                        },
                        {
                            label: i18n.t('form.save'),
                            onClick: submitMedia,
                            loading: isLoading,
                            disabled: !isValidForm,
                        },
                    ]}>
                    <FormProvider {...uploadMediaForm}>
                        <UploadMediaForm danceId={danceId} />
                    </FormProvider>
                </Modal>
            </PageContent>
        </Page>
    )
}

export default Dance
