import {alpha, createTheme, getContrastRatio} from '@mui/material/styles'
import colors from './colors'

export const inputHeight = 46
const grayBase = '#424242'
const greyMain = alpha(grayBase, 0.7)

const theme = createTheme({
    palette: {
        primary: {
            main: '#D23A3E',
            contrastText: '#fff',
        },
        secondary: {
            main: '#1E2A47',
            dark: '#2B2B2B',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
        header: {
            main: '#fff',
        },
        label: 'rgba(0, 0, 0, 0.6)',
        grey: {
            main: greyMain,
            light: alpha(grayBase, 0.5),
            dark: alpha(grayBase, 1),
            contrastText:
                getContrastRatio(greyMain, '#fff') >= 3 ? '#fff' : '#000',
        },
    },

    typography: {
        fontFamily: "'Rubik', sans-serif",
    },
    components: {
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    '& .MuiListItemIcon-root': {
                        color: colors.APP_BLACK,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    height: inputHeight,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    background: '#FFF',
                    // height: inputHeight,
                    paddingRight: 0,
                },
                input: {
                    height: inputHeight,
                    padding: '8px 12px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: '#FFF',
                    // height: inputHeight,
                    // minHeight: inputHeight,
                    paddingRight: 0,
                },
                input: {
                    // height: inputHeight,
                    // minHeight: inputHeight,
                    padding: '8px 12px',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    // height: 28,
                },
                deleteIcon: {
                    margin: 0,
                    marginInline: 4,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        padding: 4,
                        minHeight: inputHeight,
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 600,
                    height: 32,
                    padding: '5px 10px',
                    lineHeight: 'normal',
                    gap: 4,
                },
                startIcon: {
                    margin: 0,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: 16,
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    padding: 0,
                    margin: 0,
                    '& button': {
                        padding: 0,
                        marginInlineEnd: 8,
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    marginLeft: 0,
                    marginBottom: 4,
                },
            },
        },
    },
})

export default theme
