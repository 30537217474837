import {styled} from '@mui/material'

const PreviewImage = ({showImage, src, alt}) => {
    return showImage && <UploadedImage src={src} alt={alt} />
}

export default PreviewImage

const UploadedImage = styled('img')`
    width: 'fit-content';
    padding-top: 12px;
`
