import {Controller} from 'react-hook-form'
import ChipAutocomplete from '../ChipAutocomplete'
import {InputContainer} from 'components/Page/Form/Forms'

const FormChipAutocomplete = ({
    name,
    control,
    options,
    getOptionLabel,
    label,
    placeholder,
    error,
    hidden,
    inputValue,
    setInputValue,
    itemsName,
    optionsName,
    clearOnBlur,
    isLoading,
    ...props
}) => {
    const onChangeInputValue = (event, value) => {
        setInputValue(value, name)
    }

    const onSelectValue = (onChange) => (event, value) => {
        setInputValue('', name)
        onChange(value)
    }

    return (
        !hidden && (
            <InputContainer>
                <Controller
                    name={name}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <ChipAutocomplete
                            multiple
                            itemsName={itemsName}
                            optionsName={optionsName}
                            placeholder={placeholder}
                            name={name}
                            value={value || []}
                            label={label}
                            error={error}
                            onChange={onSelectValue(onChange)}
                            options={options}
                            getOptionLabel={getOptionLabel}
                            onInputChange={onChangeInputValue}
                            clearOnBlur={clearOnBlur}
                            isLoading={isLoading}
                            {...props}
                        />
                    )}
                />
            </InputContainer>
        )
    )
}

export default FormChipAutocomplete
