import {React} from 'common'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import {getAvailableLanaguages} from 'localization/i18n'

const LanguageMenu = ({
    isOpen,
    languageAnchorEl,
    handleCloseMenu,
    handleSetLanguage,
}) => {
    const languageMenuId = 'primary-search-account-menu-language'

    return (
        <Menu
            anchorEl={languageAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={languageMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isOpen}
            onClose={handleCloseMenu('language')}>
            {getAvailableLanaguages().map((lang) => (
                <MenuItem
                    key={`lang_${lang}`}
                    onClick={() => handleSetLanguage(lang)}>
                    {lang.toUpperCase()}
                </MenuItem>
            ))}
        </Menu>
    )
}

export default LanguageMenu
