import {React, styled} from 'common'
import DataTable from 'components/Display/DataTable/DataTable'
import {Page, PageContent} from 'components/Page'
import {useDeleteUserMutation, useGetUsersQuery} from 'store/Store'

const Users = () => {
    return (
        <Page>
            <Content>
                <DataTable
                    allowEdit
                    itemName="user"
                    paginationType="external"
                    useGetQuery={useGetUsersQuery}
                    useDeleteMutation={useDeleteUserMutation}
                />
            </Content>
        </Page>
    )
}

export default Users
const Content = styled(PageContent)``
