import {React} from 'common'
import Table from 'components/Display/Table'
import {Page, PageContent} from 'components/Page'
import {useDeleteAccountMutation, useGetAccountsQuery} from 'store/Store'

const Accounts = () => {
    return (
        <Page menuTitle={'accounts'}>
            <PageContent>
                <Table
                    itemName="account"
                    allowExpand
                    allowEdit
                    allowAdd
                    paginationType="external"
                    // paginationType="local"
                    useGetQuery={useGetAccountsQuery}
                    useDeleteMutation={useDeleteAccountMutation}
                />
            </PageContent>
        </Page>
    )
}

export default Accounts
