import {DateTimePicker} from '@mui/x-date-pickers'
import {renderTimeViewClock} from '@mui/x-date-pickers/timeViewRenderers'
import {InputContainer} from 'components/Page/Form/Forms'
import {Controller} from 'react-hook-form'
import dayjs from 'services/utils/dayjs'
import {useI18n} from 'store/Store'

const FormDateTimePicker = ({
    hidden,
    name,
    control,
    disabled,
    label,
    error,
}) => {
    const {i18n} = useI18n()
    return (
        !hidden && (
            <InputContainer>
                <Controller
                    name={name}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <DateTimePicker
                            ampm={false}
                            label={label}
                            format="DD/MM/YYYY, HH:mm"
                            disabled={disabled}
                            value={value ? dayjs(value) : null}
                            onChange={onChange}
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: null,
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    helperText:
                                        error && i18n.t(`formErrors.${error}`),
                                    InputLabelProps: {shrink: true},
                                    error: !!error,
                                },
                            }}
                        />
                    )}
                />
            </InputContainer>
        )
    )
}

export default FormDateTimePicker
