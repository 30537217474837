import {React, styled} from 'common'
import {FormErrorText} from 'components/Control/Form/index_'
import {Paper} from '@mui/material'
import Button from './Button'
import {Texts} from 'appearance'
import {getItemLabel, getShouldShowItemKey} from 'services/utils/editForm'
import useItemForm from 'logic/Table/useItemForm'
import FormComponent from './Form/FormComponent'
import {useNavigate} from 'react-router-dom'
import {useEffect} from 'react'

const EditForm = ({
    itemName,
    itemId,
    useGetQuery,
    useCreateMutation,
    useSaveMutation,
}) => {
    const navigate = useNavigate()

    const {
        itemNames,
        formTitle,
        keyTypes,
        requiredKeys,
        editableKeys,
        item,
        formControl,
        formErrors,
        submit,
        errorMessage,
        createResponse,
        saveResponse,
    } = useItemForm(
        itemName,
        useCreateMutation,
        useGetQuery,
        useSaveMutation,
        itemId
    )

    useEffect(() => {
        if (createResponse || saveResponse) {
            navigate(`/${itemNames}`)
        }
    }, [createResponse, saveResponse, navigate, itemNames])

    return (
        <>
            <Texts.Title>{formTitle}</Texts.Title>
            <FormContainer>
                {Object.keys(keyTypes).map((itemKey) => {
                    const disabled = !editableKeys?.includes(itemKey)
                    const label = getItemLabel(itemKey, itemName)
                    const showItemKey = getShouldShowItemKey(
                        item,
                        itemKey,
                        disabled
                    )
                    return (
                        showItemKey && (
                            <FormComponent
                                key={`form_item_key_${itemKey}`}
                                itemKey={itemKey}
                                itemNames={itemNames}
                                name={itemKey}
                                label={label}
                                control={formControl}
                                errors={formErrors}
                                required={requiredKeys?.includes(itemKey)}
                                disabled={disabled}
                                type={keyTypes[itemKey]}
                            />
                        )
                    )
                })}

                <FormSubmitButton onClick={submit} variant="contained">
                    {formTitle}
                </FormSubmitButton>
                {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}
            </FormContainer>
        </>
    )
}

export default EditForm

const FormContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    padding: 20px 30px;
    margin-top: 10px;
`

const FormSubmitButton = styled(Button)`
    width: 100%;
    margin-top: 30px !important;
    margin-bottom: 10px !important;
`
