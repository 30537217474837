import {
    useDeletePaymentMutation,
    useGetPaymentsQuery,
} from 'store/slices/api/paymentsApi'
import {Page, PageContent} from 'components/Page'
import DataTable from 'components/Display/DataTable/DataTable'

const Payments = () => {
    return (
        <Page>
            <PageContent>
                <DataTable
                    allowAdd={false}
                    itemName="payment"
                    paginationType="external"
                    useGetQuery={useGetPaymentsQuery}
                    useDeleteMutation={useDeletePaymentMutation}
                />
            </PageContent>
        </Page>
    )
}

export default Payments
