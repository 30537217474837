import {React} from 'common'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import {useIsRtl} from 'store/Store'

const MainMenuButton = ({toggleDrawer}) => {
    const isRtl = useIsRtl()
    const margins = isRtl ? {ml: 2, mr: -1.5} : {mr: 2, ml: -1.5}
    return (
        <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={margins}>
            <MenuIcon />
        </IconButton>
    )
}

export default MainMenuButton
