import UsersIcon from '@mui/icons-material/Group'
import PersonIcon from '@mui/icons-material/Person'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import QueueMusicIcon from '@mui/icons-material/QueueMusic'
import EventIcon from '@mui/icons-material/Event'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import DashboardIcon from '@mui/icons-material/Dashboard'
import EmailIcon from '@mui/icons-material/Email'
import PaidIcon from '@mui/icons-material/Paid'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import HomeIcon from '@mui/icons-material/Home'

export const menuItems = [
    'home',
    'users',
    'guides',
    'artists',
    'dances',
    'danceCollections',
    'sessions',
    'moreData',
    'messages',
    'payments',
    'publications',
    'banners',
    'newspapers',
]

export const menuIcons = {
    home: <HomeIcon />,
    users: <UsersIcon />,
    guides: <PersonIcon />,
    artists: <PersonIcon />,
    dances: <DirectionsWalkIcon />,
    danceCollections: <QueueMusicIcon />,
    sessions: <EventIcon />,
    moreData: <DashboardIcon />,
    messages: <EmailIcon />,
    payments: <PaidIcon />,
    publications: <LocalActivityIcon />,
    banners: <LocalActivityIcon />,
    newspapers: <NewspaperIcon />,
}
