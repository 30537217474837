import FormAutocomplete from 'components/Control/Form/FormAutocomplete'
import FormSelect from 'components/Control/Form/FormSelect'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {clubPeriods, clubTypes, yesNoOptions} from 'constants/users'
import {useFormContext} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {FormColumn, FormContainer, FormRow} from '../Form/Forms'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import {formatItemName, formatUser} from 'services/utils/data'

const UserForm = ({
    guides,
    searchGuide,
    setSearchGuide,
    countries,
    isNew,
    searchCountry,
    setSearchCountry,
}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const {
        control,
        formState: {errors},
    } = formContext

    return (
        <FormContainer>
            <FormRow>
                <FormColumn>
                    <FormTextInput
                        readOnly
                        fullWidth
                        hidden={isNew}
                        name="_id"
                        label={i18n.t(`users._id`)}
                        control={control}
                        error={errors._id}
                    />
                    <FormRow>
                        <FormTextInput
                            fullWidth
                            name="email"
                            label={i18n.t(`users.email`)}
                            control={control}
                            error={errors.email}
                        />
                        <FormSelect
                            fullWidth
                            optionsName="yesNo"
                            itemsName="users"
                            name="isSuper"
                            label={i18n.t(`users.isSuper`)}
                            options={yesNoOptions}
                            control={control}
                            error={errors.isSuper}
                        />
                    </FormRow>
                    <FormRow>
                        <FormTextInput
                            fullWidth
                            name="firstName"
                            label={i18n.t(`users.firstName`)}
                            control={control}
                            error={errors.firstName}
                        />
                        <FormTextInput
                            fullWidth
                            name="lastName"
                            label={i18n.t(`users.lastName`)}
                            control={control}
                            error={errors.lastName}
                        />
                    </FormRow>
                    <FormTextInput
                        fullWidth
                        name="mobilePhone"
                        label={i18n.t(`users.mobilePhone`)}
                        control={control}
                        error={errors.mobilePhone}
                    />
                    <FormRow>
                        <FormSelect
                            fullWidth
                            itemsName="users"
                            optionsName="clubTypes"
                            name="clubType"
                            label={i18n.t(`users.clubType`)}
                            options={clubTypes}
                            control={control}
                            error={errors.clubType}
                        />
                        <FormSelect
                            fullWidth
                            itemsName="users"
                            optionsName="clubPeriods"
                            name="clubPeriod"
                            label={i18n.t(`users.clubPeriod`)}
                            options={clubPeriods}
                            control={control}
                            error={errors.clubPeriod}
                        />
                    </FormRow>
                </FormColumn>
                <FormColumn>
                    <FormTextInput
                        fullWidth
                        name="facebookId"
                        label={i18n.t(`users.facebookId`)}
                        control={control}
                        error={errors.facebookId}
                    />

                    <FormAutocomplete
                        fullWidth
                        name="country"
                        label={i18n.t(`users.country`)}
                        control={control}
                        error={errors.country}
                        options={countries}
                        getOptionLabel={formatItemName}
                        inputValue={searchCountry}
                        onChangeInputValue={setSearchCountry}
                    />

                    <FormRow>
                        <FormTextInput
                            fullWidth
                            name="giftDownloads"
                            label={i18n.t(`users.giftDownloads`)}
                            control={control}
                            error={errors.giftDownloads}
                        />

                        <FormTextInput
                            fullWidth
                            name="remainingDownloads"
                            label={i18n.t(`users.remainingDownloads`)}
                            control={control}
                            error={errors.remainingDownloads}
                        />
                    </FormRow>

                    <FormRow>
                        <FormDatePicker
                            fullWidth
                            readOnly
                            name="clubRegistrationDate"
                            label={i18n.t(`users.clubRegistrationDate`)}
                            control={control}
                            error={errors.clubRegistrationDate}
                        />

                        <FormAutocomplete
                            fullWidth
                            name="guide"
                            label={i18n.t(`users.guide`)}
                            control={control}
                            error={errors.guide}
                            options={guides}
                            getOptionLabel={formatUser}
                            inputValue={searchGuide}
                            onChangeInputValue={setSearchGuide}
                        />
                    </FormRow>
                </FormColumn>
            </FormRow>
        </FormContainer>
    )
}

export default UserForm
