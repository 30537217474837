import {styled} from '@mui/material'
import {FormColumn, FormRow} from '../Form/Forms'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {useI18n} from 'store/Store'
import {useFormContext} from 'react-hook-form'
import {yesNoOptions} from 'constants/users'
import FormSelect from 'components/Control/Form/FormSelect'
import {bannersLocations} from 'constants/banners'
import FormChipSelect from 'components/Control/Form/FormChipSelect'
import FormUpload from 'components/Control/Form/FormUpload'
import {urls} from 'services/utils/urls'

const BannerForm = ({isNew, uploadBanner}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()

    const bannerUrl = formContext.watch('imgUrl')

    return (
        <Container>
            <FormRow>
                <FormColumn>
                    <FormTextInput
                        readOnly
                        hidden={isNew}
                        fullWidth
                        name="_id"
                        label={i18n.t(`banners._id`)}
                        control={formContext.control}
                        error={formContext.formState.errors._id}
                    />

                    <FormSelect
                        fullWidth
                        itemsName="users"
                        optionsName="yesNo"
                        name="isActive"
                        label={i18n.t(`banners.isActiveHeb`)}
                        options={yesNoOptions}
                        control={formContext.control}
                        error={formContext.formState.errors.isActiveHeb}
                    />

                    <FormTextInput
                        fullWidth
                        name="name"
                        label={i18n.t(`banners.name`)}
                        control={formContext.control}
                        error={formContext.formState.errors.name}
                    />

                    <FormRow>
                        <FormTextInput
                            fullWidth
                            name="width"
                            label={i18n.t(`banners.width`)}
                            control={formContext.control}
                            error={formContext.formState.errors.width}
                        />
                        <FormTextInput
                            fullWidth
                            name="height"
                            label={i18n.t(`banners.height`)}
                            control={formContext.control}
                            error={formContext.formState.errors.height}
                        />
                    </FormRow>
                </FormColumn>
                <FormColumn>
                    <FormTextInput
                        readOnly
                        hidden={isNew}
                        fullWidth
                        name="created"
                        label={i18n.t(`banners.created`)}
                        control={formContext.control}
                        error={formContext.formState.errors.created}
                    />

                    <FormSelect
                        fullWidth
                        itemsName="users"
                        optionsName="yesNo"
                        name="isActiveEng"
                        label={i18n.t(`banners.isActiveEng`)}
                        options={yesNoOptions}
                        control={formContext.control}
                        error={formContext.formState.errors.isActiveEng}
                    />

                    <FormTextInput
                        fullWidth
                        name="websiteUrl"
                        label={i18n.t(`banners.websiteUrl`)}
                        control={formContext.control}
                        error={formContext.formState.errors.websiteUrl}
                        placeholder="http://..."
                    />

                    <FormChipSelect
                        fullWidth
                        itemsName="banners"
                        optionsName="locations"
                        name="location"
                        label={i18n.t(`banners.location`)}
                        options={bannersLocations}
                        control={formContext.control}
                        error={formContext.formState.errors.location}
                    />
                </FormColumn>
            </FormRow>
            <FormUpload
                label={i18n.t(`banners.picture`)}
                name="imgUrl"
                control={formContext.control}
                onUpload={uploadBanner}
                url={`${urls.base}/${urls.banners_pictures}/${bannerUrl}`}
            />
        </Container>
    )
}

export default BannerForm

const Container = styled('div')`
    padding-inline: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`
