import {isNew} from 'services/utils/editForm'
import {Button, styled} from '@mui/material'
import {Texts} from 'appearance'
import {useI18n} from 'store/Store'

const FormTitle = ({submit, itemId, itemLabel, itemName, buttons}) => {
    const {i18n} = useI18n()
    const getModalTitle = (itemId) => {
        const firstString = i18n.t(`form.${isNew(itemId) ? 'create' : 'edit'}`)
        return `${firstString} ${itemLabel ?? i18n.t(`tables.${itemName}`)}`
    }

    return (
        <Container>
            <Title>{getModalTitle(itemId)}</Title>

            <ButtonsContainer>
                {buttons?.map((button) => (
                    <Button startIcon={button.icon} {...button}>
                        {button.label}
                    </Button>
                ))}
                <Button variant="contained" onClick={submit}>
                    {i18n.t('form.save')}
                </Button>
            </ButtonsContainer>
        </Container>
    )
}

export default FormTitle

const Container = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
`

const Title = styled(Texts.Text)(({theme}) => ({
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.primary.main,
}))
const ButtonsContainer = styled('div')`
    display: flex;
    gap: 12px;
`
