import {Chip, styled} from '@mui/material'
import {Texts} from 'appearance'
import {useI18n} from 'store/Store'
import TimeTableForm from './TimeTableForm'
import {useFormContext} from 'react-hook-form'
import useTimeTableForm from 'logic/Forms/useTimeTableForm'

const TimeTable = ({data}) => {
    const {i18n} = useI18n()

    const formContext = useFormContext()
    const {fields, addItem, removeItem} = useTimeTableForm(formContext.control)

    const formatTimeLabel = (field) => {
        const {level: levelValue, shape: shapeValue} = field || {}
        const level = levelValue && i18n.t(`sessions.levels.${levelValue}`)
        const shape = shapeValue && i18n.t(`dances.shapesTypes.${shapeValue}`)
        const times = [field?.timeStart, field?.timeEnd].join(' - ')

        const parts = [times, level, shape].filter(
            (part) => part && part !== ''
        )

        return parts.join(' | ')
    }

    return (
        <Container>
            <Title>{i18n.t('sessions.timeTable')}</Title>

            {!!fields?.length && (
                <Times>
                    {fields.map((field, index) => {
                        const label = formatTimeLabel(field)
                        return (
                            !!label?.length && (
                                <TimeChip
                                    key={field.id}
                                    label={label}
                                    onDelete={() => removeItem(index)}
                                />
                            )
                        )
                    })}
                </Times>
            )}

            <TimeTableForm addItem={addItem} />
        </Container>
    )
}

export default TimeTable

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const Title = styled(Texts.Text)(({theme}) => ({
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.label,
}))

const Times = styled('div')`
    display: flex;
    flex-flow: column wrap;
    gap: 12px;
    padding-bottom: 12px;
`

const TimeChip = styled(Chip)`
    width: fit-content;
    & .MuiChip-label {
        font-size: 14px;
    }
`
