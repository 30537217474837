import {Snackbar, styled, Tooltip} from '@mui/material'
import {Texts} from 'appearance'
import {useState} from 'react'
import {useI18n} from 'store/Store'

const CellWithCopy = ({children, value}) => {
    const {i18n} = useI18n()
    const [showCopied, setShowCopied] = useState(false)
    return (
        <Container
            onClick={() => {
                navigator.clipboard.writeText(value)
                setShowCopied(true)
            }}>
            <Tooltip title={i18n.t('tables.copyToClipboard')}>
                <Texts.RegularText>{children}</Texts.RegularText>
            </Tooltip>

            {showCopied && (
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    open={showCopied}
                    onClose={() => setShowCopied(false)}
                    message={i18n.t('tables.copiedToClipboard')}
                />
            )}
        </Container>
    )
}

export default CellWithCopy

const Container = styled('div')`
    cursor: pointer;
`
