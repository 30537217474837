import {styled} from 'common'
import RawButton from '@mui/material/Button'
import {paramCase} from 'change-case'
import {CircularProgress} from '@mui/material'

const Button = ({children, dataTestId, loading, disabled, ...p}) => {
    return (
        <StyledButton
            data-test-id={`button-${paramCase(
                children?.toString() || dataTestId || 'undefined'
            )}`}
            {...p}
            disabled={loading || disabled}
            endIcon={
                loading && <CircularProgress size={16} color="secondary" />
            }>
            {children}
        </StyledButton>
    )
}
export default Button

const StyledButton = styled(RawButton)`
    display: flex;
    gap: 8px;
    & .MuiButton-endIcon {
        margin-left: 0;
        margin-inline: 4px;
    }
`
