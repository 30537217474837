import {Typography, styled} from '@mui/material'
import 'assets/css/fonts.css'

const RegularText = styled('span')``

const MediumText = styled('span')`
    font-weight: 500;
`

const BoldText = styled('span')`
    font-weight: bold;
`

const Title = styled(BoldText)(({theme}) => ({
    color: theme.palette.primary.main,
    fontSize: 24,
}))

const Text = styled(Typography)``

const Texts = {RegularText, MediumText, Title, BoldText, Text}
export default Texts
