import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {isNew} from 'services/utils/editForm'
import {useGetGuidesQuery} from 'store/slices/api/guidesApi'
import {
    useCreatePublicationMutation,
    useGetPublicationQuery,
    useSavePublicationMutation,
} from 'store/slices/api/publicationsApi'
import {useUploadImageMutation} from 'store/slices/api/uploadsApi'
import {useI18n} from 'store/Store'
import {useAlert} from 'components/Display/Alert/AlertContext'
import {useNavigate} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import {publicationSchema} from 'services/validations/schemas/publication'

const usePublicationForm = (publicationId) => {
    const {i18n} = useI18n()
    const {showAlert} = useAlert()
    const navigate = useNavigate()

    const [doUploadImage] = useUploadImageMutation()

    const [formError, setFormError] = useState(null)
    const [guideSearchValue, setGuideSearchValue] = useState()

    const [doCreatePublication] = useCreatePublicationMutation()
    const [doSavePublication] = useSavePublicationMutation()
    const {data: guides} = useGetGuidesQuery(
        {search: guideSearchValue},
        {skip: !guideSearchValue}
    )
    const {data: publication} = useGetPublicationQuery(publicationId, {
        skip: isNew(publicationId),
    })

    const form = useForm({
        defaultValues: {isActive: true},
        mode: 'onSubmit',
        resolver: yupResolver(publicationSchema),
    })
    const formValues = form.watch()

    useEffect(() => {
        if (publication) {
            form.reset(publication)
        }
    }, [publication, form])

    const onSubmit = async (values) => {
        let response
        const body = {
            ...values,
            guide: values.guide?._id,
        }
        if (values._id) {
            response = await doSavePublication(body)
        } else {
            response = await doCreatePublication(body)
        }

        if (response?.data?._id) {
            const message = i18n.t('form.savedSuccessfully', {
                itemName: i18n.t('tables.publication'),
            })
            navigate(`/publications/${response.data._id}`)
            showAlert(message)
        }
        if (response.error) {
            setFormError(response.error.data.error)
        }
    }

    const submit = form.handleSubmit(onSubmit)

    const uploadFlyer = async () => {
        const formData = new FormData()
        formData.append('files', formValues.flierFile)
        formData.append('folderName', 'publications_flayers')

        const response = await doUploadImage({
            formData,
            folderName: 'publications_flayers',
        })

        const link = response?.data?.files?.[0].link

        if (link) {
            const url = link.split('/')[2]
            form.setValue('flierUrl', url)
        }
    }

    return {
        form,
        formError,
        publication,
        submit,
        guides,
        guideSearchValue,
        setGuideSearchValue,
        uploadFlyer,
    }
}

export default usePublicationForm
