import {TimeField} from '@mui/x-date-pickers'
import {InputContainer} from 'components/Page/Form/Forms'
import dayjs from 'services/utils/dayjs'
import {useI18n} from 'store/Store'

const TimePicker = ({name, label, value, onChange, error}) => {
    const {i18n} = useI18n()
    return (
        <InputContainer>
            <TimeField
                name={name}
                label={label}
                ampm={false}
                value={dayjs(value, 'HH:mm')}
                onChange={onChange}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        error: !!error,
                        InputLabelProps: {shrink: true},
                        helperText: error && i18n.t(`formErrors.${error}`),
                    },
                }}
            />
        </InputContainer>
    )
}

export default TimePicker
