import {getInitials} from 'services/utils/misc'
import {menuIcons} from 'constants/sideMenu'
import {useLocation} from 'react-router-dom'

const useSideMenuItem = (text, index) => {
    const location = useLocation()

    const getIcon = (text) => menuIcons[text] || getInitials(text)
    const icon = getIcon(text, index)

    const isSelected = location.pathname.split('/')[1] === text
    const itemPath = text === 'home' ? '/' : `/${text}`

    return {icon, isSelected, itemPath}
}

export default useSideMenuItem
