import {styled} from 'common'
import RawTable from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableTitleAndFilters from './Table/TableTitleAndFilters'
import TableBody from './Table/TableBody'
import TablePagination from './Table/TablePagination'
import TableHeadRow from './Table/TableHeadRow'
import {FormErrorText} from 'components/Control/Form/index_'
import useTable from 'logic/Table/useTable'
import {useI18n} from 'store/Store'

const Table = ({
    itemName,
    total,
    visibleKeys,
    onDelete,
    allowExpand,
    allowEdit,
    allowAdd,
    allowDelete,
    filters,
    children,
    paginationType, // 'local', 'external' (server) or null
    useGetQuery,
    useDeleteMutation,
    ...p
}) => {
    const {i18n} = useI18n()

    const {
        tableName,
        tableKeys,
        tableData,
        keyTypes,
        tableItems,
        totalItems,
        errorMessage,
        doDelete,
    } = useTable({
        itemName,
        useGetQuery,
        paginationType,
        useDeleteMutation,
        visibleKeys,
    })

    const tableTitle = i18n.t(`tables.${tableName}`)

    return (
        <Container>
            <TableTitleAndFilters
                title={tableTitle}
                useSearch
                allowAdd={allowAdd}
                itemName={itemName}>
                {filters}
            </TableTitleAndFilters>
            <StyledTableContainer component={Paper}>
                <StyledTable
                    sx={{minWidth: 650}}
                    {...p}
                    aria-label="simple table">
                    <TableHeadRow
                        itemName={itemName}
                        onDelete={doDelete}
                        allowExpand={allowExpand}
                        allowEdit={allowEdit}
                        tableKeys={tableKeys}
                    />
                    <TableBody
                        itemName={itemName}
                        tableKeys={tableKeys}
                        keyTypes={keyTypes}
                        tableItems={tableItems}
                        tableData={tableData}
                        onDelete={doDelete}
                        allowExpand={allowExpand}
                        allowEdit={allowEdit}
                        allowDelete={allowDelete}>
                        {children}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>
            <TablePagination
                itemName={itemName}
                paginationType={paginationType}
                totalItems={totalItems}
            />
            {errorMessage && <FormErrorText>{errorMessage}</FormErrorText>}
        </Container>
    )
}

export default Table

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const StyledTableContainer = styled(TableContainer)`
    width: 100%;
    margin-bottom: 0px;
`
const StyledTable = styled(RawTable)`
    width: 100%;
`
