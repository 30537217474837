import {DesktopDatePicker} from '@mui/x-date-pickers'
import dayjs from 'services/utils/dayjs'
import {Controller} from 'react-hook-form'
import {useI18n, useIsRtl} from 'store/Store'
import {InputContainer} from 'components/Page/Form/Forms'
import {inputHeight} from 'appearance/theme'

const FormDatePicker = ({
    name,
    control,
    label,
    error,
    disabled,
    hidden,
    valueFormat,
    allowedDates,
    timezone,
}) => {
    const {i18n} = useI18n()
    const isRtl = useIsRtl()

    const handleChange = (onChange) => (value) => {
        valueFormat ? onChange(value.format(valueFormat)) : onChange(value)
    }

    const rtlStyles = isRtl
        ? {
              style: {transform: 'rotate(180deg)'},
          }
        : {}

    return (
        !hidden && (
            <InputContainer>
                <Controller
                    name={name}
                    control={control}
                    render={({field: {onChange, value}}) => (
                        <DesktopDatePicker
                            disabled={disabled}
                            format="DD/MM/YYYY"
                            timezone={timezone}
                            label={label}
                            value={value ? dayjs(value) : null}
                            onChange={handleChange(onChange)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    InputLabelProps: {shrink: true},
                                    error: !!error,
                                    helperText:
                                        error && i18n.t(`formErrors.${error}`),
                                    InputProps: {
                                        style: {height: inputHeight},
                                    },
                                },
                                desktopPaper: {
                                    dir: isRtl ? 'rtl' : 'ltr',
                                },
                                leftArrowIcon: {...rtlStyles},
                                rightArrowIcon: {...rtlStyles},
                            }}
                            shouldDisableDate={
                                allowedDates
                                    ? (date) => {
                                          const formattedDate =
                                              dayjs(date).format('YYYY-MM-DD')
                                          return !allowedDates.includes(
                                              formattedDate
                                          )
                                      }
                                    : null
                            }
                        />
                    )}
                />
            </InputContainer>
        )
    )
}

export default FormDatePicker
