import DataTable from 'components/Display/DataTable/DataTable'
import {Page, PageContent} from 'components/Page'
import {
    useDeleteDanceMutation,
    useGetDancesQuery,
} from 'store/slices/api/dancesApi'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import {useI18n} from 'store/Store'
import Modal from 'components/Display/Modal/Modal'
import {FormProvider} from 'react-hook-form'
import useDanceMedia from 'logic/Dances/useDanceMedia'
import UploadMediaForm from 'components/Page/Dances/UploadMediaForm'
import {useNavigate} from 'react-router-dom'
import {UploadOutline} from '@styled-icons/typicons/UploadOutline'

const Dances = () => {
    const {i18n} = useI18n()
    const navigate = useNavigate()

    const {
        toggleDialog,
        form,
        dialogOpen,
        dances,
        submit,
        handleSelectDance,
        searchDanceValue,
        setSearchDanceValue,
        isLoading,
        isValidForm,
    } = useDanceMedia()

    return (
        <Page>
            <PageContent>
                <DataTable
                    allowEdit
                    allowDelete
                    itemName="dance"
                    paginationType="external"
                    useGetQuery={useGetDancesQuery}
                    useDeleteMutation={useDeleteDanceMutation}
                    additionalActions={[
                        {
                            label: i18n.t('dances.uploadFullFilmedDate'),
                            onClick: () => {
                                navigate(`/upload`)
                            },
                            icon: <FileUploadIcon />,
                        },
                        {
                            label: i18n.t('dances.uploadMedia'),
                            onClick: () => toggleDialog('new'),
                            icon: <FileUploadIcon />,
                        },
                    ]}
                    additionalRowActions={[
                        {icon: UploadOutline, onClick: toggleDialog},
                    ]}
                />
            </PageContent>

            <Modal
                open={dialogOpen}
                title={i18n.t('dances.uploadMedia')}
                onClose={() => toggleDialog}
                buttons={[
                    {
                        label: i18n.t('form.cancel'),
                        onClick: () => toggleDialog(),
                    },
                    {
                        label: i18n.t('form.save'),
                        onClick: submit,
                        loading: isLoading,
                        disabled: !isValidForm,
                    },
                ]}>
                <FormProvider {...form}>
                    <UploadMediaForm
                        dances={dances}
                        handleSelectDance={handleSelectDance}
                        searchDanceValue={searchDanceValue}
                        setSearchDanceValue={setSearchDanceValue}
                    />
                </FormProvider>
            </Modal>
        </Page>
    )
}

export default Dances
