import baseApi, {
    transformResponseFromData,
    provideTag,
} from 'store/slices/api/baseApi'
import {constructUrl} from './utils'

const danceCollentionsApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getDanceCollections: build.query({
            query: (query) => {
                const url = constructUrl('danceCollections', {
                    ...query,
                    sort: query.sort ?? JSON.stringify({created: 'desc'}),
                })
                return {
                    url,
                    method: 'GET',
                }
            },
            transformResponse: transformResponseFromData,
            providesTags: () => provideTag('DanceCollection'),
        }),
        getDanceCollection: build.query({
            query: (id) => ({
                url: `danceCollections/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('DanceCollection', result),
        }),
        createDanceCollection: build.mutation({
            query: (body) => {
                return {
                    url: `danceCollections`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('DanceCollection'),
        }),
        saveDanceCollection: build.mutation({
            query: (body) => {
                return {
                    url: `danceCollections/${body._id}`,
                    method: 'PUT',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (result) => provideTag('DanceCollection', result),
        }),
        deleteDanceCollection: build.mutation({
            query: (id) => {
                return {
                    url: `danceCollections/${id}`,
                    method: 'DELETE',
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: () => provideTag('DanceCollection'),
        }),
        downloadDanceCollection: build.query({
            query: (language) => ({
                url: `danceCollections?language=${language}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (result) => provideTag('DanceCollection', result),
        }),
    }),
    overrideExisting: false,
})

export default danceCollentionsApi

export const {
    useGetDanceCollectionsQuery,
    useGetDanceCollectionQuery,
    useCreateDanceCollectionMutation,
    useSaveDanceCollectionMutation,
    useDeleteDanceCollectionMutation,
    useDownloadDanceCollectionQuery,
} = danceCollentionsApi
