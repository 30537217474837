import {useState} from 'react'
import {useForm} from 'react-hook-form'
import storage from 'services/storage'
import {
    useForgotPasswordMutation,
    useLoginMutation,
    useResetPasswordMutation,
    useSignupWithInviteMutation,
} from 'store/Store'

const onSubmit = (doAuth, setErrorMessage, token) => (submittedData) => {
    const {password, confirmPassword, rememberMe} = submittedData
    if (confirmPassword && password && password !== confirmPassword) {
        setErrorMessage('Passwords do not match')
        return
    }
    storage.setRememberMe(rememberMe || false)
    const values = token ? {...submittedData, token} : submittedData
    doAuth(values)
}

const getFirstWithValue = (items) => items.find((item) => item)

const useAuth = (token) => {
    const [
        doLogin,
        {isLoading: loginIsLoading, error: loginError, data: loginResponse},
    ] = useLoginMutation()

    const [
        doSignup,
        {isLoading: signupIsLoading, error: signupError, data: signupResponse},
    ] = useSignupWithInviteMutation()

    const [
        doForgotPassword,
        {
            isLoading: forgotPasswordIsLoading,
            error: forgotPasswordError,
            data: forgotPasswordResponse,
        },
    ] = useForgotPasswordMutation()

    const [
        doResetPassword,
        {
            isLoading: resetPasswordIsLoading,
            error: resetPasswordError,
            data: resetPasswordResponse,
        },
    ] = useResetPasswordMutation()

    const [errorMessage, setErrorMessage] = useState(null)

    const {
        getValues: getFormValues,
        setValue: setFormValue,
        handleSubmit,
        control: formControl,
        formState: {errors: formErrors},
    } = useForm()

    const submit = (doAuth, error, token) =>
        handleSubmit(onSubmit(doAuth, error, token))

    const login = submit(doLogin, setErrorMessage)
    const signup = submit(doSignup, setErrorMessage, token)
    const forgotPassword = submit(doForgotPassword, setErrorMessage)
    const resetPassword = submit(doResetPassword, setErrorMessage, token)

    return {
        formControl,
        isLoading: getFirstWithValue([
            loginIsLoading,
            signupIsLoading,
            forgotPasswordIsLoading,
            resetPasswordIsLoading,
        ]),
        error: getFirstWithValue([
            loginError,
            signupError,
            forgotPasswordError,
            resetPasswordError,
        ]),
        errorMessage,
        formErrors,
        getFormValues,
        setFormValue,
        response: getFirstWithValue([
            loginResponse,
            signupResponse,
            forgotPasswordResponse,
            resetPasswordResponse,
        ]),
        login,
        signup,
        forgotPassword,
        resetPassword,
    }
}

export default useAuth
