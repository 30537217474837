import {React} from 'common'
import {Page, PageContent} from 'components/Page'
import {
    useCreateAccountMutation,
    useGetAccountQuery,
    useSaveAccountMutation,
} from 'store/Store'
import EditForm from 'components/Control/EditForm'
import {useParams} from 'react-router-dom'

const Account = () => {
    const {accountId} = useParams()

    return (
        <Page>
            <PageContent>
                <EditForm
                    itemName="account"
                    itemId={accountId}
                    useGetQuery={useGetAccountQuery}
                    useCreateMutation={useCreateAccountMutation}
                    useSaveMutation={useSaveAccountMutation}
                />
            </PageContent>
        </Page>
    )
}

export default Account
