import 'app_globals'
import React from 'react'
import './App.css'
import NavigationRouter from 'navigation/NavigationRouter'
import {Provider} from 'react-redux'
import store from 'store/Store'

import {AlertProvider} from 'components/Display/Alert/AlertContext'
import AlertComponent from 'components/Display/Alert/AlertComponent'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import ThemeWrapper from 'ThemeWrapper'

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Provider store={store}>
                <ThemeWrapper>
                    <AlertProvider>
                        <NavigationRouter />
                        <AlertComponent />
                    </AlertProvider>
                </ThemeWrapper>
            </Provider>
        </LocalizationProvider>
    )
}

export default App
