import {getFormError, isNew} from 'services/utils/editForm'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'

import {useAlert} from 'components/Display/Alert/AlertContext'
import {useI18n} from 'store/Store'
import {
    useCreateArtistMutation,
    useGetArtistQuery,
    useSaveArtistMutation,
} from 'store/slices/api/artistsApi'
import {yupResolver} from '@hookform/resolvers/yup'
import {itemSchema} from 'services/validations/schemas/item'
import {useNavigate} from 'react-router-dom'

const useArtistForm = (id) => {
    const form = useForm({
        mode: 'onSubmit',
        resolver: yupResolver(itemSchema),
    })
    const {handleSubmit} = form
    const {showAlert} = useAlert()
    const {i18n} = useI18n()
    const navigate = useNavigate()

    const [doCreateArtist] = useCreateArtistMutation()
    const [doSaveArtist] = useSaveArtistMutation()
    const {data: artist} = useGetArtistQuery(id, {skip: isNew(id)})

    useEffect(() => {
        if (artist) {
            form.reset(artist)
        }
    }, [form, artist])

    const onSubmit = async (values) => {
        let response

        response = values._id
            ? await doSaveArtist(values)
            : await doCreateArtist(values)

        if (response.error) {
            const errorMessage = getFormError(
                response.error?.data?.message,
                `${i18n.t('moreData.nameEng')} / ${i18n.t(`moreData.nameHeb`)}`,
                i18n
            )

            showAlert(errorMessage, 'error')
        }

        if (response?.data?._id) {
            const message = i18n.t('form.savedSuccessfully', {
                itemName: i18n.t('tables.artist'),
            })
            navigate(`/artists/${response.data._id}`)
            showAlert(message)
        }
    }

    const submit = handleSubmit(onSubmit)

    return {
        form,
        submit,
    }
}

export default useArtistForm
