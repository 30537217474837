import {Autocomplete as RawAutocomplete, Chip, TextField} from '@mui/material'
import {debounce} from 'lodash'
import {useCallback, useMemo, useState} from 'react'
import {useI18n} from 'store/Store'

const ChipAutocomplete = ({
    label,
    name,
    value,
    onChange,
    options,
    getOptionLabel,
    onInputChange,
    placeholder,
    error,
    multiple,
    itemsName,
    optionsName,
    clearOnBlur = false,
    isLoading,
    ...props
}) => {
    const {i18n} = useI18n()
    const [inputValue, setInputValue] = useState('')

    const formatOptionLabel = useCallback(
        (option) => {
            if (getOptionLabel) return getOptionLabel(option)

            return itemsName && optionsName
                ? i18n.t(`${itemsName}.${optionsName}.${option}`)
                : option
        },
        [getOptionLabel, itemsName, optionsName, i18n]
    )

    const debouncedSearch = useMemo(
        () =>
            debounce((searchValue) => {
                onInputChange?.(null, searchValue)
            }, 500),
        [onInputChange]
    )

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue)
        debouncedSearch(newInputValue)
    }

    return (
        <RawAutocomplete
            multiple={multiple}
            onClose={(event) => onInputChange?.(event, [])}
            fullWidth
            autoHighlight
            name={name}
            value={value}
            onChange={onChange}
            clearOnBlur={clearOnBlur}
            options={options || []}
            getOptionLabel={formatOptionLabel}
            filterOptions={(x) => x}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            noOptionsText={i18n.t('form.autocompleteNoOptionsText')}
            loading={isLoading}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        key={value}
                        label={formatOptionLabel(option)}
                        {...getTagProps({index})}
                        sx={{userSelect: 'unset'}}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    error={error}
                    helperText={!!error && i18n.t(`formErrors.${error}`)}
                    inputProps={params.inputProps}
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                        ...params.InputProps,
                        style: {minHeight: 'auto'},
                    }}
                />
            )}
            {...props}
        />
    )
}

export default ChipAutocomplete
