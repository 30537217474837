import {useForm} from 'react-hook-form'
import baseUrl from 'store/slices/api/baseUrl'
import {useGetFilmedDancesDatesQuery} from 'store/slices/api/dancesApi'
import {constructUrl} from 'store/slices/api/utils'

const useDownloadFilmedDates = () => {
    const {
        control,
        formState: {errors},
        watch,
    } = useForm()

    const formValues = watch()

    const getDownloadUrl = () => {
        return constructUrl(`${baseUrl}/dances/downloadFilmedDates`, formValues)
    }

    const {data: filmedDates} = useGetFilmedDancesDatesQuery()
    return {getDownloadUrl, filmedDates, control, errors, formValues}
}

export default useDownloadFilmedDates
