import TableBodyRaw from '@mui/material/TableBody'
import TableRow from 'components/Display/Table/TableRow'
import TableExpandArea from './TableExpandArea'
import {useState} from 'react'
import {getItemId} from 'services/utils/tables'

const TableBody = ({
    itemName,
    tableKeys,
    keyTypes,
    tableItems,
    tableData,
    onDelete,
    allowExpand,
    allowEdit,
    allowDelete,
    children,
}) => {
    const tableName = `${itemName}s`
    const [expandedId, setExpandedId] = useState(null)

    return (
        <TableBodyRaw>
            {tableData
                // ?.filter(
                //     (row) =>
                //         paginationType === 'external' ||
                //         isSearchedRow(row, search)
                // )
                .map((row, r) => (
                    <TableRow
                        key={`table_row_${r}`}
                        itemName={itemName}
                        tableName={tableName}
                        tableKeys={tableKeys}
                        keyTypes={keyTypes}
                        tableRowData={row}
                        rowIndex={r}
                        allowEdit={allowEdit}
                        allowDelete={allowDelete}
                        onDelete={onDelete}
                        onExpand={allowExpand ? setExpandedId : null}
                        expandedId={expandedId}>
                        {children || (
                            <TableExpandArea
                                item={tableItems.find(
                                    (item) => getItemId(item) === expandedId
                                )}
                            />
                        )}
                    </TableRow>
                ))}
        </TableBodyRaw>
    )
}

export default TableBody
