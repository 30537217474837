import Autocomplete from 'components/Control/Autocomplete'
import {getLocale} from 'localization/i18n'
import {useState} from 'react'
import {useUrlParams} from 'services/hooks'
import {formatItemByLocale, formatUserName} from 'services/utils/data'
import {useGetCitiesQuery} from 'store/slices/api/citiesApi'
import {useGetCountriesQuery} from 'store/slices/api/countriesApi'
import {useGetDancesQuery} from 'store/slices/api/dancesApi'
import {useGetGuidesQuery} from 'store/slices/api/guidesApi'
import {useI18n} from 'store/Store'

const AutocompleteFilter = ({column, tableName}) => {
    const locale = getLocale()
    const {i18n} = useI18n()
    const {setUrlParam} = useUrlParams()

    const [inputValue, setInputValue] = useState('')

    const optionsSearchFns = {
        country: useGetCountriesQuery,
        city: useGetCitiesQuery,
        choreographers: useGetGuidesQuery,
        guides: useGetGuidesQuery,
        guide: useGetGuidesQuery,
        dance: useGetDancesQuery,
    }

    const {data: options} = optionsSearchFns[column.id](
        {search: inputValue},
        {skip: !inputValue}
    )

    const onChangeInputValue = (value) => {
        setInputValue(value)
    }

    const getOptionsLabel = (option) => {
        return option?.firstNameEng
            ? formatUserName(option)
            : formatItemByLocale(option, locale)
    }

    const onSelectValue = (event, option) => {
        setUrlParam(column.id, option?._id)
    }

    return (
        <Autocomplete
            variant="standard"
            placeholder={i18n.t('form.filterBy', {
                key: i18n.t(`${tableName}.${column.id}`),
            })}
            options={options?.data}
            inputValue={inputValue}
            onInputChange={(event, value) => onChangeInputValue(value)}
            getOptionLabel={getOptionsLabel}
            onChange={onSelectValue}
            // height={32}
        />
    )
}

export default AutocompleteFilter
