import moment from 'moment'

const dance = {
    isHidden: false,
    nameEng: '',
    nameHeb: '',
    youtubeId: '',
    youtubeStatus: '',
    songName: '',
    yearCreated: moment().format('YYYY'),
    filmedDate: '',
    choreographers: [],
    performers: [],
    composers: [],
    lyricists: [],
    companies: [],
    shapes: [],
    youtubeIds: [],
    facebookVideosIds: [],
    lyricsEng: '',
    lyricsHeb: '',
    danceStepsEng: '',
    danceStepsHeb: '',
    danceStoryEng: '',
    danceStoryHeb: '',
}

export const defaultValues = {dance}

export const errors = {
    duplicateKey: 'document already exists with duplicate key and value',
}
