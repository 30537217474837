import {capitalCase} from 'change-case'
import {errors} from 'constants/forms'

export const isNew = (itemId) => !itemId || itemId === 'new'

export const extractError = (saveError, createError) =>
    saveError?.data?.message || createError?.data?.message

export const getFormTitle = (itemId, itemName) => {
    let action = 'Update'
    if (isNew(itemId)) {
        action = 'Create'
    }
    return `${action} ${capitalCase(itemName)}`
}

export const getItemLabel = (itemKey, itemName) => {
    if (itemKey === 'name') {
        return `${capitalCase(itemName)} Name`
    }
    return capitalCase(itemKey)
}

export const getShouldShowItemKey = (item, itemKey, disabled) =>
    !!item?.[itemKey] || item?.[itemKey] === false || !disabled

export const getFormError = (error, itemKey, i18n) => {
    const duplicateKeyError = i18n.t('formErrors.duplicateKey', {
        key: itemKey,
    })

    return error.includes(errors.duplicateKey)
        ? duplicateKeyError
        : i18n.t('formErrors.somethingWentWrong')
}
