import DataTable from 'components/Display/DataTable/DataTable'
import {Page, PageContent} from 'components/Page'
import {
    useDeleteArtistMutation,
    useGetArtistsQuery,
} from 'store/slices/api/artistsApi'

const Artists = () => {
    return (
        <Page>
            <PageContent>
                <DataTable
                    translationKey="moreData"
                    allowEdit
                    allowDelete
                    itemName="artist"
                    paginationType="external"
                    useGetQuery={useGetArtistsQuery}
                    useDeleteMutation={useDeleteArtistMutation}
                />
            </PageContent>
        </Page>
    )
}

export default Artists
