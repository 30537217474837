import {i18n, React} from 'common'
import {FormInput} from 'components/Control/Form/index_'
import {debounce} from 'lodash'
import {useUrlParams} from 'services/hooks'

const TableSearch = ({itemName, useSearch}) => {
    const {urlParams, setUrlParam} = useUrlParams()
    const {[`${itemName}_search`]: search} = urlParams

    if (!useSearch) {
        return null
    }

    const debouncedSetUrlParam = debounce(
        (value) => setUrlParam(`${itemName}_search`, value),
        500
    )

    const handleSearchChange = (event) => {
        const {value} = event.target
        if (value < search) {
            setUrlParam(`${itemName}_search`, value)
        } else {
            debouncedSetUrlParam(value)
        }
    }

    return (
        <FormInput
            fullWidth
            onChange={handleSearchChange}
            value={search}
            placeholder={`${i18n.t('header.search')}...`}
        />
    )
}

export default TableSearch
