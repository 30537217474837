import {isNew} from 'services/utils/editForm'
import {Page, PageContent} from 'components/Page'
import {FormProvider} from 'react-hook-form'
import {useI18n} from 'store/Store'
import {useParams} from 'react-router-dom'
import FormTitle from 'components/Page/Form/FormTitle'
import SessionForm from 'components/Page/Sessions/SessionForm'
import useSessionForm from 'logic/Forms/useSessionForm'

const Session = () => {
    const {i18n} = useI18n()
    const {sessionId} = useParams()
    const {
        form,
        submit,
        guidesResults,
        searchGuideValue,
        setSearchGuideValue,
        session,
        countriesResults,
        searchCountryValue,
        setSearchCountryValue,
        citiesResults,
        searchCityValue,
        setSearchCityValue,
        handleSetValue,
        imageUrl,
        uploadImage,
    } = useSessionForm(sessionId)

    return (
        <Page>
            <PageContent>
                <FormTitle
                    itemId={sessionId}
                    itemLabel={i18n.t(`tables.session`)}
                    submit={submit}
                />
                <FormProvider {...form}>
                    <SessionForm
                        session={session}
                        isNew={isNew(sessionId)}
                        guidesResults={guidesResults}
                        searchGuideValue={searchGuideValue}
                        setSearchGuideValue={setSearchGuideValue}
                        countriesResults={countriesResults}
                        searchCountryValue={searchCountryValue}
                        setSearchCountryValue={setSearchCountryValue}
                        citiesResults={citiesResults}
                        searchCityValue={searchCityValue}
                        setSearchCityValue={setSearchCityValue}
                        handleSetValue={handleSetValue}
                        imageUrl={imageUrl}
                        uploadImage={uploadImage}
                    />
                </FormProvider>
            </PageContent>
        </Page>
    )
}

export default Session
