import {FormColumn, FormContainer, FormRow} from '../Form/Forms'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {useI18n} from 'store/Store'
import {useFormContext} from 'react-hook-form'
import FormAutocomplete from 'components/Control/Form/FormAutocomplete'
import FormSelect from 'components/Control/Form/FormSelect'
import {yesNoOptions} from 'constants/users'
import RichTextEditor from 'components/Control/Form/RichTextEditor'
import FormDatePicker from 'components/Control/Form/FormDatePicker'
import {formatItemName} from 'services/utils/data'
import {styled} from '@mui/material'

const GuideForm = ({
    cities,
    countries,
    isNew,
    searchCityValue,
    searchCountryValue,
    setCityInputValue,
    setCountryInputValue,
}) => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const {
        control,
        formState: {errors},
    } = formContext

    return (
        <FormContainer>
            <FormRow>
                <FormColumn>
                    <FormTextInput
                        readOnly
                        hidden={isNew}
                        fullWidth
                        name="_id"
                        label={i18n.t(`users._id`)}
                        control={control}
                        error={errors._id}
                    />

                    <FormRow>
                        <FormTextInput
                            fullWidth
                            name="firstNameEng"
                            label={i18n.t(`users.firstNameEng`)}
                            control={control}
                            error={errors.firstNameEng?.message}
                        />

                        <FormTextInput
                            fullWidth
                            name="lastNameEng"
                            label={i18n.t(`users.lastNameEng`)}
                            control={control}
                            error={errors.lastNameEng?.message}
                        />
                    </FormRow>
                    <FormRow>
                        <FormTextInput
                            fullWidth
                            name="firstNameHeb"
                            label={i18n.t(`users.firstNameHeb`)}
                            control={control}
                            error={errors.firstNameHeb}
                        />

                        <FormTextInput
                            fullWidth
                            name="lastNameHeb"
                            label={i18n.t(`users.lastNameHeb`)}
                            control={control}
                            error={errors.lastNameHeb}
                        />
                    </FormRow>

                    <FormTextInput
                        fullWidth
                        name="email"
                        label={i18n.t(`users.email`)}
                        control={control}
                        error={errors.email?.message}
                    />

                    <FormTextInput
                        fullWidth
                        name="mobilePhone"
                        label={i18n.t(`users.mobilePhone`)}
                        control={control}
                        error={errors.mobilePhone?.message}
                    />
                </FormColumn>

                <FormColumn>
                    <FormTextInput
                        fullWidth
                        name="address"
                        label={i18n.t(`users.address`)}
                        control={control}
                        error={errors.address}
                    />
                    <FormRow>
                        <FormAutocomplete
                            fullWidth
                            name="city"
                            label={i18n.t(`users.city`)}
                            control={control}
                            error={errors.city}
                            options={cities}
                            getOptionLabel={formatItemName}
                            inputValue={searchCityValue}
                            onChangeInputValue={setCityInputValue}
                        />

                        <FormAutocomplete
                            fullWidth
                            name="country"
                            label={i18n.t(`users.country`)}
                            control={control}
                            error={errors.country}
                            options={countries}
                            getOptionLabel={formatItemName}
                            inputValue={searchCountryValue}
                            onChangeInputValue={setCountryInputValue}
                        />
                    </FormRow>

                    <FormRow>
                        <FormTextInput
                            fullWidth
                            name="websiteUrl"
                            label={i18n.t(`users.websiteUrl`)}
                            control={control}
                            error={errors.websiteUrl}
                        />

                        <FormTextInput
                            fullWidth
                            name="imageUrl"
                            label={i18n.t(`users.imageUrl`)}
                            control={control}
                            error={errors.imageUrl}
                        />
                    </FormRow>

                    <FormDatePicker
                        fullWidth
                        name="dateOfBirth"
                        label={i18n.t(`users.dateOfBirth`)}
                        control={control}
                        error={errors.dateOfBirth}
                    />

                    <FormRow>
                        <FormSelect
                            fullWidth
                            itemsName="users"
                            optionsName="yesNo"
                            name="isDead"
                            label={i18n.t(`users.isDead`)}
                            options={yesNoOptions}
                            control={control}
                            error={errors.isDead}
                        />

                        <FormDatePicker
                            fullWidth
                            name="dateOfDeath"
                            label={i18n.t(`users.dateOfDeath`)}
                            control={control}
                            error={errors.dateOfDeath}
                        />
                    </FormRow>
                </FormColumn>
            </FormRow>
            <TextsEditorsContainer>
                <RichTextEditor
                    name="resumeEng"
                    label={i18n.t(`users.resumeEng`)}
                    control={control}
                    error={errors.resumeEng}
                />

                <RichTextEditor
                    name="resumeHeb"
                    label={i18n.t(`users.resumeHeb`)}
                    control={control}
                    error={errors.resumeHeb}
                />
            </TextsEditorsContainer>
        </FormContainer>
    )
}

export default GuideForm

const TextsEditorsContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 48px;
`
